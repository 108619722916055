import React, { useEffect, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import * as customerAPI from '../../api/CustomerAPI';
import * as locationAPI from '../../api/LocationAPI';
import Image from './Image';
import { useToast, Box, useColorModeValue, HStack, Skeleton, Text } from '@chakra-ui/react';
import { addLocationImage, locationUpdated, selectLocationById } from '../../features/locations/locationsSlice';
import { LightboxComponent as Lightbox } from '../common/LightboxComponent';
import TagForm from '../form/TagFormComponent';
import { OutlineButton, SubmitButton } from '../form/Button';
import axios from 'axios';


const imageTags = {
  command: 'Command',
  firstIn: 'First In',
  floorPlan: 'Floor Plan',
};

export const ImageStrip = (props) => {
  const { selectedLocationImageReorder, onAnnotation, isFIO, isCommand, canEditImage, changeCounter } = props;

  const dispatch = useDispatch();
  const toast = useToast();

  const { customerId, links } = useSelector(v => v.customer);
  const { selectedLocationId } = useSelector(v => v.locations);
  const selectedLocation = useSelector(state => selectLocationById(state, selectedLocationId));

  const colorMode = useColorModeValue('white', 'gray.900');

  const [uploading, setUploading] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

  useEffect(() => {
    let isSubbed = true;
    // If the selectedLocation has imageLength, but no images, then we haven't loaded them yet...
    if (selectedLocation && !selectedLocation.images && selectedLocation.imageLength > 0) {
      setLocationLoading(true);
      const link = selectedLocation.links.find(link => link.rel === 'self');
      customerAPI.getLocation(
        link,
        (res) => {
          if (isSubbed) {
            // Updates global location store w/ new location data: images, imageLength, etc.
            dispatch(locationUpdated({
              id: res.id,
              changes: {
                ...res
              }
            }));
            setLocationLoading(false);
          }
        },
        (f) => {
          toast({
            title: 'Unable to load location images',
            position: 'top',
            description: 'Connection to image service failed, please re-select location to try again.',
            status: 'warning',
            duration: 9000,
            isClosable: true
          });
          console.error('ImageStrip::getLocation', f);
          setLocationLoading(false);
        });
    }
    return () => {
      isSubbed = false;
    };
  }, [selectedLocation]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [selectedLocation, dispatch, toast]);

  const uploadImages = files => {
    if (!selectedLocation) {
      toast({
        title: 'Location not selected',
        position: 'top',
        description: 'Please select a location to upload an image.',
        status: 'warning',
        duration: 9000,
        isClosable: true
      });
      return Promise.resolve();
    }
    setUploading(true);
    let currentIndex = 1;
    const totalIndex = files.length;
    props.setModal({
      heading: 'Image Uploading',
      body: (
        <div>
          <span> Image upload in process... 0 / {totalIndex} </span>
        </div>
      ),
      footer: <span />
    });

    // replace props.uploadImage with api action.

    const uploads = files.map(file =>
      locationAPI.uploadImage({
        image: file,
        locationId: selectedLocation.id
      }, links && links.find(x => x.rel === 'locations')
      ).then(({ data }) => {
        dispatch(addLocationImage({
          id: selectedLocation.id,
          image: data
        }));
        return data;
      }).then(image => {
        return axios
          .get(image.href)
          .then(() => {
            return image;
          });
      }).then(e => {

        props.setModal({
          heading: 'Image Uploading',
          body: (
            <div>
              <span> Image upload in process...{currentIndex} / {totalIndex} </span>
            </div>
          ),
          footer: <span />
        });
        currentIndex = currentIndex + 1;
      }).catch(err => {
        props.setModal({
          heading: 'Image Uploading',
          body: (
            <div>
              <span> Error encountered during image upload ...{currentIndex} / {totalIndex} </span>
            </div>
          ),
          footer: <span />
        });
        return err;
      })
    );
    return Promise.all(uploads).then((e) => {
      toast({
        title: 'Upload Complete',
        position: 'top',
        description: 'Images successfully uploaded.',
        status: 'success',
        duration: 9000,
        isClosable: true
      });
      setUploading(false);
      props.toggleModal();
    }).catch((e) => {
      toast({
        title: 'Upload Error',
        position: 'top',
        description: 'There was a problem uploading the images. Please try again.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
      setUploading(false);
      props.toggleModal();
    });
  };

  // const onInput = files => {
  //   const filesArray = [...files];
  //   uploadImages(filesArray);
  // };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      uploadImages(acceptedFiles);
    }

    rejectedFiles.forEach(reject => {
      toast({
        title: 'Upload Error',
        position: 'top',
        description: `File "${reject.file.name}" was rejected (must be an image or PDF)`,
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    });
  };

  const saveTags = (tags, index) => {
    const imageId = selectedLocation.images[index].id;
    const locationId = selectedLocation.id;
    locationAPI.saveTags(locationId, imageId, tags, links && links.find(x => x.rel === 'locations')).then((res, err) => {
      // Returns updated image, so we replace location in store.
      dispatch(locationUpdated({
        id: locationId,
        changes: {
          images: selectedLocation.images.map((image, i) => {
            if (image.id === imageId) {
              return res.data;
            }
            return image;
          })
        }
      }));
      props.toggleModal();
    });
  };

  const tagImage = index => {
    const pdfId = selectedLocation.images[index].id;
    const pdfName = selectedLocation.images[index].title;
    const locationId = selectedLocation.id;
    locationAPI.getTags(locationId, pdfId, links && links.find(x => x.rel === 'locations')).then((res1, err1) => {
      if (err1) console.warn(err1);
      let tags = [];
      if (res1.data === '') {
        tags = [];
      } else if (res1.data) {
        tags = [...res1.data];
      }
      const tagsRefined = [];
      for (let ii = 0; ii < tags.length; ii++) {
        const tag = { id: tags[ii], text: tags[ii] };
        tagsRefined.push(tag);
      }

      props.setModal({
        heading: 'Tag File (Image & PDF)',
        body: (
          <TagForm
            onConfirm={(savedTags) => saveTags(savedTags, index)}
            onDecline={props.toggleModal}
            fileName={pdfName}
            tags={tagsRefined}
          />
        )
      });
    });
  };

  const moveImage = (index, direction) => {
    if (!selectedLocation.images || selectedLocation.images.length <= 1 || index < 0) {
      return;
    }
    // To Use on request failure.
    const originalImages = [...selectedLocation.images ? selectedLocation.images : []];
    const dir = direction === 'left' ? index - 1 : index + 1;
    const newImages = [...selectedLocation.images];
    // Omitted: saving original order - unneeded?
    const leftImage = selectedLocation.images[dir];
    newImages[dir] = selectedLocation.images[index];
    newImages[index] = leftImage;

    dispatch(locationUpdated({
      id: selectedLocation.id,
      changes: {
        images: newImages
      }
    }));

    // Silently Attempt to update image order on server.
    locationAPI.reorderImage(selectedLocation.id, newImages, links && links.find(x => x.rel === 'locations')).catch((err) => {
      console.error('ImageStrip::moveImage', err);
      // Revert to original order.
      dispatch(locationUpdated({
        id: selectedLocation.id,
        changes: {
          images: originalImages
        }
      }));
      toast({
        title: 'Image Reorder Failed',
        position: 'top',
        description: 'Image order was not updated on the server. Please try again.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    });
  };

  const toggleLightbox = () => {
    setLightboxIsOpen(!lightboxIsOpen);
  };

  const onlyTagContent = (pImages, tag) => { // this can be improved. filtering can be done in a useEffect dependent on props.activeTabKey
    if (!pImages) return null;
    const images = [];
    for (let ii = 0; ii < pImages.length; ii++) {
      if (!pImages[ii].tags) continue;
      for (let jj = 0; jj < pImages[ii].tags.length; jj++) {
        if (pImages[ii].tags[jj].toUpperCase() === tag.toUpperCase()) {
          images.push(pImages[ii]);
          break;
        }
      }
    }
    return images;
  };

  const onSelection = index => {
    setCurrentImageIndex(index);
    props.setImageIndex(index);
    toggleLightbox();
  };

  const confirmImageDelete = index => {
    // let isLoading = false;

    const deleteImage = (index) => {
      // isLoading = true;
      return locationAPI.deleteImage(selectedLocation.id, selectedLocation.images[index].id, links && links.find(x => x.rel === 'locations')).then((res, err) => {
        if (err) {
          toast({
            title: 'Error',
            position: 'top',
            description: 'Unable to delete image',
            status: 'error',
            duration: 9000,
            isClosable: true
          });
          return;
        }
        dispatch(locationUpdated({
          id: selectedLocation.id,
          changes: {
            images: selectedLocation.images.filter((image, i) => i !== index)
          }
        }));
        toast({
          title: 'Success',
          position: 'top',
          description: 'Image deleted',
          status: 'success',
          duration: 9000,
          isClosable: true
        });
        // isLoading = false;
        props.toggleModal();
      });
    };

    props.setModal({
      heading: 'Delete an Image',
      body: (<Text>Are you sure you want to delete this image?</Text>),
      footer: (<HStack justifyContent="end" spacing="1rem">
        <OutlineButton onClick={props.toggleModal} width="8rem">No</OutlineButton>
        <SubmitButton onClick={() => deleteImage(index)} width="8rem">Yes</SubmitButton>
      </HStack>)
    });
  };

  const renderLightbox = () => {
    if (!lightboxIsOpen) return null;
    const lightboxProps = {
      images: props.activeTabKey === 'firstIn' ? onlyTagContent(selectedLocation.images, 'First In')
        : props.activeTabKey === 'command' ? onlyTagContent(selectedLocation.images, 'Command')
          : selectedLocation.images,
      lightboxIsOpen: lightboxIsOpen,
      toggleLightbox: toggleLightbox,
      startingImageIndex: currentImageIndex
    };
    return <Lightbox {...lightboxProps} />;
  };

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    noClick: true,
    multiple: true,
    accept: {
      'image/*': [],
      'application/pdf': ['.pdf']
    }
  });

  return (
    <React.Fragment>
      {selectedLocation && (
        <Box position="absolute" bottom="0" width="100%" bg={colorMode}>
          <div className="imageStrip" {...getRootProps()}>
            {locationLoading && (
              <HStack>
                {[...Array(selectedLocation.imageLength)].map((e, i) => (
                  <Skeleton key={`skeleton-${i}-image`} index={`imageskelt-${i}`} minWidth="256px" height="190px" />
                ))}
              </HStack>
            )}
            {(uploading) ? (
              <img
                src="/images/flow-loader.svg"
                className="no-image"
                style={{ height: '150px', margin: '10px auto' }}
                alt=""
              />
            ) : null}
            {!uploading && !locationLoading && (selectedLocation.id !== selectedLocationImageReorder) && (selectedLocation.customerId === customerId) && canEditImage && !isFIO && !isCommand ? (
              <span className={"imageContainer"}>
                <div className="upload-wrapper">
                  <label htmlFor="upload" className="custom-file-upload">
                    <img src="/images/file-upload-scripts-square.jpg" alt="" />
                  </label>
                  <input id="upload" style={{ display: 'none' }}
                    // onChange={(event) => {
                    //   onInput(event.target.files);
                    // }}
                    // onClick={(event) => {
                    //   event.target.value = null;
                    // }}
                     {...getInputProps()}
                  />
                </div>
              </span>
            ) : null}
            {!uploading && !locationLoading &&
              (isFIO || isCommand || (selectedLocation.customerId !== customerId) || !canEditImage) && !selectedLocation.images &&
              <span className="imageContainer">
                <div>
                  <img src="/images/no-image-available.jpg" alt="" />
                </div>
              </span>
            }
            {!uploading && !locationLoading &&
              (isFIO || isCommand || (selectedLocation.customerId !== customerId) || !canEditImage) && selectedLocation.images && selectedLocation.images.length < 1 &&
              <span className={"imageContainer"}>
                <div>
                  <img src="/images/no-image-available.jpg" alt="" />
                </div>
              </span>
            }
            {selectedLocation.images && !locationLoading && !uploading
              ? selectedLocation.images.filter(i => {
                if ((isFIO || isCommand) && selectedLocation.images && selectedLocation.images.length > 0) {
                  return i.tags && i.tags.indexOf(imageTags[props.activeTabKey]) > -1;
                }
                return true;

              }).map((image, index) => (
                <Image
                  src={(image.hrefThumbnail === 'NoImage') ? '/images/pdf-thumbnail.png' : (`${image.hrefThumbnail}?${changeCounter}`)}
                  key={`${image.id}-${index}-image`}
                  reorder={selectedLocation.id === selectedLocationImageReorder}
                  extremeleft={index === 0}
                  extremeright={index === (selectedLocation.images.length - 1)}
                  onImageClick={() => onSelection(index)}
                  onAnnotationClick={onAnnotation(index)}
                  onTaggingClick={() => tagImage(index)}
                  onDeleteClick={() => confirmImageDelete(index)}
                  onLeftClick={() => moveImage(index, 'left')}
                  onRightClick={() => moveImage(index, 'right')}
                  pdfFileName={(image.hrefThumbnail === 'NoImage') ? image.title : ' '}
                  canEditImage={canEditImage && (image.hrefThumbnail !== 'NoImage')}
                  isPDF={(image.hrefThumbnail === 'NoImage')}
                  isFIO={isFIO}
                  isCommand={isCommand}
                  isMine={selectedLocation.customerId === customerId}
                  supportsTouch={supportsTouch}
                />
              ))
              : null}
          </div>
          {renderLightbox()}
        </Box>
      )}</React.Fragment>);
};

export default ImageStrip;
