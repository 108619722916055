import React from 'react';
import { Stack, Box, StatGroup, Stat, StatLabel, StatNumber, StatHelpText } from '@chakra-ui/react';
import HydrantsInfo from './HydrantsInfoComponent';
import IconLegend from './IconLegendComponent';
import { useSelector } from 'react-redux';
import { selectAllHydrants } from '../../features/hydrants/hydrantsSlice';

export const FlowData = (props) => {
  return (
    <Box p="0.5rem" borderWidth="1px" borderRadius="0.5rem" boxShadow="sm">
      <StatGroup padding="0.75rem">
        <Stat>
          <StatLabel>Hydrants</StatLabel>
          <StatNumber>{props.selectedHydrantsLength}</StatNumber>
          <StatHelpText>selected</StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Total Flow Rate</StatLabel>
          <StatNumber>{props.totalFlowRate ? (
            props.totalFlowRate.toLocaleString()
          ) : '--'
          }</StatNumber>
          <StatHelpText>gal/min</StatHelpText>
        </Stat>
      </StatGroup>
    </Box>
  );
};

export const FlowDataAndHydrantsInfo = () => {
  const { pinLegend } = useSelector(state => state.customer);
  const { selectedHydrantIds } = useSelector(state => state.hydrants);
  const selectedHydrants = useSelector(selectAllHydrants).filter(hydrant => selectedHydrantIds.includes(hydrant.id));


  const getFlowDataProps = () => {
    let totalHoses = 0;
    let totalFlowRate = 0;
    for (let i = 0; i < selectedHydrants.length; i++) {
      if (selectedHydrants[i].hydrantSize) {
        totalHoses = totalHoses + parseInt(selectedHydrants[i].hydrantSize);
      }
      const flow =
        selectedHydrants[i].flow === null ||
          selectedHydrants[i].flow === undefined
          ? 500
          : parseInt(selectedHydrants[i].flow);
      totalFlowRate = totalFlowRate + flow;
    }
    return {
      selectedHydrantsLength: selectedHydrants.length,
      totalHoses: totalHoses,
      totalFlowRate: totalFlowRate
    };
  };

  return (
    <Stack spacing="1rem">
      <FlowData  {...getFlowDataProps()} />
      {Object.keys(pinLegend).length !== 0 && (
        <IconLegend pinLegend={pinLegend} />
      )}      <HydrantsInfo
        {...{
          selectedHydrants: selectedHydrants
        }}
      />
    </Stack>
  );
};
