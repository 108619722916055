import React, { useEffect, useState } from 'react';
import SignUpSecondaryComponent from '../components/SignUpSecondary/SignUpSecondaryComponent';
import * as sessionAPI from '../api/SessionAPI';
import { Flex, useToast } from '@chakra-ui/react';
// import { useDispatch } from 'react-redux';
import { ExternalContainer } from './ExternalContainer';

const SignUpSecondaryContainer = (props) => {
  const toast = useToast();
  // const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState(null);

  const getTokenValue = (name) => {
    const url = window.location.href;
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  const onError = () => {
    toast({
      title: 'Error',
      position: 'top',
      description: 'Registration link is invalid, please request a new link to set up your account.',
      status: 'error',
      duration: 5000,
      isClosable: true
    });
  };

  useEffect(() => {
    let isMounted = true;

    sessionAPI.validateLinkPart(getTokenValue('linkPart'),
      () => {
        isMounted && sessionAPI.getUserFromLinkPart(getTokenValue('linkPart'),
        (email) => {
          setUserEmail(email);
        },
        onError
        );
      },
      onError
    );

    return () => {
      isMounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // }, );

  return (
    <ExternalContainer>
      <Flex flexDirection="column" justifyContent="center">
        <SignUpSecondaryComponent
          userEmail={userEmail}
          linkPart={getTokenValue('linkPart')}
         />
      </Flex>
    </ExternalContainer>
  );
};

export default SignUpSecondaryContainer;
