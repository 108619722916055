import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import {ChakraProvider, createLocalStorageManager} from '@chakra-ui/react';
import flowTheme from '../styles/theme/default';

import App from '../components/App';
import { FirebaseAnalytics } from '../analytics/FirebaseAnalytics';
import {BrowserRouter} from "react-router-dom";

const manager = createLocalStorageManager('fmsp-cm');

export default function Root({ store, history }) {
  return (
    <ChakraProvider colorModeManager={manager} theme={flowTheme}>
      <Provider store={store}>
          <BrowserRouter>
              <React.Fragment>
                  <App />
                  <FirebaseAnalytics />
              </React.Fragment>
          </BrowserRouter>
      </Provider>
    </ChakraProvider>

  );
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
