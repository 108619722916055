import React from 'react';
import {useSelector} from "react-redux";
import {Navigate, useLocation} from "react-router-dom";
import auth from '../auth/Authenticator';
import MainContainer from "../containers/MainContainer";

export function RequireAuth({ children, role = null }) {
    const user = useSelector(state => state.user);
    const location = useLocation();
    const allowed = (role && user.role) ? user.role === role : true;

    return (allowed && auth.loggedIn()) ? (
        <MainContainer>
            {children}
        </MainContainer>
    ) : (
        <Navigate to={{
            pathname: user.role ? '/' : '/login',
            state: { from: location },
            search: location.search
        }} replace />
    );
}
