import { loginSuccess, loginError } from '../actions/session-actions';
import axios from 'axios';
import config from '../config.js';

export function loginUser(dispatch, credentials, successCallback, errorCallback) {

  const credString = btoa(`${credentials.email}:${credentials.password}`);
  axios.post(`${config.API_BASE_URL}/api/auth/token`, {}, {
    headers: { 'Authorization': `Basic ${credString}` }
  })

    .then(function(response) {
      const errMsg = getLink(response.data.links, 'msg');
      if (errMsg) {
        callCommonCallback(errorCallback);
        dispatch(loginError(errMsg.href));
      } else {
        const jwt = response.data.accessToken;
        const customerLink = getLink(response.data.links, 'customer');
        const userLink = getLink(response.data.links, 'user');
        const tokenType = response.data.tokenType;
        const customerIDLink = getLink(response.data.links, 'customerID');

        sessionStorage.setItem('jwt', jwt);
        sessionStorage.setItem('customer', JSON.stringify(customerLink));
        sessionStorage.setItem('user', JSON.stringify(userLink));
        sessionStorage.setItem('tokenType', tokenType);
        sessionStorage.setItem('customerID', JSON.stringify(customerIDLink));

        if (credentials.persistLogin === true) {
          localStorage.setItem('jwt', jwt);
          localStorage.setItem('customer', JSON.stringify(customerLink));
          localStorage.setItem('user', JSON.stringify(userLink));
          localStorage.setItem('tokenType', tokenType);
          localStorage.setItem('customerID', JSON.stringify(customerIDLink));
        }

        if (typeof successCallback === 'function') {
          const strComp = userLink.href.split('/');
          const userId = strComp[strComp.length - 1];
          successCallback(credentials.email, userId);
        }
        const customerId = customerIDLink.href;
        dispatch(loginSuccess({ customerId, jwt, customerLink, userLink, tokenType, customerIDLink }));
      }
    })
    .catch(function(error) {
      const message = (error.response && error.response.data) ? error.response.data.message : 'Something went wrong. Please try again.';
      callCommonCallback(errorCallback, message);
      dispatch(loginError(message));
    });
}

function callCommonCallback(commonCallback, ...args) {
  if (typeof commonCallback === 'function') {
    commonCallback(...args);
  }
}

function getLink(links, rel) {
  return links.filter(function(obj) {
    return obj.rel === rel;
  })[0];
}

export function forgotPasswordResetRequest(data, onSuccess, onError) {
  axios.post(`${config.API_BASE_URL}/api/passwordresetrequest`, data, {})
    .then(function(/*response*/) {
      onSuccess();
    })
    .catch(function(/*error*/) {
      onError();
    });
}

export function forgotPasswordReset(data, onSuccess, onError) {
  axios.post(`${config.API_BASE_URL}/api/completepasswordreset`, data, {})
    .then(function(/*response*/) {
      // showMessage({status: 'Success', message: 'Password reset successfully'});
      onSuccess();
    })
    .catch(function(/*error*/) {
      // showMessage({status: 'Error', message: 'Oops! something went wrong in forgotPasswordReset.'});
      onError();
    });
}

export function submitSignUpSecondary(data, linkPart, onSuccess, onError) {
  axios.post(`${config.API_BASE_URL}/api/registrationLink/${linkPart}/createSecondary`, data, {})
    .then(function(/*response*/) {
      onSuccess();
    })
    .catch(function(/*error*/) {
      onError();
    });
}

export function validateLinkPart(linkPart, onSuccess, onError) {
  axios.get(`${config.API_BASE_URL}/api/registrationLink/${linkPart}/validate`, {})
    .then(function(response) {
      if (response.data.success) {
        onSuccess();
      } else {
        onError();
      }
    })
    .catch(function(/*error*/) {
      onError();
    });
}

export function getUserFromLinkPart(linkPart, onSuccess, onError) {
  axios.get(`${config.API_BASE_URL}/api/registrationLink/${linkPart}/user`, {})
    .then(function(response) {
      onSuccess(response.data.email);
    })
    .catch(function(/*error*/) {
      onError();
    });
}

export function setSessionFromLocal() {
  const keys = ['jwt', 'customer', 'user', 'tokenType', 'customerID'];
  keys.map(k => sessionStorage.setItem(k, localStorage.getItem(k)));
}

export function validateCreateDepartmentLinkPart(linkPart, onSuccess, onError) {
  axios.get(`${config.API_BASE_URL}/api/signup/validate/${linkPart}`, {})
    .then(function(response) {
      if (response.data.success) {
        onSuccess();
      } else {
        onError();
      }
    })
    .catch(function(/*error*/) {
      onError();
    });
}

export function submitCreateDepartment(data, linkPart, onSuccess, onError) {
  axios.post(`${config.API_BASE_URL}/api/signup/${linkPart}`, data, {})
    .then(function(/*response*/) {
      onSuccess();
    })
    .catch(function(/*error*/) {
      onError();
    });
}
