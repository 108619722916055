import * as z from 'zod';


export const PasswordReset = z.object({
  currentPassword: z.string().nonempty('Current password is required'),
  newPassword: z.string().nonempty('New password is required').min(8, 'Password must be at least 8 characters'),
  confirmPassword: z.string().nonempty('Confirm password is required'),
}).refine((data) => data.newPassword === data.confirmPassword, {
  message: 'Passwords do not match',
  path: ['confirmPassword']
});

export const PasswordResetComplete = z.object({
  email: z.string().nonempty('Email is required.'),
  newPassword: z.string().nonempty('New password is required').min(8, 'Password must be at least 8 characters'),
  confirmPassword: z.string().nonempty('Confirm password is required'),
}).refine((data) => data.newPassword === data.confirmPassword, {
  message: 'Passwords do not match',
  path: ['confirmPassword']
});
