import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from './useQuery';

export function useIncidentIDParamEffect(dispatchMessages, selectLocation) {
  const query = useQuery();
  const { lastFetch: lastLocFetch/*, lastPartnerFetch: lastPartnerLocFetch*/ } = useSelector(state => state.locations);
  const { partners } = useSelector(v => v.customer);

  useEffect(() => {
    const queryIncidentID = query.get('incidentId') || query.get('incidentid');
    const partnersFetch = partners.length < 1 ? true : partners.length > 0 && lastLocFetch;
    if (lastLocFetch && partnersFetch && dispatchMessages.length && queryIncidentID) {
      //Find matching dispatch message with incidentID
      const selectedDispatch = dispatchMessages.find(msg => msg.incidentID === queryIncidentID);

      if (selectedDispatch) {
        selectLocation(selectedDispatch);
      }
    }
  // }, [dispatchMessages, query, lastLocFetch, lastLocFetch]);
  }, [dispatchMessages, query, lastLocFetch, partners, selectLocation]);

}
