

export const firebaseConfig = {
  apiKey: 'AIzaSyAYNZ_D-ZJzazYt4DhFfBf1zLXFNz1uSnQ',
  authDomain: 'flowmsp.firebaseapp.com',
  databaseURL: 'https://flowmsp.firebaseio.com',
  projectId: 'flowmsp',
  storageBucket: 'flowmsp.appspot.com',
  messagingSenderId: '84318145205',
  appId: '1:84318145205:web:2bdcc06696b3cda5c0ccfc',
  measurementId: 'G-Z6JFH1XYVG'
};
