import React from 'react';
import * as util from '../../helpers/Util';
import { Text, Stack, HStack, Heading, Box, Flex, Divider, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/react';
const IconLegend = (props) => {
  const IconLegendRow = (props) => (
    <HStack alignItems="center">
      <Flex width="1.5rem" justifyContent={'center'}>
        <img src={util.getMarkerIcon(true, props.legend.pinColor === 'DRY', true, props.legend.pinColor)} alt="" />
      </Flex>
      <Text fontSize="sm" fontWeight="600">{props.legend.label}</Text>
    </HStack>
  );

  return (
    <Box borderWidth="1px"
    borderRadius="0.5rem">
      <Accordion allowMultiple>
        <AccordionItem>
          <LegendHeader pinLegend={props.pinLegend} />
          <AccordionPanel p="0.5rem">
            <Stack spacing="0.25rem">
              {props.pinLegend.rangePinColors.map((iconLegend, index) => (<React.Fragment key={`icon-frag-${index}`}>
                <IconLegendRow key={`${index}-icon-legend`} legend={iconLegend} />
                <Divider key={`${index}-div`} />
              </React.Fragment>
              ))}
              <IconLegendRow legend={props.pinLegend.unknownPinColor} />
              <Divider />
              <IconLegendRow legend={{ pinColor: 'DRY', label: 'Dry Hydrant' }} />
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

    </Box>
  );
};

const LegendHeader = ({
  pinLegend,
  ...props
}) => {
  // const { isOpen } = useAccordionItemState();

  return (
    <AccordionButton justifyContent="space-between">
      <HStack>
        <img src={util.getMarkerIcon(true, false, true, 'RED')} alt="" />
        <Heading textAlign="left" flex="1" as="p" size="xs">Legend</Heading>
      </HStack>
      <AccordionIcon/>
    </AccordionButton>
  );
};
export default IconLegend;
