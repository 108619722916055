import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import * as dispatchAPI from '../../api/DispatchAPI';

// Thunks
export const fetchDispatchMessages = createAsyncThunk(
  'user/fetchDispatchMessages',
  async(data, thunkAPI) => {
    const { customer } = thunkAPI.getState();
    const slug = customer && customer.slug;

    const response = await dispatchAPI.fetchDispatchMessages(slug);
    return response.data.msgSenderList;
  }
);

/**
 * Retrieves the available units
 * @type {AsyncThunk<Promise<*>, void, AsyncThunkConfig>}
 */
export const fetchDispatchUnits = createAsyncThunk(
    'user/fetchDispatchUnits',
    async(data, thunkAPI) => {
      const { customer } = thunkAPI.getState();
      const slug = customer && customer.slug;

      const response = await dispatchAPI.fetchDispatchUnitList(slug);
      return response.data.unitsList || [];
    }
);

/**
 * Updates the selected unit preference
 * @type {AsyncThunk<Promise<void>, void, AsyncThunkConfig>}
 */
export const setSelectedUnits = createAsyncThunk(
    'user/setDispatchUnits',
    async(unitIds, thunkAPI) => {
      const { customer } = thunkAPI.getState();
      const slug = customer && customer.slug;

      await dispatchAPI.setDispatchUnits(unitIds, slug);
      return unitIds;
    },
    {
      // Only execute if not already updating/fetching
      condition: (unitIds, { getState }) => {
        const state = getState();
        const { unitsLoading } = state.dispatch;
        return unitsLoading === 'idle';
      }
    }
);


export const dispatchAdapter = createEntityAdapter();
const initialState = dispatchAdapter.getInitialState({
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
  lastFetch: null,
  selectedDispatchId: null,

  // Unit state
  unitsCurrentRequestId: null,
  unitsLoading: 'idle',
  unitsLastFetch: null,
  unitsLastUpdated: null,
  unitsError: null,
  units: []
});
export const dispatchSlice = createSlice({
  name: 'dispatch',
  initialState,
  reducers: {
    addDispatchMessage: (state, action) => {
      if (action.payload) {
        try {
          const newMsg = JSON.parse(action.payload);

          // Check and add local isInUnitFilter flag
          if (!state.units.length) {
            console.warn('No units or units not yet loaded, unable to update unit filter for new event (defaulting to true)'); // eslint-disable-line no-console
            newMsg.isInUnitFilter = true;
          } else {
            const selectedUnits = state.units.filter(unit => unit.selected).map(unit => unit.unit);
            newMsg.isInUnitFilter = !!((newMsg.units || []).find(name => selectedUnits.includes(name)));
          }

          dispatchAdapter.addOne(state, newMsg);
        } catch (e) {
          console.error('Error parsing new dispatch message', e); // eslint-disable-line no-console
        }
      }
    },

  },
  extraReducers: (builder) => {
    // @todo consider using entityAdapter
    // Add reducers for additional action types here, and handle loading state as needed
    // fetchAllHydrants
    builder
      .addCase(fetchDispatchMessages.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchDispatchMessages.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.lastFetch = Date.now();
        if (action.payload) {
          dispatchAdapter.setAll(state, action.payload);
        }
      })
      .addCase(fetchDispatchMessages.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })

      // Handle unit actions
      .addCase(fetchDispatchUnits.pending, (state, action) => {
        if (state.unitsLoading === 'idle') {
          state.unitsLoading = 'pending';
          state.unitsCurrentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchDispatchUnits.fulfilled, (state, action) => {
        state.unitsLoading = 'idle';
        state.unitsCurrentRequestId = undefined;
        state.unitsLastFetch = Date.now();
        state.units = action.payload;
      })
      .addCase(fetchDispatchUnits.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
            state.unitsLoading === 'pending' &&
            state.unitsCurrentRequestId === requestId
        ) {
          state.unitsLoading = 'idle';
          state.unitsError = action.error;
          state.unitsCurrentRequestId = undefined;
        }
      })

      .addCase(setSelectedUnits.pending, (state, action) => {
        if (state.unitsLoading === 'idle') {
          state.unitsLoading = 'pending';
          state.unitsCurrentRequestId = action.meta.requestId;
        }
      })
      .addCase(setSelectedUnits.fulfilled, (state, action) => {
        state.unitsLoading = 'idle';
        state.unitsCurrentRequestId = undefined;
        state.unitsLastFetch = Date.now();
        state.unitsLastUpdated = Date.now();
        state.lastFetch = null; // clear so next time the dispatch component is loaded it refreshes
        const selectedUnitIds = new Set(action.meta.arg);
        for (const unit of state.units) {
          unit.selected = selectedUnitIds.has(unit.id);
        }
      })
      .addCase(setSelectedUnits.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
            state.unitsLoading === 'pending' &&
            state.unitsCurrentRequestId === requestId
        ) {
          state.unitsLoading = 'idle';
          state.unitsError = action.error;
          state.unitsCurrentRequestId = undefined;
        }
      })


    ;
  }
});

const { actions, reducer } = dispatchSlice;

export const {
  addDispatchMessage
} = actions;

export default reducer;


export const {
  selectById: selectedDispatchId,
  selectAll: selectAllDispatches,
  selectTotal: selectTotalDispatches,
} = dispatchAdapter.getSelectors(state => state.dispatch);
