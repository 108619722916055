/* global google */
import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { shouldNotUpdate } from '../../helpers/Memo';
import { Flex, Tooltip, Icon, Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_CENTER,
  midPoint,
  offsetCenter,
} from '../../helpers/GoogleMaps';
import CustomMap from './CustomMap';
import {
  selectAllHydrants,
  setSelectedHydrants,
} from '../../features/hydrants/hydrantsSlice';
import {getUIConfig, setUserConfig} from '../../features/user/userSlice';
import {
  selectAllLocations,
  selectLocationById,
  setSelectedLocationId,
} from '../../features/locations/locationsSlice';
import ErrorBoundary from '../../containers/ErrorBoundary';
import { FaCrosshairs } from '@react-icons/all-files/fa/FaCrosshairs';
import { AddItem } from './AddItemComponent';

const MapComponent = (props) => {
  const {
    address1: customerStreet,
    city: customerCity,
    state: customerState,
  } = useSelector((state) => state.customer);
  const user = useSelector(state => state.user);
  const userConfig = getUIConfig(user);
  const { customerId } = useSelector((state) => state.customer);
  const { selectedHydrantIds, /*loading: hydrantsLoading*/ } = useSelector(
    (state) => state.hydrants
  );
  const {
    selectedLocationId,
    // loading: locationsLoading,
    // partnerLoading,
  } = useSelector((state) => state.locations);
  const selectedLocation = useSelector((state) =>
    selectLocationById(state, selectedLocationId)
  );
  const hydrants = useSelector(selectAllHydrants);
  const locations = useSelector(selectAllLocations);
  const filteredLocationIds = useSelector(
    (state) => state.locations.filteredLocationIds
  );
  const dispatch = useDispatch();
  const [visibleLocations, setVisibleLocations] = useState([]);
  const [visibleHydrants, setVisibleHydrants] = useState([]);
  const [map, setMap] = useState();

  const [newHydrant, setNewHydrant] = useState(false);

  const geocoder = new google.maps.Geocoder();

  const [config, setConfig] = useState({
    ...userConfig,
    bounds: null,
  });

  useEffect(() => {
    // If config center is not set, we find customer address and set it as center
    if (!config.center) {
      setCustomerCenter();
    }
  }, [userConfig]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [userConfig, config.center]); // eslint-disable-line react-hooks/exhaustive-deps

  // Create map animation side-effect after render is complete
  useEffect(() => {
    if (map) {
      // @todo remove setTimeout. We ran into race condiiton of map and map.getProjection being available
      setTimeout(() => {
        if (selectedLocation && map.getProjection()) {
          const newCenter =
            selectedLocation.latLon ?
              new google.maps.LatLng(selectedLocation.latLon.latitude, selectedLocation.latLon.longitude)
            :
              midPoint(
                selectedLocation.geoOutline.map((coor) => ({
                  lat: coor.latitude,
                  lng: coor.longitude,
                }))
              );
          if (map && !isEqual(config.center, newCenter)) {
            const newPoint = offsetCenter(map, newCenter, 0, 150);
            setTimeout(() => map.panTo(newPoint), 50);
          }
        }
      }, 250);
    }
  }, [selectedLocation, map]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [selectedLocation, map, config.center]);

  useEffect(() => {
    mapVisibleHydrants();
    mapVisibleLocations();
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    map,
    config,
    selectedHydrantIds,
    hydrants,
    locations,
    filteredLocationIds,
    props.hydrantInMove,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.markersFromSearch && props.markersFromSearch.length > 0 && map) {
      map.panTo(props.markersFromSearch[0].position);

      // Wait for expensive operation until panTo is complete
      // if there is a search marker, which is now the map center, let's see if there's a location polygon
      // using first marker as this is what gets centered on
      setTimeout(() => {
        const centerMarker = props.markersFromSearch[0];
        for (let i = 0; i < locations.length; i++) {
          const loc = locations[i];
          const locPoly = new google.maps.Polygon({
            paths: loc.geoOutline.map((p) => {
              return { lat: p.latitude, lng: p.longitude };
            }),
          });
          const containsMarker = google.maps.geometry.poly.containsLocation(
            centerMarker.position,
            locPoly
          );

          if (containsMarker) {
            dispatch(setSelectedLocationId(loc.id));
            dispatch(
              setSelectedHydrants(
                loc.hydrants ? loc.hydrants.map((h) => h.id) : []
              )
            );
            break;
          }
        }
      }, 100);
    }
  }, [props.markersFromSearch]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [dispatch, locations, map, props.markersFromSearch]);

  const setCustomerCenter = () => {
    const customerAddress = `${customerStreet} ${customerCity}, ${customerState}`;
    geocoder.geocode({ address: customerAddress }, (results, status) => {
      if (status === 'OK') {
        const customerLocation = results[0].geometry.location;
        setConfig({
          ...config,
          center: {
            lat: customerLocation.lat(),
            lng: customerLocation.lng(),
          },
        });
      } else {
        setConfig({
          ...config,
          center: DEFAULT_CENTER,
        });
      }
    });
  };

  const mapVisibleHydrants = () => {
    if (config.bounds) {
      const visHyds = hydrants.filter((h) =>
        config.bounds.contains(h.position)
      );
      setVisibleHydrants(
        visHyds.map((h) =>
          selectedHydrantIds.includes(h.id) ? { ...h, isSelected: true } : h
        )
      );
    }
  };

  const mapVisibleLocations = () => {
    if (config.bounds) {
      const locs = locations
        .filter((loc) =>
          loc.latLon ?
            config.bounds.contains({ lat: loc.latLon.latitude, lng: loc.latLon.longitude })
          :
            loc.geoOutline.some((coor) =>
              config.bounds.contains({ lat: coor.latitude, lng: coor.longitude })
            )
        )
        .map((loc) => ({
          ...loc,
          isMine: loc.customerId === customerId,
          isSelected: loc.id === selectedLocationId,
        }));
      if (filteredLocationIds.length > 0) {
        setVisibleLocations(
          locs.filter((loc) => filteredLocationIds.includes(loc.id))
        );
      } else {
        setVisibleLocations(locs);
      }
    }
  };

  function getClickLocation(latLng) {
    if (map !== undefined) {
      const projection = map.getProjection();
      const bounds = map.getBounds();
      const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
      const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
      const scale = Math.pow(2, map.getZoom());
      const worldPoint = projection.fromLatLngToPoint(latLng);
      return {
        x: Math.floor((worldPoint.x - bottomLeft.x) * scale) + 40,
        y: Math.floor((worldPoint.y - topRight.y) * scale) + 20,
      };
    }
    return {
      x: 0,
      y: 0,
    };
  }

  function handleMapMounted(map) {
    setMap(map);
    setConfig({ ...config, bounds: map.getBounds() });
  }

  function handleZoomChanged() {}

  const handleOnIdle = (e) => {
    if (map !== undefined) {
      if (map.getCenter() !== undefined) {
        const center = map.getCenter();
        setConfig({
          ...config,
          bounds: map.getBounds(),
          center: {
            lat: center.lat(),
            lng: center.lng(),
          },
          zoom: map.getZoom(),
        });
        dispatch(
          setUserConfig({
            center: {
              lat: center.lat(),
              lng: center.lng(),
            },
            zoom: map.getZoom(),
          })
        );
      }
    }
  };

  function onMapTypeIdChanged() {
    if (map !== undefined && map.getMapTypeId() !== config.mapTypeId) {
      setConfig({ ...config, mapTypeId: map.getMapTypeId() });
      dispatch(
        setUserConfig({
          mapTypeId: map.getMapTypeId(),
        })
      );
    }
  }

  function handleMarkerEvent(targetMarker, markerProps, handleHydrantClick) {
    props.setMarkerProps(targetMarker, markerProps);
  }

  function handleLocationClick(targetLocation) {
    if (props.hydrantInMove || props.buildingInMove) return;
    const isCurrent = selectedLocationId === targetLocation.id;

    props.handleLocationClick(isCurrent, targetLocation, () => {
      // Did we just click the current location?
      dispatch(setSelectedLocationId(isCurrent ? null : targetLocation.id));
      if (isCurrent) {
        props.clearSelectedHydrants();
      } else {
        props.selectHydrantsByLocation(targetLocation);
      }
    });
  }

  const handleLocationRightClick = (targetLocation) => (e) => {
    if (targetLocation.isMine) {
      props.handleLocationRightClick(
        getClickLocation(e.latLng),
        targetLocation
      );
    }
  };

  function handleMapClick(e) {
    if (newHydrant) {
      if (geocoder && typeof geocoder.geocode === 'function') {
        geocoder.geocode(
          {
            latLng: e.latLng,
          },
          function(results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                const coordValues = {
                  x: e.pixel.x,
                  y: e.pixel.y,
                  latLng: { lat: e.latLng.lat(), lng: e.latLng.lng() },
                  streetAddress: results[0].formatted_address,
                };
                props.handleContextMenuItemSelect(
                  {
                    value: 'ADD_HYDRANT',
                  },
                  coordValues
                );
              }
            }
          }
        );
      }
      setNewHydrant(false);
    } else {
      props.handleMapClick();
    }
  }

  function handleMapRightClick(e) {
    const _props = props;
    if (geocoder && typeof geocoder.geocode === 'function') {
      geocoder.geocode(
        {
          latLng: e.latLng,
        },
        function(results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              _props.handleMapRightClick(
                e.pixel,
                { lat: e.latLng.lat(), lng: e.latLng.lng() },
                results[0].formatted_address
              );
            }
          }
        }
      );
    }
  }

  function recenterMap() {
    if (map !== undefined) {
      setCustomerCenter();
    }
  }

  function handleMarkerClose(marker) {
    props.setMarkerProps(marker, { showInfo: false }, false);
  }

  function handleMarkerRightClick(e, marker) {
    if (marker.isMine) {
      props.handleHydrantRightClick(getClickLocation(e.latLng), marker);
    }
  }

  const iconStyle = {
    position: 'absolute',
    top: '15rem',
    zIndex: 1,
    left: '0.75rem',
  };

  return (
    <ErrorBoundary>
      <Flex position="relative" className={'map-container'} direction="column">
        <Tooltip placement="right" label="Re-Center Map">
          <Box height="2rem" style={iconStyle}>
            <Icon
              cursor={'pointer'}
              as={FaCrosshairs}
              width={'2rem'}
              height={'2rem'}
              zIndex={1}
              padding={'0.5rem'}
              borderRadius={'0.25rem'}
              background={'#fff'}
              onClick={recenterMap}
              boxShadow="md"
            />
          </Box>
        </Tooltip>
        <AddItem
          onCancelAction={props.onCancelAction}
          isInActionMode={props.isInActionMode}
          newHydrant={newHydrant}
          setNewHydrant={setNewHydrant}
          handleContextMenuItemSelect={props.handleContextMenuItemSelect}
        />
        {config.center && (
          <CustomMap
            newHydrant={newHydrant}
            hydrantInMove={props.hydrantInMove}
            onHydrantDragEnd={props.handleHydrantMove}
            buildingInMove={props.buildingInMove}
            onBuildingDragEnd={props.onBuildingDragEnd}
            onBuildingPinDragEnd={props.handleBuildingPinMove}
            setBuildingDragStartingPoint={props.setBuildingDragStartingPoint}
            visibleLocations={visibleLocations}
            visibleHydrants={visibleHydrants}
            disableDblClick={userConfig.disableDblClick === 'true'}
            markersFromSearch={
              props.markersFromSearch ? props.markersFromSearch : []
            }
            bounds={config.bounds}
            center={config.center}
            zoom={config.zoom}
            mapTypeId={config.mapTypeId}
            setMarkersFromSearch={props.setMarkersFromSearch}
            onMarkerEvent={handleMarkerEvent}
            handleMapMounted={handleMapMounted}
            onZoomChanged={handleZoomChanged}
            onLocationClick={handleLocationClick}
            onLocationRightClick={handleLocationRightClick}
            onMapClick={handleMapClick}
            onMapRightClick={handleMapRightClick}
            onMarkerRightClick={handleMarkerRightClick}
            onMarkerClose={handleMarkerClose}
            onMapTypeIdChanged={onMapTypeIdChanged}
            onIdle={handleOnIdle}
            distancePolyline={props.distancePolyline}
            setRemovePolygons={props.setRemovePolygons}
            showDrawingTools={props.showDrawingTools}
            showDrawingToolsOption={props.showDrawingToolsOption}
            prevPolylineData={props.prevPolylineData}
            onPolygonComplete={props.handlePolygonComplete}
            onPolylineComplete={props.handlePolylineComplete}
          />
        )}
      </Flex>
    </ErrorBoundary>
  );
};

export default React.memo(MapComponent, shouldNotUpdate);
