import * as z from 'zod';

export const CustomerRegistration = z.object({
  customerName: z.string().nonempty('A Department or Company name is required'),
  address1: z.string(),
  address2: z.optional(z.string()),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  password: z.string().min(8, 'Password must be at least 8 characters'),
  confirmPassword: z.string(),
  termsAndConditions: z.boolean().refine(v => v, 'You must accept the terms and conditions'),
  confirmNewAccount: z.boolean().refine(v => v, 'Please confirm you are creating a new department account'),
}).refine((data) => data.password === data.confirmPassword, {
  message: 'Passwords do not match',
  path: ['confirmPassword']
});
