import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Icon,
  Stack,
  Tooltip,
  Text
} from '@chakra-ui/react';
import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt';

import { setSelectedLocationId, selectLocationById } from '../../features/locations/locationsSlice';
import { setSelectedHydrants } from '../../features/hydrants/hydrantsSlice';
import { formatLocationDate, formatDate, formatDateFromISO } from '../../helpers/Date';

import { AssignmentBadge } from '../../components/assignments/AssignmentBadge';
import { allFields as buildingDataFields } from '../../components/form/BuildingDataFormComponent';
import { CompletionPopover } from './CompletionPopover';
import {useNavigate} from "react-router-dom";

const locationColumns = {
  Header: 'Location',
  width: 30,
  columns: [{
    Header: 'Address',
    minWidth: 150,
    maxWidth: 250,
    accessor: (row) => {
      // compiles address vals into string for searchable entity
      return [row.businessName, ...Object.values(row.address ? row.address : {})].join(', ');
    },
    Cell: ({ /*value,*/ row }) => {
      const dispatch = useDispatch();
      const navigate = useNavigate();

      const loc = useSelector(state => selectLocationById(state, row.original.id));
      const onClick = () => {
        navigate('/');
        // @todo Not like this: this is a hacky way to make sure map re-centers on location
        setTimeout(() => {
          dispatch(setSelectedLocationId(row.original.id));
          dispatch(setSelectedHydrants(loc.hydrants ? loc.hydrants.map(h => h.id) : []));
        }, 100);
      };
      const address = row.original.address;
      return (
        <Tooltip label={<Text>View on map <Icon as={FaExternalLinkAlt} height="0.75rem" width="0.75rem" /></Text>} placement="left">
          <Stack className="address" minWidth="8rem" spacing="0px" cursor="pointer" onClick={onClick} _hover={{ textDecoration: 'underline' }}>
            {Object.keys(address).length < 1 && (
              <Text>No Address Data</Text>
            )}
            {row.original.businessName && <span className="name">{row.original.businessName}</span>}
            {address && address.address1 && <span className="address1">{address.address1}</span>}
            {address && address.address2 && <span className="address2">{address.address2}</span>}
            {address && address.city && <span className="city-zip"><span>{address.city}, {address.state} {address.zip}</span></span>}
          </Stack>
        </Tooltip>
      );
    }
  },
  {
    Header: 'Lot #',
    accessor: 'lotNumber',
    width: 60,
  },
  {
    Header: 'District',
    accessor: 'customerZone',
    width: 60,
    className: 'district'
  },
  {
    Header: 'Floors Above',
    accessor: 'storey',
    width: 60,
  },
  {
    Header: 'Floors Below',
    accessor: 'storeyBelow',
    width: 60,
  },
  {
    Header: 'Roof Area',
    accessor: 'roofArea',
    width: 60,
  },
  ]
};

const preplanColumns = {
  Header: 'Preplan',
  width: 40,
  className: 'cell-center',
  columns: [
    {
      Header: 'Hydrants',
      accessor: 'hydrants',
      className: 'hydrants',
      width: 80
    },
    {
      Header: 'Images',
      accessor: 'imageLength',
      className: 'images',
      width: 70
    },
    {
      Header: 'Image Annotations',
      accessor: 'imageAnnotations',
      width: 85,
      Cell: ({ value, row }) => (<Text fontSize="sm" fontWeight={600}>{value}/{row.original.imageLength}</Text>)
    },
    {
      Header: 'Completion',
      id: 'completion',
      width: 100,
      accessor: (row) => row.completion.completion,
      className: 'completion',
      filter: 'between',
      Cell: ({ value, row }) => <CompletionPopover value={value} row={row} />
    },
    {
      Header: 'Floor Plan',
      id: 'floorPlan',
      className: 'floorPlan',
      width: 70,
      accessor: (row) => row.floorPlan ? 1 : 0,
      Cell: ({ value }) => value ? <Text fontSize="sm" fontWeight={600}>Yes</Text> : ''
    },
    {
      Header: 'High Hazard',
      className: 'highHazard',
      id: 'isHighHazard',
      width: 70,
      accessor: (row) => row.isHighHazard ? 1 : 0,
      Cell: ({ value }) => value ? <Text fontSize="sm" fontWeight={600}>Yes</Text> : ''
    },
    {
      Header: 'Do Not Share',
      className: 'doNotShare',
      id: 'doNotShare',
      width: 70,
      accessor: (row) => row.doNotShare ? 1 : 0,
      Cell: ({ value }) => value ? <Text fontSize="sm" fontWeight={600}>Yes</Text> : ''
    },
    {
      Header: 'Risk Assessment Score',
      className: 'riskAssessmentScore',
      id: 'riskAssessmentScore',
      accessor: (row) => row.riskAssessmentScore,
      width: 70,
      Cell: ({ value }) => {
        return <Text fontSize="sm" fontWeight={600}>{value}</Text>;
      }
    },
    {
      Header: 'Review Status',
      accessor: 'reviewStatus',
      width: 70,
      Cell: ({ value }) => <Text fontSize="sm" fontWeight={600}>{value}</Text>
    },
    {
      Header: 'Last Reviewed By',
      accessor: 'lastReviewedBy',
    },
    {
      Header: 'Last Reviewed',
      id: 'lastReviewedOn',
      filter: 'between',
      accessor: (row) => {
        return formatLocationDate(row.lastReviewedOn).getTime();
      },
      Cell: ({ value, row }) => {
        return (<Stack className="name-date" spacing={'0'}>
          <div className="name">{row.original.lastReviewedBy}</div>
          {value && (
            <div className="date">{
              formatDate(value)
            }</div>
          )}
        </Stack>);
      }
    },
    {
      Header: 'Created',
      id: 'originalPrePlan',
      filter: 'between',
      accessor: (row) => {
        return formatLocationDate(row.originalPrePlan).getTime();
      },
      Cell: ({ value, /*row*/ }) => value ? (<Stack className="name-date">
        <div className="date">{formatDate(value)}</div>
      </Stack>) : null
    }
  ]
};

const assignmentColumns = {
  Header: 'Assignment',
  width: 30,
  columns: [
    {
      Header: 'Assigned To',
      accessor: 'assignedTo',
      filter: 'equals',
      Cell: ({ value }) => <Box maxWidth="6rem"><Text fontSize="sm">{value}</Text></Box>
    },
    {
      Header: 'Due',
      accessor: 'assignmentDueOn',
      Cell: ({ value }) => value ? (
        (<Stack className="name-date">
          <div className="date">{formatDateFromISO(value)}</div>
        </Stack>)
      ) : null
    },
    {
      Header: 'Status',
      minWidth: 150,
      maxWidth: 250,
      accessor: 'assignmentStatus',
      className: 'assignmentStatus',
      Cell: ({ /*row,*/ value }) => (value ? <Box minWidth="8rem"><AssignmentBadge status={value} /></Box> : null)
    },
    {
      Header: 'Assignment Notes',
      accessor: 'assignmentNotes',
    }
  ]
};

const buildingColumns = {
  id: 'building',
  Header: 'Building',
  columns: buildingDataFields.map(b => {
    if (b.id === 'commercialStructure') {
      return {
        id: `building.${b.id}`,
        width: 70,
        Header: b.label,
        accessor: (row) => (row.building && row.building.commercialStructure) ? 1 : 0,
        Cell: ({ value }) => value ? <Text fontSize="sm" fontWeight={600}>Yes</Text> : '',
        className: 'building'
      };
    } else if (b.id === 'doNotShare') {
      return {
        id: `building.${b.id}`,
        width: 70,
        Header: b.label,
        accessor: (row) => (row.building && row.building.doNotShare) ? 1 : 0,
        Cell: ({ value }) => value ? <Text fontSize="sm" fontWeight={600}>Yes</Text> : '',
        className: 'building'
      };
    }
    return {
      id: `building.${b.id}`,
      width: 170,
      Header: b.label,
      accessor: (row) => row.building && row.building[b.id],
      className: 'building'
    };
  })
};

export const allColumns = [
  locationColumns,
  preplanColumns,
  assignmentColumns,
  buildingColumns
];

export const allColumnIds = [
  ...allColumns.flatMap(c => c.columns).map(c => c.id || (typeof(c.accessor) === 'string' ? c.accessor : undefined) || c.Header)
];

export const hiddenColumnIds = [
  'id',
  'lastReviewedBy',
  'imageLength',
  'originalPrePlan',
  'riskAssessmentScore',
  'storey',
  'storeyBelow',
  'roofArea',
  ...buildingDataFields.map(b => `building.${b.id}`)
];
