import { Button, Stack, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
// import Form from './FormComponent';
import { InputWithLabel } from './FormWrapper';
import * as sessionAPI from '../../api/SessionAPI';
// class ForgotPasswordForm extends React.Component {
//
//   constructor(props) {
//     super();
//     this.state = {
//       form: {
//         email: ''
//       }
//     };
//     this.handleFormSubmit = this.handleFormSubmit.bind(this);
//   }
//
//   handleFormSubmit(form) {
//     this.props.handleFormSubmit(form);
//   }
//
//   render() {
//     const formFields = [
//       {
//         id: 'email',
//         name: 'email',
//         type: 'text',
//         label: 'Enter your email id',
//         required: true
//       }
//     ];
//     return (<Form {...{
//       form: this.state.form,
//       handleFormSubmit: this.handleFormSubmit,
//       formFields: formFields,
//       errorMessage: this.props.errorMessage,
//       isFormSubmitting: this.props.isFormSubmitting
//     }}
//     />);
//   }
//
// }

const ForgotPassword = (props) => {
  const methods = useForm();
  const toast = useToast();
  const [success, setSuccess] = useState(false);

  const handleFormSubmit = (form) => {
    return new Promise((resolve) => sessionAPI.forgotPasswordResetRequest(form,
      () => {
        resolve();
        setSuccess(true);
      },
      () => {
        resolve();
        toast({
          title: 'Request failed',
          position: 'top',
          description: 'We were unable to reset your password. Please try again.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    ));
  };

  const formField = {
    id: 'email',
    name: 'email',
    type: 'email',
    label: 'Email address',
    placeholder: 'user@example.com',
    required: true
  };

  return (success ? (
    <Stack spacing="1.5rem">
      <p>Request received. Please check your email to reset your password.</p>
      <Button
        bg={'blue.400'}
        color={'white'}
        _hover={{
          bg: 'blue.500',
        }}
        onClick={props.toggleModal}>Close</Button>
    </Stack>
  ) : (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
        <Stack spacing="1.5rem">
          <InputWithLabel
            {...formField}
          />
          <Button
            bg={'blue.400'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            isLoading={methods.formState.isSubmitting}
            type="submit">Reset Password</Button>
        </Stack>
      </form>
    </FormProvider>
  ));
};

export default ForgotPassword;
