// Session_
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_OUT = 'LOG_OUT';
export const INVALID_CREDENTIAL = 'INVALID_CREDENTIAL';

// Customer
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_CUSTOMER_LIST = 'SET_CUSTOMER_LIST';
export const SET_CUSTOMER_RADIUS_LIST = 'SET_CUSTOMER_RADIUS_LIST';
export const SET_CUSTOMER_PARTNERS_LIST = 'SET_CUSTOMER_PARTNERS_LIST';
export const SET_CUSTOMER_CONFIG = 'SET_CUSTOMER_CONFIG';
export const SET_CUSTOMER_UI_CONFIG = 'SET_CUSTOMER_UI_CONFIG';
export const SET_CUSTOMER_HYDRANTS = 'SET_CUSTOMER_HYDRANTS';
export const SET_PARTNER_HYDRANTS = 'SET_PARTNER_HYDRANTS';
export const SET_PARTNER_HYDRANTS_FIRST = 'SET_PARTNER_HYDRANTS_FIRST';
export const SET_CUSTOMER_LOCATIONS = 'SET_CUSTOMER_LOCATIONS';
export const SET_CUSTOMER_CURRENT_LOCATION = 'SET_CUSTOMER_CURRENT_LOCATION';
export const SET_PARTNER_LOCATIONS_FIRST = 'SET_PARTNER_LOCATIONS_FIRST';
export const SET_PARTNER_LOCATIONS = 'SET_PARTNER_LOCATIONS';
export const SET_CUSTOMER_LOCATION = 'SET_CUSTOMER_LOCATION';
export const SET_CUSTOMER_FILTER_LOCATIONS = 'SET_CUSTOMER_FILTER_LOCATIONS';
export const SET_CUSTOMER_MAP_FILTER = 'SET_CUSTOMER_MAP_FILTER';

export const ADD_CUSTOMER_LOCATION = 'ADD_CUSTOMER_LOCATION';
export const DELETE_CUSTOMER_LOCATION = 'DELETE_CUSTOMER_LOCATION';
export const EDIT_CUSTOMER_LOCATION = 'EDIT_CUSTOMER_LOCATION';
export const ADD_CUSTOMER_HYDRANT = 'ADD_CUSTOMER_HYDRANT';
export const EDIT_CUSTOMER_HYDRANT = 'EDIT_CUSTOMER_HYDRANT';
export const DELETE_CUSTOMER_HYDRANT = 'DELETE_CUSTOMER_HYDRANT';
export const DELETE_CUSTOMER_HYDRANT_ALL = 'DELETE_CUSTOMER_HYDRANT_ALL';
export const SET_CUSTOMER_DEFAULT_STATE = 'SET_CUSTOMER_DEFAULT_STATE';
export const SET_CUSTOMER_USERS = 'SET_CUSTOMER_USERS';
export const SET_CUSTOMER_LOGOUT = 'SET_CUSTOMER_LOGOUT';
export const SET_LOCATION_KOUNTER = 'SET_LOCATION_KOUNTER';
// Hydrant
export const SET_HYDRANT_PROPS = 'SET_HYDRANT_PROPS';
export const CLEAR_SELECTED_HYDRANTS = 'CLEAR_SELECTED_HYDRANTS';
export const SELECT_HYDRANTS_BY_LOCATION = 'SELECT_HYDRANTS_BY_LOCATION';

// USER
export const SET_USER = 'SET_USER';

// IMAGES
export const ADD_LOCATION_IMAGE = 'ADD_LOCATION_IMAGE';
export const UPDATE_LOCATION_IMAGE = 'UPDATE_LOCATION_IMAGE';
// batch update
export const UPDATE_LOCATION_IMAGES = 'UPDATE_LOCATION_IMAGES';
export const DELETE_LOCATION_IMAGE = 'DELETE_LOCATION_IMAGE';

// SMS
export const SET_CUSTOMER_SMS = 'SET_CUSTOMER_SMS';
export const SET_NEW_MESSAGE = 'SET_NEW_MESSAGE';
export const SET_BACKLOG_MESSAGE = 'SET_BACKLOG_MESSAGE';
