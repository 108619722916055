import React, { useContext } from 'react';

import { Progress, Flex, HStack, Link, Heading, IconButton, useColorModeValue, Text, Stack, Divider } from '@chakra-ui/react';
import FlowLogo from '../svg/FlowMSPImageTrendLogo';
import { useSelector } from 'react-redux';
import { RiMenuFoldLine } from '@react-icons/all-files/ri/RiMenuFoldLine';
import { RiMenuUnfoldLine } from '@react-icons/all-files/ri/RiMenuUnfoldLine';

import { NavContext } from './MainContainer';
import {
  Link as RouterLink, useLocation
} from 'react-router-dom';
import { selectTotalLocations } from '../features/locations/locationsSlice';
import { selectTotalHydrants } from '../features/hydrants/hydrantsSlice';

export const headerBannerStyle = {
  height: '4rem',
  width: '100%',
  justifyContent: 'space-between',
  paddingRight: '1rem',
  alignItems: 'center',
  shadow: 'sm',
};

export const HeaderContainer = () => {
  const { loading: locationsLoading, partnerLoading } = useSelector(state => state.locations);
  const { loading: hydrantsLoading } = useSelector(state => state.hydrants);

  const customer = useSelector(state => state.customer);
  // const user = useSelector(state => state.user);

  const location = useLocation();


  return (
    <Stack spacing={0}>
      {(partnerLoading === 'pending' || locationsLoading === 'pending' || hydrantsLoading === 'pending') && (
          <Progress size="xs" zIndex={1} isIndeterminate position="absolute" width="100%" />
        )}
    <Flex {...headerBannerStyle} bg={useColorModeValue('white', 'gray.900')}>
      <HStack spacing="0.5rem">
        <MenuButton />
        <Link as={RouterLink} to={'/'} style={{ textDecoration: 'none' }}>
          <FlowLogo />
        </Link>
      </HStack>
      {customer && (
        <HStack alignContent="center" spacing="1rem">
          {location.pathname === '/' && (
          <React.Fragment>
            <DataStatus />
            <Divider orientation="vertical" />
          </React.Fragment>)
          }
          <Heading as="h2" size="md">
            {customer.name}
          </Heading>
        </HStack>
      )}
    </Flex>
    </Stack>

  );
};


const MenuButton = () => {
  const nav = useContext(NavContext);

  return (
    <IconButton
      aria-label="Toggle Menu"
      variant="unstyled"
      icon={nav.isOpen ? <RiMenuFoldLine /> : <RiMenuUnfoldLine />}
      size="3rem"
      padding="1rem"
      borderRadius="0"
      _hover={{
        bg: 'blue.400',
        color: 'white',
      }}
      onClick={nav.toggleMenu}
    />
  );
};


const DataStatus = () => {
  const { loading: locationsLoading } = useSelector(state => state.locations);
  const locationCount = useSelector(selectTotalLocations);

  const { loading: hydrantsLoading } = useSelector(state => state.hydrants);
  const hydrantCount = useSelector(selectTotalHydrants);

  const locationText = locationsLoading === 'pending' ? 'Loading...' : `${locationCount}`;
  const hydrantText = hydrantsLoading === 'pending' ? 'Loading...' : `${hydrantCount}`;

  return (
    <HStack spacing="0.5rem" alignSelf="center">
      <Text><strong>Locations:</strong> {locationText}</Text>
      <Text><strong>Hydrants:</strong> {hydrantText}</Text>
    </HStack>
  );
};
