import React, { useContext } from 'react';
import {
  Box,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Button,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
  Tooltip,
  Heading,
} from '@chakra-ui/react';

import { FaRegCompass } from '@react-icons/all-files/fa/FaRegCompass';
import { FaRegListAlt } from '@react-icons/all-files/fa/FaRegListAlt';
import { FaRegUser } from '@react-icons/all-files/fa/FaRegUser';
import { FaRegIdCard } from '@react-icons/all-files/fa/FaRegIdCard';
// import { FaTerminal } from '@react-icons/all-files/fa/FaTerminal';
import { FaUpload } from '@react-icons/all-files/fa/FaUpload';
import { FaShareAlt } from '@react-icons/all-files/fa/FaShareAlt';
import { FaSignOutAlt } from '@react-icons/all-files/fa/FaSignOutAlt';
import { FaCog } from '@react-icons/all-files/fa/FaCog';
import { FaQuestionCircle } from '@react-icons/all-files/fa/FaQuestionCircle';
import { FaRegEnvelope } from '@react-icons/all-files/fa/FaRegEnvelope';


import {
  NavLink as RouterLink
} from 'react-router-dom';
import { NavContext } from '../../containers/MainContainer';
import { ConfirmModal } from '../modal/Confirm';
import { MapSettings } from '../config/MapSettings';
// import { ProfileContainer } from '../../containers/dashboard/ProfileContainer';
import { useSelector } from 'react-redux';

const userNav = [
  {
    name: 'My Profile',
    icon: FaRegUser,
    to: '/my-profile'
  }
];

const adminNav = [
  {
    name: 'Account',
    icon: FaRegIdCard,
    to: '/account-info'
  },
  {
    name: 'Data Upload',
    icon: FaUpload,
    to: '/upload-data'
  },
  {
    name: 'Data Sharing',
    icon: FaShareAlt,
    to: '/data-sharing'
  }
];

const appNav = [
  {
    name: 'Map',
    icon: FaRegCompass,
    to: '/'
  },
  {
    name: 'Dashboard',
    icon: FaRegListAlt,
    to: '/dashboard'
  },
  // {
  //   name: 'Assignments',
  //   icon: FaRegCalendarCheck,
  //   to: '/assignments'
  // },
];

// const logoutNav = {
//   name: 'Logout',
//   icon: FaSignOutAlt,
//   to: '/logout'
// };

// @todo define props
export const Sidebar = (props) => {
  const user = useSelector(state => state.user);
  const nav = useContext(NavContext);
  return (
    <Flex className="sidebar" bg={useColorModeValue('white', 'gray.900')}>
      <Box
        display="flex"
        flexDirection="column"
        borderRight="1px"
        boxShadow="sm"
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        w={nav.isOpen ? '11rem' : '3.25rem'}
        h="full"
        overflow="hidden"
      >
        {appNav.map((link) => (
          <NavItem to={link.to} key={link.name} icon={link.icon}>
            {link.name}
          </NavItem>
        ))}
        <Divider />
        {userNav.map((link) => (
          <NavItem to={link.to} key={link.name} icon={link.icon}>
            {link.name}
          </NavItem>
        ))}
        <React.Fragment>
          {['ADMIN'].includes(user.role) && adminNav.map((link) => (
            <NavItem to={link.to} key={link.name} icon={link.icon}>
              {link.name}
            </NavItem>
          ))}
        </React.Fragment>
        <Settings />
        <Support />
        <LogoutButton logoutAction={props.logout} navOpen={nav.isOpen} />
        <Text fontSize="0.75rem" color="gray.400" mt="auto" textAlign="center">
          v{process.env.REACT_APP_VERSION}
        </Text>
      </Box>
    </Flex>
  );
};

export const Support = () => {

  return (
    <Popover placement="right">
      <PopoverTrigger>
        <Button display="flex" textAlign="left" w="100%" color="inherit" fontWeight="normal" variant="link" style={{ textDecoration: 'none' }}>
          <NavIcon icon={FaQuestionCircle}>Support</NavIcon>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Box p="1rem">
              <Heading as="h4" fontSize="sm" mb={2}>Support</Heading>
              <Text fontSize="xs">Connect with our customer operations team: Send us an email with a brief description of how we can help.</Text>
              <Button mt={4} width="100%" size="sm" colorScheme="blue" leftIcon={<FaRegEnvelope />} variant="outline" as={Link} isExternal href="mailto:support@flowmsp.com">Contact FlowMSP Support</Button>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export const Settings = () => {

  return (
    <Popover placement="right">
      <PopoverTrigger>
        <Button display="flex" textAlign="left" w="100%" color="inherit" fontWeight="normal" variant="link" style={{ textDecoration: 'none' }}>
          <NavIcon icon={FaCog}>Settings</NavIcon>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Box p="1rem">
              <MapSettings />
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};


const NavItem = ({ icon, children, to }) => {

  const linkStyle = {
    textDecoration: 'none'
  };
  const activeStyle = {
    backgroundColor: 'var(--chakra-colors-blue-400)',
    color: 'white',
  };

  return (
    <Link as={RouterLink} end to={to} style={({isActive}) => isActive ? activeStyle : linkStyle} _focus={{
      boxShadow: 'none'
    }}>
      <NavIcon icon={icon}>
        {children}
      </NavIcon>
    </Link>
  );
};

const NavIcon = (props) => {
  const nav = useContext(NavContext);

  return (
    <Tooltip hasArrow fontSize="1rem" padding="0.5rem" isDisabled={nav.isOpen} placement="right" label={props.children}>
      <Flex
        height="3.5rem"
        p="4"
        role="group"
        cursor="pointer"
        w="100%"
        alignItems="center"
        lineHeight="1.5rem"
        _hover={{
          bg: 'blue.400',
          color: 'white',
        }}
      >
        {props.icon && (
          <Icon
            mr="1.25rem"
            fontSize="1rem"
            _groupHover={{
              color: 'white',
            }}
            as={props.icon}
          />
        )}
        {nav.isOpen && <Text fontWeight="600">{props.children}</Text>}
      </Flex>
    </Tooltip>

  );
};

const LogoutButton = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Flex>
      <Button display="flex" textAlign="left" w="100%" color="inherit" fontWeight="normal" onClick={() => setIsOpen(true)} variant="link" style={{ textDecoration: 'none' }}>
        <NavIcon icon={FaSignOutAlt}>
          Logout
        </NavIcon>
      </Button>
      <ConfirmModal
        isOpen={isOpen}
        onDecline={() => setIsOpen(false)}
        onConfirm={props.logoutAction}
      >
        Are you sure you want to logout?
      </ConfirmModal>
    </Flex>
  );
};
