import React, {createRef, useState} from 'react';
import { Annotator, Tools } from '../annotator';
import './App.css';

function App() {
    const [ currentTool, setCurrentTool ] = useState(Tools.Pencil);
    const [ currentColor, setCurrentColor ] = useState('#ff0000');
    const [ currentJSON, setJSON ] = useState('');
    const annotatorRef = createRef();
    return (
        <div id="demo-main">
            <div className="annotator-container">
                <div className="tool-bar">
                    <div>
                        {Object.values(Tools).map(tool => (
                            <button key={tool} onClick={() => setCurrentTool(tool)}>
                                {tool}
                            </button>
                        ))}
                        <input type="color" value={currentColor} onChange={(e) => setCurrentColor(e.target.value)} />
                        <button className="symbolButton" onClick={() => {
                            setCurrentTool(Tools.Symbol);
                            annotatorRef.current.setImage('/images/NASPGLib/extended/A_256x256.png');
                        }}><img src="/images/NASPGLib/extended/A_256x256.png" alt="" /></button>
                        <button className="symbolButton" onClick={(e) => {
                            setCurrentTool(Tools.Symbol);
                            annotatorRef.current.setImage('/images/NASPGLib/extended/B_256x256.png');
                        }}><img src="/images/NASPGLib/extended/B_256x256.png" alt="" /></button>
                    </div>
                    <div className="tool-status">
                        <strong>Tool</strong>: {currentTool}, <strong>Color</strong>: <span style={{ color: currentColor }}>{currentColor}</span>
                    </div>
                </div>
                <Annotator
                    ref={annotatorRef}
                    className="annotator"
                    color={currentColor}
                    height={window.innerHeight - 200}
                    lineWidth={3}
                    tool={currentTool}
                    onChange={(data) => setJSON(JSON.stringify(data, null, 2))}
                    imageUrl="https://s3-us-west-2.amazonaws.com/flowmsp-test-image-bucket2/processed/demofd/dbba41f5-396e-48af-a3ca-9881062044a2/1015_Oconor_Ave_1_b4192db8_32d4_49da_a98c_f32adebee925-1024h.jpg"
                />
            </div>
            <div className="sidebar">
                <h2>JSON</h2>
                <textarea value={currentJSON} onChange={(e) => setJSON(e.target.value)}/>
                <button onClick={() => {
                    annotatorRef.current.fromJSON(JSON.parse(currentJSON));
                }}>Load from JSON
                </button>
            </div>
        </div>
    );
}

export default App;
