import React from 'react';
import { /*PrimaryButton,*/ SubmitButton} from '../form/Button';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import FormComponent from '../form/FormComponent';
// import * as assignmentAPI from '../../api/AssignmentAPI';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import {clearAssignments, createAssignments, selectAllAssignments} from '../../features/assignments/assignmentsSlice';
import { assignmentFieldValues } from '../../models/Assignment';
import { parseISO } from 'date-fns';
import { getNameOrEmail } from '../../helpers/User';
import {getLatestLocationAssignments} from './AssignmentUtil';


export const CreateAssignmentModal = (props) => {
  const { isOpen, /*onOpen,*/ onClose } = useDisclosure({
    isOpen: props.showModal,
    onClose: props.toggleModal
  });
  const customer = useSelector(state => state.customer);
  // const user = useSelector(state => state.user);
  const assignments = useSelector(selectAllAssignments);
  const { loading, updatingAction } = useSelector(state => state.assignments);
  const isUpdating = loading !== 'idle'
  const toast = useToast();
  const dispatch = useDispatch();
  const methods = useForm();

  const onClear = async() => {
    const assignmentData = {
      customerId: customer.customerId,
      locationId: props.locationIds.map(locationId => ({ id: locationId })),
      assignedTo: null,
      assignmentDueOn: null,
      assignmentStatus: null,
    };

    const result = await dispatch(clearAssignments(assignmentData));
    return new Promise(resolve => {
      if (clearAssignments.fulfilled.match(result)) {
        toast({
          title: 'Success',
          position: 'top',
          description: 'Assignments updated.',
          status: 'success',
          duration: 2500,
          isClosable: true
        });
        resolve();
        onClose();
      } else {
        toast({
          title: 'Error',
          position: 'top',
          description: 'Error updating assignments, please try again.',
          status: 'error',
          duration: 2500,
          isClosable: true
        });
        resolve();
      }
    });
  };

  const onSubmit = async(form) => {

    if (!form.assignedTo) {
      toast({
        title: 'Assign To',
        position: 'top',
        description: 'Please select someone to assign the location to',
        status: 'error',
        duration: 2500,
        isClosable: true
      });
      return Promise.resolve();
    }

    if (!form.assignmentDueOn) {
      toast({
        title: 'Due Date',
        position: 'top',
        description: 'Please choose the date the assignment is due',
        status: 'error',
        duration: 2500,
        isClosable: true
      });
      return Promise.resolve();
    }

    if (!form.assignmentStatus) {
      toast({
        title: 'Status',
        position: 'top',
        description: 'Please choose the assignment status',
        status: 'error',
        duration: 2500,
        isClosable: true
      });
      return Promise.resolve();
    }

    const assignmentData = {
      customerId: customer.customerId,
      locationId: props.locationIds.map(locationId => ({ id: locationId })),
      assignedTo: form.assignedTo || null,
      assignmentDueOn: form.assignmentDueOn || null,
      assignmentStatus: form.assignmentStatus,
      notes: form.assignmentNotes,
    };

    // console.log({ assignmentData, form });
    // return;

    // {
    //  "customerId":"7b663fd7-3c7f-4c9a-a369-a06e4de3126d",
    //  "locationId":[{"id":"e0ccd2e7-bcc4-418e-bad2-f694c3ade4aa"}],
    //  "assignedTo":"406fe421-b75b-413a-86bb-7428c9426094",
    //  "assignmentDueOn":"2024-04-20T05:00:00.000Z",
    //  "assignmentStatus":"OPEN"
    //  }

    const result = await dispatch(createAssignments(assignmentData));
    return new Promise(resolve => {
      if (createAssignments.fulfilled.match(result)) {
        toast({
          title: 'Success',
          position: 'top',
          description: 'Assignments updated.',
          status: 'success',
          duration: 2500,
          isClosable: true
        });
        resolve();
        onClose();
      } else {
        toast({
          title: 'Error',
          position: 'top',
          description: 'Error updating assignments, please try again.',
          status: 'error',
          duration: 2500,
          isClosable: true
        });
        resolve();
      }
    });
  };

  const formFields = [
    {
      id: 'assignedTo',
      name: 'assignedTo',
      label: 'Assign To',
      // type: 'select',
      type: 'search-select',
      options: [
        {label: <em>Choose...</em>, value: '' },
      ].concat(customer.users
          .filter(u => u.role !== 'USER')
          // .sort((a, b) => (a.firstName||'').localeCompare(b.firstName||''))
            .map(u => ({
        label: getNameOrEmail(u),
        value: u.id
      }))),
      // required: true,
    },
    {
      id: 'assignmentDueOn',
      name: 'assignmentDueOn',
      type: 'date',
      label: 'Assignment Due On',
      // required: true, // <-- enabling this causes a crash, don't do it
    },
    {
      id: 'assignmentStatus',
      name: 'assignmentStatus',
      label: 'Status',
      type: 'select',
      options: Object.keys(assignmentFieldValues).map(key => ({
        label: assignmentFieldValues[key],
        value: key
      })),
      // required: true,
    },
    {
      id: 'assignmentNotes',
      name: 'assignmentNotes',
      label: 'Notes',
      type: 'textarea',
      placeholder: ''
    }
  ];

  const activeAssignment = getLatestLocationAssignments(assignments, props.locationIds[0]);

  const formData = () => {

    if (activeAssignment) {
      return {
        assignmentStatus: activeAssignment.assignmentStatus === '' ? 'OPEN' : activeAssignment.assignmentStatus,
        assignedTo: activeAssignment.assignedTo || '',
        assignmentDueOn: activeAssignment.assignmentDueOn ? parseISO(activeAssignment.assignmentDueOn) : '',
        assignmentNotes: activeAssignment.notes || '',
      };
    }
      return {
        assignedTo: '',
        assignmentStatus: 'OPEN',
        assignmentDueOn: '',
        assignmentNotes: ''
      };

  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalHeader>
              Manage Assignment
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormComponent data={formData()} formFields={formFields} />
            </ModalBody>
            <ModalFooter style={{display: 'flex', justifyContent: 'space-between'}}>
              {activeAssignment && <Button variant="outline" color="red.400" isDisabled={isUpdating} isLoading={isUpdating && updatingAction === 'clear'} onClick={onClear} mt={4}>Clear Assignment</Button>}
              <SubmitButton isDisabled={isUpdating} isLoading={methods.formState.isSubmitting && updatingAction === 'update'} mt={4}> Save </SubmitButton>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>

  );

};
