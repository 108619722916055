import * as z from 'zod';

// OPEN, IN_PROGRESS, NEEDS_FEEDBACK, COMPLETE, CANCELLED

export const OPEN = 'OPEN';
export const IN_PROGRESS = 'IN_PROGRESS';
export const NEEDS_FEEDBACK = 'NEEDS_FEEDBACK';
export const COMPLETE = 'COMPLETE';
export const CANCELLED = 'CANCELLED';

export const AssignmentStatus = z.enum([
  OPEN,
  IN_PROGRESS,
  NEEDS_FEEDBACK,
  COMPLETE,
  CANCELLED
]);

export const assignmentFieldValues = {
  [OPEN]: 'Open',
  [IN_PROGRESS]: 'In Progress',
  [NEEDS_FEEDBACK]: 'Needs Feedback',
  [COMPLETE]: 'Complete',
  [CANCELLED]: 'Cancelled'
};
