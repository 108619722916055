import React, { Component } from 'react';
import { connect } from 'react-redux';
import Annotator from '../annotator/Annotator';
import ColorPicker from '../components/annotator/ColorPicker';
import { Gallery } from 'react-grid-gallery';
import ErrorBoundary from './ErrorBoundary';
import { Button, ButtonGroup, Divider, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { FaUnderline } from '@react-icons/all-files/fa/FaUnderline';
import { FaBold } from '@react-icons/all-files/fa/FaBold';
import { FaItalic } from '@react-icons/all-files/fa/FaItalic';


// const KeyCodes = {
//   comma: 188,
//   enter: 13,
// };
// const delimiters = [KeyCodes.comma, KeyCodes.enter];

const manipulationTools = ['Select', 'Delete'];
const drawingTools = ['Pencil', 'Line', 'Circle', 'Rectangle'];
const lineWidthOptions = [3, 5, 7, 9, 11];
const fontSizeOptions = [14, 18, 22, 26, 30, 34, 38, 42];

const annotatorSymbols = [
  'NASPGLib/extended/A_256x256.png',
  'NASPGLib/extended/B_256x256.png',
  'NASPGLib/extended/C_256x256.png',
  'NASPGLib/extended/D_256x256.png',
  'NASPGLib/preplan/Alarm_Detectors_Detailed/Annunciator-Panel_256x256.png',
  'NASPGLib/preplan/Key_or_Knox_Box/Plain_256x256.png',
  'NASPGLib/extended/Electric_256x256.png',
  'NASPGLib/extended/Gas_256x256.png',
  'NASPGLib/extended/RoofAccess_256x256.png',
  'annotator/exterior_side_1_256x256.png',
  'annotator/exterior_side_2_256x256.png',
  'annotator/exterior_side_3_256x256.png',
  'annotator/exterior_side_4_256x256.png',
  'annotator/compass_rose_256x256.png',
  'annotator/compass_north_256x256.png',
  'annotator/elevator_256x256.png',
  'annotator/stairs_256x256.png',
  'annotator/apparatus_ambulance_128x256.png',
  'annotator/apparatus_engine_128x256.png',
  'annotator/apparatus_ladder_128x256.png',
  'annotator/apparatus_officer_128x256.png',
  'annotator/apparatus_pumper_128x256.png',
  'annotator/oneway_left_256x256.png',
  'annotator/oneway_right_256x256.png',
  'annotator/road_closed_256x256.png',
  'annotator/ac_unit_256x256.png',
  'annotator/hydrant_256x256.png',
  'annotator/aed_256x256.png',
  'NASPGLib/extended/GenericHazard_256x256.png',
  'annotator/sprinkler_256x256.png',
  'annotator/standpipe_256x256.png',
  'annotator/floridarooftruss_256x256.png',
  'NASPGLib/extended/TrussedRoof_256x256.png',
  'annotator/photovoltaic_256x256.png',
  'annotator/photovoltaic_roofmounted_256x256.png',
  'annotator/structure_hazard_ds_256x256.png',
  'annotator/structure_hazard_normal_256x256.png',
  'annotator/structure_hazard_x_256x256.png',
  'annotator/facp_256x256.png',
  'annotator/fdsp_256x256.png',
  'annotator/main_electric_disconnect_256x256.png',
  'annotator/osy_valve_256x256.png',
  'annotator/piv_256x256.png',
  'annotator/water_meter_256x256.png',
  'annotator/water_shutoff_256x256.png',
  'NASPGLib/preplan/Fire_Suppression_Detailed/DETAIL_Fire-Department-Connection--FDC1_256x256.png',
  'NASPGLib/extended/SprinklerConnection_256x256.png',
  'NASPGLib/extended/StandpipeConnection_256x256.png',
  'NASPGLib/Hazardous_Materials/HAZARD_NFPA74_MLTCLR_SOLID_DETAIL__256x256.png',
  'NASPGLib/Hazardous_Materials/Class-1-Blasting-Agent-15_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-1-Explosives-11_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-1-Explosives-12_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-1-Explosives-13_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-1-Explosives-14_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-1-Explosives-15_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-1-Explosives-16_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-1-Explosives_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-2-Chlorine_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-2-Flammable-Gas-21_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-2-Flammable-Gas_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-2-Flammable-Liquid_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-2-Flammable-Solid_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-2-Inhalation-Hazards-23_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-2-Non-Flammable-Gas-22_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-2-Oxygen-22_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-3-Combustible_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-3-Flammable_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-3-Fuel-Oil_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-3-Gasoline_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-4-Dangerous-When-Wet_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-4-Flammable-Solids_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-4-Spontaneously-Combustible_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-5-Organic-Peroxides-51_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-5-Organic-Peroxides-52_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-5-Oxidizers-51_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-6-Infectious_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-6-Inhalation-Hazards_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-6-Poison-Gas_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-6-Poisons_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-6-Toxic-Gas_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-6-Toxic_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-7-Radioactive_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-8-Acids_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-8-Alkaline_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-8-Corrosive_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-9_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-Other-DANGEROUS_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-Other-HOT_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-Other-Store-Away-From-Food_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-Other-V2_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-Other-V3_256x256.png',
  'NASPGLib/Hazardous_Materials/Class-Other_256x256.png',
];

export class AnnotatorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tool: 'Select',
      color: 'black',
      lineWidth: 3,
      fontSize: 16
    };
    this.json = {};
    this.svg = {};
    // this._annotator;
  }

  renderButton(tool, label) {
    const { tool: activeTool } = this.state;

    // Rudimentary setting of default colors
    let { color } = this.state;
    if (tool === 'Text Bubble') {
      color = 'white';
    }

    return (
      <Button
        size="sm"
        key={tool}
        variant={activeTool === tool ? 'solid' : 'outline'}
        colorScheme={tool === activeTool ? 'blue' : 'gray'}
        isActive={tool === activeTool}
        onClick={e => this.setState({ tool, color })}
      >
        {label}
      </Button>
    );
  }

  myTileViewportStyleFn() {
    const style = {
      // height: '60px',
      width: '60px',
    };
    return style;
  }

  myThumbnailStyleFn() {
    const style = {
      // height: '60px',
      width: '60px',
    };
    return style;
  }

  renderSymbolDropdown() {
    const { tool: activeTool } = this.state;
    // const allowSymbolOption = activeTool === 'Symbol';
    const symbolToolActive = activeTool === 'Symbol';
    const IMAGES = [];

    for (let ii = 0; ii < annotatorSymbols.length; ii++) {
      const path = `/images/${annotatorSymbols[ii]}`;
      //This is to test the icon in development environment
      //const path = 'https://s3-us-west-2.amazonaws.com/flowmsp-test-image-bucket2/icons/NASPGLib/Hazardous_Materials/' + annotatorSymbols[ii] + '_256x256.png';
      const image = {
        src: path,
        // thumbnail: path,
        width: 50,
        height: 50,
        caption: annotatorSymbols[ii],
        isSelected: symbolToolActive ? (this.state.symbolType === annotatorSymbols[ii]) : false
      };
      IMAGES.push(image);
    }
    return (
      <Gallery images={IMAGES}
        enableLightbox={false}
        thumbnailStyle={this.myThumbnailStyleFn}
        tileViewportStyle={this.myTileViewportStyleFn}
        enableImageSelection={symbolToolActive}
        onClick={(index) => {
          if (!symbolToolActive) {
            return;
          }
          const path = IMAGES[index].src;
          const option = IMAGES[index].caption;
          this._annotator.setImage(path);
          this.setState({
            symbolType: option,
            symbolPath: path
          });
        }
        }
        onSelect={(index) => {
          if (!symbolToolActive) {
            return;
          }
          const path = IMAGES[index].src;
          const option = IMAGES[index].caption;
          this._annotator.setImage(path);
          this.setState({
            symbolType: option,
            symbolPath: path
          });
        }
        }
      />
    );
  }

  renderSizeDropdown() {
    const { tool: activeTool } = this.state;
    // const allowSizeOption = activeTool === 'Line' || activeTool === 'Pencil';
    const textToolActive = activeTool === 'Text';

    if (textToolActive) {
      return (
        <Menu>
          <MenuButton
            disabled={!textToolActive}
          >
            <Button as="span" size="sm">
              Font Size ⏷
              <Divider marginX={2} orientation={'vertical'} />
              {this.state.fontSize && `${this.state.fontSize}`}
            </Button>
          </MenuButton>
          <MenuList>
            {fontSizeOptions.map(option => (
              <MenuItem
                key={`${option}`}
                onClick={() => {
                  this._annotator.setFontSize(option);
                  this.setState({ fontSize: option });
                }}
              >
                {`${option}`}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      );
    }
    return (
      <Menu>
        <MenuButton>
          <Button as="span" size="sm">
            Line Width ⏷
            <Divider marginX={2} orientation={'vertical'} color="black" />
            {this.state.lineWidth && `${this.state.lineWidth}`}
          </Button>
        </MenuButton>
        <MenuList>
          {lineWidthOptions.map(option => (
            <MenuItem
              key={`${option}`}
              onClick={() => this.setState({ lineWidth: option })}
            >
              {`${option}px`}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  }

  render() {
    const { tool: activeTool } = this.state;
    // const allowSizeOption = activeTool === 'Line' || activeTool === 'Pencil';
    const allowColorOption = activeTool !== 'Delete';
    const textToolActive = activeTool === 'Text';
    // const symbolToolActive = activeTool === 'Symbol';

    const toolBarStyle = {
      zIndex: '2',
      padding: '0.5rem',
      flexWrap: 'wrap'
    };
    return (
      <ErrorBoundary>
        <React.Fragment>
          <div style={{ zIndex: 1, position: 'absolute', left: 0, top: 0, height: '100vh', width: '100vw', background: 'rgb(39, 39, 39, 0.8)' }} />
          <div style={{ zIndex: 2, height: '100%', width: '100%', background: 'white' }}>
            <HStack spacing="1rem" {...toolBarStyle} justifyContent="center">
              <ButtonGroup spacing={1}>
                {manipulationTools.map(tool => this.renderButton(tool, tool))}
              </ButtonGroup>
              <ButtonGroup spacing={1}>
                {drawingTools.map(tool => this.renderButton(tool, tool))}
              </ButtonGroup>
              <ButtonGroup spacing={1}>
                {this.renderButton('Symbol', 'Symbol')}
              </ButtonGroup>
              <ButtonGroup spacing={1}>
                {this.renderButton('Text', 'Text Label')}
                {this.renderButton('Text Bubble', 'Text Bubble')}
                <IconButton
                  aria-label="Bold"
                  icon={<FaBold />}
                  size="sm"
                  isDisabled={!textToolActive}
                  onClick={e => this._annotator.setBold()}
                />
                <IconButton
                  aria-label="Italic"
                  icon={<FaItalic />}
                  size="sm"
                  isDisabled={!textToolActive}
                  onClick={e => this._annotator.setItalic()}
                />
                <IconButton
                  aria-label="Underline"
                  icon={<FaUnderline />}
                  size="sm"
                  isDisabled={!textToolActive}
                  onClick={e => this._annotator.setUnderline()}
                />
              </ButtonGroup>
              <div>
                <ColorPicker
                  onChange={color => {
                    this.setState({
                      color
                    });
                  }
                  }
                  color={{ r: 0, g: 0, b: 0 }}
                  disabled={!allowColorOption}
                />
                {this.renderSizeDropdown()}
              </div>
              <ButtonGroup spacing={1}>
                <Button
                  variant="solid"
                  size="sm"
                  colorScheme="orange"
                  onClick={() => this.props.onCancel(this.json, this.svg)}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  size="sm"
                  colorScheme="green"
                  onClick={() => {
                    this.props.onSave(this.json, this.svg);
                  }}
                >
                  Save
                </Button>
              </ButtonGroup>
            </HStack>
            <div className="grid-symbol" style={{ fontSize: '12px' }}>
              Available Symbols
              {this.renderSymbolDropdown()}
            </div>
            <Annotator
              className="annotator"
              ref={c => (this._annotator = c)}
              height={window.innerHeight - 150}
              color={this.state.color}
              // lineWidth={allowSizeOption ? this.state.lineWidth : 5}
              lineWidth={this.state.lineWidth}
              fontSize={this.state.fontSize}
              tool={this.state.tool}
              imageUrl={
                this.props.image.href
              } /*Eventually change this to this._annotator.toJSON()*/
              onChange={(json, svg) => {
                this.json = json;
                this.svg = svg;
              }}
              defaultData={this.props.json}
            />
          </div>
        </React.Fragment>
      </ErrorBoundary>
    );
  }
}

export default connect()(AnnotatorContainer);
