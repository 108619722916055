import { Flex, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

export const ExternalContainer = (props) => {


  return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('blue.800', 'blue.800')}
      >
        {props.children}
      </Flex>
  );
};
