import React from 'react';
import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { FaExclamationCircle } from '@react-icons/all-files/fa/FaExclamationCircle';

export const CommercialTag = props => {
  return (
    <Tag colorScheme="blue" size={props.tagSize ? props.tagSize : 'md'}>
      <TagLeftIcon as={FaExclamationCircle} />
      <TagLabel fontWeight={600}>Commercial</TagLabel>
    </Tag>
  );
};
