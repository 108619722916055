import * as AJAXUtil from './AJAXUtil';
import { deleteCustomerHydrantAll } from '../actions/customer-actions';
import axios from 'axios';
import { store } from '../index';

// export function addHydrant(dispatch, hydrant, successCallback, errorCallback) {
//   AJAXUtil.AJAX({
//     method: 'PUT',
//     url: JSON.parse(sessionStorage.hydrant).href,
//     data: hydrant
//   }).then((res) => {
//     dispatch(addCustomerHydrant(res.data));
//     if (typeof successCallback === 'function') {
//       successCallback();
//     }
//   }).catch(function(error) {
//     if (error && error.response) {
//       if (typeof errorCallback === 'function') {
//         errorCallback();
//       }
//     }
//   });
// }

// export function editHydrant(dispatch, id, hydrant, successCallback, errorCallback) {
//   AJAXUtil.AJAX({
//     method: 'PATCH',
//     url: `${JSON.parse(sessionStorage.hydrant).href}/${id}`,
//     data: hydrant
//   }).then((res) => {
//     dispatch(editCustomerHydrant(res.data));
//     if (typeof successCallback === 'function') {
//       successCallback();
//     }
//   }).catch(function(error) {
//     if (error && error.response) {
//       if (typeof errorCallback === 'function') {
//         errorCallback();
//       }
//     }
//   });
// }

// export function deleteHydrant(dispatch, id, successCallback, errorCallback) {
//   AJAXUtil.AJAX({
//     method: 'DELETE',
//     url: `${JSON.parse(sessionStorage.hydrant).href}/${id}`
//   }).then((res) => {
//     console.log('delete hydrant res:', res);
//     if (res.data) {
//       if (res.data.success) {
//         dispatch(deleteCustomerHydrant(id));
//         if (typeof successCallback === 'function') {
//           console.log('res.data.success');
//           successCallback();
//         }
//       } else {
//         if (typeof errorCallback === 'function') {
//           errorCallback(res);
//         }
//       }
//     } else {
//       if (typeof errorCallback === 'function') {
//         errorCallback();
//       }
//     }
//   }).catch(function(error) {
//     if (error && error.response) {
//       if (typeof errorCallback === 'function') {
//         errorCallback();
//       }
//     }
//   });
// }

export function deleteAllHydrants(dispatch, successCallback, errorCallback, hydrantsLink) {
  AJAXUtil.AJAX({
    method: 'DELETE',
    url: `${hydrantsLink.href}`
  }).then((res) => {
    dispatch(deleteCustomerHydrantAll());
    if (typeof successCallback === 'function') {
      successCallback();
    }
  }).catch(function(error) {
    if (error && error.response) {
      if (typeof errorCallback === 'function') {
        errorCallback();
      }
    }
  });
}

export function uploadHydrant(data, successCallback, errorCallback, uploadConfig, hydrantsLink) {
  const state = store.getState();
  const jwt = state && state.session && state.session.jwt;
  const tokenType = state && state.session && state.session.tokenType;

  const config = {
    headers: {
      Authorization: `${tokenType} ${jwt}`,
      'Content-Type': 'multipart/form-data',
      'X-FlowMSP-Source': 'Web',
      'X-FlowMSP-Version': process.env.REACT_APP_VERSION
    },
    onUploadProgress: function(progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (typeof uploadConfig === 'function') {
        uploadConfig(percentCompleted);
      }
    }
  };
  const url = `${hydrantsLink.href}/upload`;
  axios.put(url, data, config).then((res) => {
    if (res.data) {
      if (res.data.successFlag === 0) {
        if (typeof successCallback === 'function') {
          const msg = res.data.log;
          successCallback(msg);
        }
      } else {
        if (typeof errorCallback === 'function') {
          const msg = res.data.log;
          errorCallback(msg);
        }
      }
    } else {
      if (typeof errorCallback === 'function') {
        errorCallback('Error processing file');
      }
    }
  }).catch(function(error) {
    if (typeof errorCallback === 'function') {
      errorCallback('Error while uploading file');
    }
  });
}


/**
 * New API Services
 * Individually controlalbel functions for each API call
 */
 export const fetchAllHydrants = (hydrantsLink) => {
  return AJAXUtil.AJAX({
    method: hydrantsLink.op,
    url: hydrantsLink.href,
  });
};

export const addHydrant = (hydrant, hydrantsLink) => {
  return AJAXUtil.AJAX({
      method: 'PUT',
      url: hydrantsLink.href,
      data: hydrant
    });
};

export function editHydrant(id, hydrant, hydrantsLink) {
  return AJAXUtil.AJAX({
    method: 'PATCH',
    url: `${hydrantsLink.href}/${id}`,
    data: hydrant
  });
}

export function deleteHydrant(id, hydrantsLink) {
  return AJAXUtil.AJAX({
    method: 'DELETE',
    url: `${hydrantsLink.href}/${id}`
  });
}

export const fetchPartnerHydrants = (partnerId, hydrantsLink) => {
  return AJAXUtil.AJAX({
    method: 'GET',
    url: `${hydrantsLink.href}/partners/${partnerId}`
  });
};
