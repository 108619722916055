import { Box, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
// import { WithContext as ReactTags } from 'react-tag-input';
import { SubmitButton } from './Button';
import FormComponent from './FormComponent';

// const KeyCodes = {
//   comma: 188,
//   enter: 13,
// };
// const delimiters = [KeyCodes.comma, KeyCodes.enter];

// const suggestedTags = [
//   { id: 'First In', text: 'First In' },
//   { id: 'Command', text: 'Command' },
//   { id: 'Floor Plan', text: 'Floor Plan'},
//   { id: 'Aerial', text: 'Aerial' },
// ];
const TagForm = (props) => {
  // const [state, setState] = useState({
  //   tags: props.tags ? props.tags : [],
  //   suggestions: suggestedTags
  // });

  // function handleDeleteTag(i) {
  //   setState({
  //     ...state,
  //     tags: state.tags.filter((tag, index) => index !== i),
  //   });
  // }
  //
  // function handleAdditionTag(tag) {
  //   setState({ ...state, tags: [...state.tags, tag] });
  // }

  // function renderTagInput() {
  //   if (!state.tags) {
  //     return null;
  //   }
  //   return (
  //     <ReactTags
  //       tags={state.tags}
  //       placeholder={'Add Tags'}
  //       suggestions={state.suggestions}
  //       handleDelete={handleDeleteTag}
  //       handleAddition={handleAdditionTag}
  //       minQueryLength={1}
  //       delimiters={delimiters}
  //       inline
  //     />
  //
  //   );
  // }

  // function handleConfirm() {
  //   props.onConfirm(state.tags);
  // }
  //
  // function handleDecline() {
  //   props.onDecline();
  // }

  return (
    <Box pb={4}>
      <Stack spacing={2}>
        <div>Image: {props.fileName}</div>
        <TagMultiCheckForm {...props} />
        {/* <div className="confirmTag">
          {renderTagInput()}
        </div>
        <HStack justifyContent="end" spacing="1rem">
          <OutlineButton onClick={handleDecline} width="8rem">Cancel</OutlineButton>
          <PrimaryButton onClick={handleConfirm} width="8rem">Save</PrimaryButton>
        </HStack> */}
      </Stack>
    </Box>
  );
};

// @todo this needs an overhaul
const TagMultiCheckForm = (props) => {
  const methods = useForm();
  const defaultTags = {
    'First In': false,
    'Command': false,
    'Floor Plan': false,
    'Aerial': false,
  };
  const [tagData, setTagData] = useState();

  const onSubmit = (data) => {
    props.onConfirm(Object.keys(data).filter(k => data[k]).map(t => ({id: t, text: t})));
  };

  useEffect(() => {
    const newTagData = defaultTags;
    props.tags.forEach(tag => {
      newTagData[tag.id] = true;
    });
    setTagData(newTagData);
  }, [props.tags]); // eslint-disable-line react-hooks/exhaustive-deps

  const fields = [
    { id: 'First In',
      name: 'First In',
      description: 'First In',
      type: 'checkbox',
    },
    { id: 'Command',
      name: 'Command',
      description: 'Command',
      type: 'checkbox'
    },
    { id: 'Floor Plan',
      name: 'Floor Plan',
      description: 'Floor Plan',
      type: 'checkbox'
    },
    { id: 'Aerial',
      name: 'Aerial',
      description: 'Aerial',
      type: 'checkbox'
    },
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {tagData && <FormComponent formFields={fields} data={tagData} />}
        <SubmitButton isLoading={methods.formState.isSubmitting} mt={4} type="submit">
          Save
        </SubmitButton>
      </form>
    </FormProvider>
  );
};


export default TagForm;
