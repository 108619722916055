
export const getNameOrEmail = (user) => {
  if (!user) {
    return 'User';
  }
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else if (user.email) {
    return user.email;
  }
    return 'User';
};
