import React from 'react';
import { useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import Modal from '../common/ModalComponent';
import { SubmitButton } from '../form/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { CreateUnit } from '../../models/Unit';
import { zodResolver } from '@hookform/resolvers/zod';
import FormComponent from '../../components/form/FormComponent';
import { useDispatch } from 'react-redux';
import { addUnit } from '../../features/departmentUnits/departmentUnitsSlice';
import { formFields, checkDuplicateUnits } from './utils';


export const AddUnitModal = (props) => {
  const methods = useForm({
    resolver: zodResolver(CreateUnit)
  });
  const dispatch = useDispatch();
  const toast = useToast();
  const customer = useSelector(state => state.customer);

  const initialData = {
    unit: '',
    desc: ''
  };

  const onSubmit = async (data) => {
    if (checkDuplicateUnits(props.units, data.unit)) {
      toast({
        title: 'Error',
        position: 'top',
        description: 'unit must be unique for PSAP.',
        status: 'error',
        duration: 2500,
        isClosable: true
      });
      return;
    }

    data.psapId = customer.psapId;

    const resultAction = await dispatch(addUnit(data));

    if (addUnit.fulfilled.match(resultAction)) {
      props.closeModal();
      toast({
        title: 'Unit Created',
        position: 'top',
        description: 'Unit created.',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
    } else {
      toast({
        title: 'Error',
        position: 'top',
        description: resultAction.payload || 'There was an error creating the unit. Please try again.',
        status: 'error',
        duration: 2500,
        isClosable: true
      });
    }
  };

  return (
    <Modal
      showModal={props.isOpen}
      toggleModal={props.closeModal}
      modal={{
        heading: 'Add Unit',
        body: (<FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormComponent formFields={formFields} data={initialData} />
            <SubmitButton mb="1rem" isLoading={methods.formState.isSubmitting} mt={4} colorScheme="blue" type="submit">
              Submit
            </SubmitButton>
          </form>
        </FormProvider >)
      }}
    />
  );
};