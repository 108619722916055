// FlowMSP Theme Extension for Chakra-UI
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    flowBlue: {
      400: '#0070f3',
    }
  },
  components: {
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: 'none'
        },
      },
    },
    Tabs: {
      baseStyle: {
        tab: {
          _focus: {
            boxShadow: 'none'
          },
        }
      },
    },
    Accordion: {
      baseStyle: {
        button: {
          _focus: {
            boxShadow: 'none'
          }
        }
      }
    },
    Popover: {
      baseStyle: {
        content: {
          _focus: {
            boxShadow: 'none'
          }
        }
      }
    }
  }
});

export default theme;
