import { Button } from '@chakra-ui/react';
import React from 'react';


export const SubmitButton = (props) => {
  return (
    <Button
      bg={'blue.400'}
      color={'white'}
      _hover={{
        bg: 'blue.500',
      }}
      isLoading={props.isLoading}
      type="submit"
      {...props}
    >
      {props.children}
    </Button>
  );
};

export const SubmitButtonDanger = (props) => (
  <SubmitButton bg={'red.600'} _hover={{
    bg: 'red.400',
  }} {...props} />
);

export const PrimaryButton = (props) => {

  return (
    <Button
      variant="solid"
      bg={'blue.400'}
      color={'white'}
      _hover={{
        bg: 'blue.500',
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export const OutlineButton = (props) => {

  return (
    <Button
      variant={'outline'}
      color={'blue.400'}
      _hover={{
        bg: 'gray.100'
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export const LinkButton = (props) => {

  return (
    <Button
      variant="link"
      fontSize="sm"
      color={'blue.400'}
      onClick={props.onClick}
      {...props}
    >
      {props.children}
    </Button>

  );
};

export const GhostButton = (props) => {

  return (
    <Button
      variant="ghost"
      color={'blue.400'}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export const DefaultButton = (props) => {

  return (
    <Button {...props}>
      {props.children}
    </Button>
  );
};
