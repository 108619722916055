import * as z from 'zod';


export const CreateUnit = z.object({
  unit: z.string(),
  desc: z.string().optional(),
});

export const EditUnit = z.object({
  unit: z.string(),
  desc: z.string().optional(),
});
