import { Box, Button, Flex, Stack, Text, Tooltip } from '@chakra-ui/react';
import React, {Fragment, useEffect, useState} from 'react';
import { formatDateTime } from '../../helpers/Date';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';

import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {fetchDispatchUnits, selectAllDispatches} from '../../features/dispatch/dispatchSlice';
import { selectLocationById } from '../../features/locations/locationsSlice';
import { HighHazardTag } from './HighHazardTag';
import { useIncidentIDParamEffect } from '../../hooks/useIncidentID';

export const SMSListInfo = ({
  selectALocation
}) => {
  const [selected, setSelected] = useState(null);
  const dispatchMessages = useSelector(selectAllDispatches);
  const [sortedMessages, setSortedMessages] = useState([]);
  const dispatch = useDispatch();
  const [enableUnitFilter, units, unitsLastFetch] = useSelector(state => [ state.user.uiConfig.enableUnitFilter, state.dispatch.units, state.dispatch.unitsLastFetch], shallowEqual);
  const selectedUnits = units.filter(unit => unit.selected);

  const maxUnitsToDisplay = 5;
  let selectedUnitText = selectedUnits.slice(0, maxUnitsToDisplay).map(u => u.unit).join(', ');
  const remainingUnits = selectedUnits.length - maxUnitsToDisplay;
  if (remainingUnits > 0) selectedUnitText += ` and ${remainingUnits} other unit${remainingUnits === 1 ? '' : 's'}`;

  useIncidentIDParamEffect(dispatchMessages, selectALocation);

  useEffect(() => {
    if (!unitsLastFetch) {
      dispatch(fetchDispatchUnits());
    }
  // }, [units]);
  }, [units, dispatch, unitsLastFetch]);

  React.useEffect(() => {
    const sorted = dispatchMessages
        .slice()
        .sort((a, b) => a.sequence < b.sequence ? 1 : -1)
    ;
    if (enableUnitFilter === 'true') {
      setSortedMessages(sorted.filter(message => message.isInUnitFilter));
    } else {
      setSortedMessages(sorted);
    }

  }, [dispatchMessages, enableUnitFilter]);

  const onMessageClick = (sms, /*index*/) => {
    // @todo - use sms id when backend updates api
    setSelected(sms.sequence);
    selectALocation(sms);
  };
  const messageStyle = {
    borderWidth: '1px',
    borderRadius: '0.5rem',
    padding: '0.75rem',
  };

  const selectedStyle = {
    bg: 'gray.50',
    borderColor: 'blue.400',
    boxShadow: 'lg'
  };

  return (
    <Stack spacing="0.5rem">
      <Box
          key={'sms-list-box-info'}
          style={{
            fontSize: '0.85em',
            color: 'gray.400'
          }}
      >
        {enableUnitFilter === 'true' ? (
            <em>Showing {sortedMessages.length} of {dispatchMessages.length} calls for {selectedUnitText}</em>
        ) : (
            <em>Showing recent {dispatchMessages.length} calls</em>
        )}

      </Box>
      {sortedMessages.map((sms, index) => (
        <Box
          key={`sms-list-box-${index}-${sms.sequence}`}
          onClick={() => onMessageClick(sms)}
          cursor="pointer"
          boxShadow={'sm'}
          {...messageStyle}
          _hover={{
            boxShadow: 'lg',
            borderColor: 'blue.400',
            borderWidth: '1px',
          }}
          {...(selected === sms.sequence ? selectedStyle : {})}
        >
          <DispatchMessage key={`sms-list-item-${index}`} {...sms} includeHeader />
        </Box>
      ))}
      {sortedMessages.length === 0 && (
          <Box
              key={'sms-list-box-none'}
              style={{
                fontSize: '0.85em',
                color: 'gray.400'
              }}
          >
            {dispatchMessages.length ? (
                <em>No dispatch calls match your unit filter</em>
            ) : (
                <em>No dispatch calls available</em>
            )}

          </Box>

      )}
    </Stack>
  );
};

export const DispatchMessage = ({
  address,
  customerID,
  incidentID,
  latLon,
  responding,
  sequence,
  text,
  textRaw,
  type,
  selectALocation,
  includeHeader,
  ...message
}) => {

  const dispatchLocation = useSelector(state => selectLocationById(state, message.locationID));

  const addressClick = () => {
    const destination = encodeURIComponent(address);
    const link = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
    window.open(link, '_blank');
  };

  const planAvailStyle = {
    margin: '-0.8rem',
    marginBottom: '0.5rem',
    bg: 'blue.600',
    padding: '0.25rem 0.75rem',
    borderTopRadius: '0.5rem',
  };

  return (<Fragment>
    {includeHeader && message.locationID && (
        <Box {...planAvailStyle} display="flex" justifyContent="space-between">
          <Text color="white" fontSize="xs" textTransform="uppercase" fontWeight="600">
            Pre-plan Available
          </Text>
          {dispatchLocation && dispatchLocation.isHighHazard && (<HighHazardTag description="" tagSize="sm"/>)}
        </Box>
      )
    }
    < Stack spacing="0.25rem">
      <Flex justifyContent="space-between">
        <DataText label="Type" value={type} />
        <Text flexShrink={0} minWidth="6.75rem" fontSize="sm" fontWeight="600">
          {formatDateTime(sequence)}
        </Text>
      </Flex>
      <DataText label="Address" value={
        <Tooltip openDelay={200} hasArrow placement="top" label="Open directions in Google Maps">
          <Button
            variant="link"
            size="sm"
            fontWeight="inherit"
            _hover={{
              textDecoration: 'underline',
              color: 'blue.400',
              fontWeight: '600'
            }}
            // rightIcon={}
            color="inherit"
            onClick={addressClick}
            whiteSpace="normal"
          >
            <Flex alignItems="baseline" textAlign="left"><Text marginRight="0.5rem">{address}</Text><FiExternalLink /></Flex>
          </Button>
        </Tooltip>
      } />
      <DataText label="Message" value={text} />
    </Stack>
  </Fragment >
  );
};

const DataText = ({ label, value, ...props }) => (
  <Stack spacing="0">
    <Text fontSize="xs" textTransform="uppercase" fontWeight="400">
      {label}
    </Text>
    <Text fontWeight="600" fontSize="sm" whiteSpace="pre-wrap" {...props.valProps}>{value ? value : '--'}</Text>
  </Stack>
);
