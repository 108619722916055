import * as z from 'zod';


export const ESOIntegration = z.object({
    ESOPrimarySubscription: z.string().regex(/^[a-zA-Z0-9]*$/, "Only letters and numbers are allowed for this field.").nonempty('This field is required.'),
    ESOSecondarySubscription: z.string().regex(/^[a-zA-Z0-9]*$/, "Only letters and numbers are allowed for this field."),
});

export const ESODispatchIntegration = z.object({
    ESODispatch: z.string().regex(/^[a-zA-Z0-9]*$/, "Only letters and numbers are allowed for this field.").nonempty('This field is required.'),
});

export const FirstArrivingIntegration = z.object({
    FirstArriving: z.string().regex(/^[a-zA-Z0-9]*$/, "Only letters and numbers are allowed for this field.").nonempty('This field is required.'),
});

export const EmergencyNetworkingIntegration = z.object({
    EmergencyNetworking: z.string().regex(/^[a-zA-Z0-9]*$/, "Only letters and numbers are allowed for this field.").nonempty('This field is required.'),
});
