import { Box, Divider, HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import { OutlineButton, PrimaryButton } from './Button';
class ConfirmForm extends React.Component {

  constructor(props) {
    super();
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleDecline = this.handleDecline.bind(this);
  }

  handleConfirm() {
    if (this.props.form) {
      this.props.onConfirm(this.props.form);
    } else {
      this.props.onConfirm();
    }
  }

  handleDecline() {
    this.props.onDecline();
  }

  render() {
    return (
      <Box>
        <form className="confirm">
          <Stack spacing="1rem">
            <div>{this.props.body}</div>
            <Divider />
            <HStack justifyContent="end" spacing="1rem">
              <OutlineButton onClick={this.handleDecline} width="8rem">No</OutlineButton>
              <PrimaryButton onClick={this.handleConfirm} width="8rem">Yes</PrimaryButton>
            </HStack>
          </Stack>
        </form>
      </Box>
    );
  }
}

export default ConfirmForm;
