import * as AJAXUtil from './AJAXUtil';
import config from '../config.js';

export function createAssignment(data, slug) {
  return AJAXUtil.AJAX({
    method: 'PUT',
    baseURL: config.API_BASE_URL,
    url: `/api/${slug}/assignment/createAssignment`,
    data: data
  });
}

export function createAssignments(data, slug) {
  return AJAXUtil.AJAX({
    method: 'PUT',
    baseURL: config.API_BASE_URL,
    url: `/api/${slug}/assignment/createAssignments`,
    data: data
  });
}

export function fetchAllAssignments(slug, customerId) {
  return AJAXUtil.AJAX({
    method: 'GET',
    baseURL: config.API_BASE_URL,
    url: `/api/${slug}/assignments/${customerId}`
  });
}
