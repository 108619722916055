import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import * as hydrantAPI from '../../api/HydrantAPI';

// Setting up according to redux-toolkit best practices
// Reference: https://redux-toolkit.js.org/usage/usage-guide


// Normalize Hydrant Data
const mapHydrantData = (hy, isMine) => ({
  ...hy,
  // position: new google.maps.LatLng(
  //   hy.latLon.latitude,
  //   hy.latLon.longitude
  // ),
  position: {
    lat: hy.latLon.latitude,
    lng: hy.latLon.longitude
  },
  isSelected: false,
  pinColor: hy.flowRange && hy.flowRange.pinColor ? hy.flowRange.pinColor : null,
  isMine
});

// interface ThunkAPI {
//   dispatch: Function
//   getState: Function
//   extra?: any
//   requestId: string
//   signal: AbortSignal
// }
// Thunks
export const fetchAllHydrants = createAsyncThunk(
  'hydrants/fetchAllHydrants',
  async(data, thunkAPI) => {
    const { customer } = thunkAPI.getState();
    const hydrantsLink = customer.links && customer.links.find(x => x.rel === 'hydrants');

    const response = await hydrantAPI.fetchAllHydrants(hydrantsLink);
    return response.data;
  }
);

export const addHydrant = createAsyncThunk(
  'hydrants/addHydrant',
  async(hydrant, thunkAPI) => {
    const { customer } = thunkAPI.getState();
    const hydrantsLink = customer.links && customer.links.find(x => x.rel === 'hydrants');

    const response = await hydrantAPI.addHydrant(hydrant, hydrantsLink);
    return response.data;
  }
);

export const editHydrant = createAsyncThunk(
  'hydrants/editHydrant',
  async(hydrant, thunkAPI) => {
    const { customer } = thunkAPI.getState();
    const hydrantsLink = customer.links && customer.links.find(x => x.rel === 'hydrants');

    const response = await hydrantAPI.editHydrant(hydrant.id, hydrant.data, hydrantsLink);
    return response.data;
  }
);
export const deleteHydrant = createAsyncThunk(
  'hydrants/deleteHydrant',
  async(hydrantId, thunkAPI) => {
    const { customer } = thunkAPI.getState();
    const hydrantsLink = customer.links && customer.links.find(x => x.rel === 'hydrants');

    return await hydrantAPI.deleteHydrant(hydrantId, hydrantsLink).then(res => {
      if (res.data && res.data.success) {
        return { ...res.data, id: hydrantId };
      }
      return thunkAPI.rejectWithValue(res.data);
    });

  }
);
// Partners
export const fetchPartnerHydrants = createAsyncThunk(
  'locations/fetchPartnerHydrants',
  async(partnerId, thunkAPI) => {
    const { customer } = thunkAPI.getState();
    const hydrantsLink = customer.links && customer.links.find(x => x.rel === 'hydrants');

    const response = await hydrantAPI.fetchPartnerHydrants(partnerId, hydrantsLink);
    return response.data;
  }
);

export const hydrantsAdapter = createEntityAdapter();
const initialState = hydrantsAdapter.getInitialState({
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
  lastFetch: null,
  lastPartnerFetch: null,
  selectedHydrantIds: [],
});

export const hydrantsSlice = createSlice({
  name: 'hydrants',
  initialState,
  reducers: {
    hydrantsLoading: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    hydrantsReceived: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.entities = action.payload;
      }
    },
    hydrantSelected: (state, action) => {
      const selectedHydrantIds = state.selectedHydrantIds;
      if (state.selectedHydrantIds.indexOf(action.payload) > -1) {
        state.selectedHydrantIds = selectedHydrantIds.filter(id => id !== action.payload);
      } else {
        state.selectedHydrantIds = [...selectedHydrantIds, action.payload];
      }
    },
    clearSelectedHydrants: (state) => {
      state.selectedHydrantIds = [];
    },
    setSelectedHydrants: (state, action) => {
      state.selectedHydrantIds = action.payload;
    },
    resetLastPartnerFetch: (state, action) => {
      state.lastPartnerFetch = null;
    },
    filterPartnerHydrants: (state, action) => {
      // given a new list of partner ids, filter locations to make sure they are in the list
      const excludedPartners = hydrantsAdapter.getSelectors().selectAll(state).filter(loc => !loc.isMine).filter(loc => !action.payload.includes(loc.customerId));
      hydrantsAdapter.removeMany(state, excludedPartners.map(loc => loc.id));
    }
  },
  extraReducers: (builder) => {
    // @todo consider using entityAdapter
    // Add reducers for additional action types here, and handle loading state as needed
    // fetchAllHydrants
    builder
      .addCase(fetchAllHydrants.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchAllHydrants.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.lastFetch = Date.now();
        if (action.payload.data && action.payload.data.length > 0) {
          hydrantsAdapter.upsertMany(state, action.payload.data.map(hy => mapHydrantData(hy, true)));
        }
      })
      .addCase(fetchAllHydrants.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.error;
          state.lastFetch = Date.now();
          state.currentRequestId = undefined;
        }
      });
    // addHydrant
    builder
      .addCase(addHydrant.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(addHydrant.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.currentRequestId = undefined;

          hydrantsAdapter.addOne(state, mapHydrantData(action.payload, true));
        }
      })
      .addCase(addHydrant.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.error;

          state.currentRequestId = undefined;
        }
      });
    // editHydrant
    builder
      .addCase(editHydrant.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(editHydrant.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.currentRequestId = undefined;
          hydrantsAdapter.setOne(state,
            mapHydrantData(action.payload, true)
          );
        }
      })
      .addCase(editHydrant.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.error;

          state.currentRequestId = undefined;
        }
      });
    // deleteHydrant
    builder
      .addCase(deleteHydrant.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteHydrant.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.currentRequestId = undefined;
          hydrantsAdapter.removeOne(state, action.payload.id);
        }
      })
      .addCase(deleteHydrant.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.error;

          state.currentRequestId = undefined;
        }
      });
    builder
      .addCase(fetchPartnerHydrants.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchPartnerHydrants.fulfilled, (state, action) => {
          state.loading = 'idle';
          state.currentRequestId = undefined;
          if (action.payload.data) {
            hydrantsAdapter.upsertMany(state, action.payload.data.map(hy => mapHydrantData(hy, false)));
            state.lastPartnerFetch = Date.now();
          }
      })
      .addCase(fetchPartnerHydrants.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  }
});

const { actions, reducer } = hydrantsSlice;

export const {
  hydrantSelected,
  setSelectedHydrants,
  clearSelectedHydrants,
  resetLastPartnerFetch,
  filterPartnerHydrants
} = actions;

export default reducer;


export const {
  selectById: selectHydrantById,
  selectAll: selectAllHydrants,
  selectTotal: selectTotalHydrants,
  selectIds: selectHydrantIds
} = hydrantsAdapter.getSelectors(state => state.hydrants);
