import * as AJAXUtil from './AJAXUtil';
import { setUser } from '../actions/user-actions';
import axios from 'axios';
import { store } from '../index';

export function getUser(dispatch) {
  const state = store.getState();
  const userLink = state && state.session && state.session.userLink;

  AJAXUtil.AJAX({
    method: 'GET',
    url: userLink.href
  }).then((res) => {
    dispatch(setUser(res.data));
  }).catch(err => {
    console.log('err getUser', err);
  });
}

export function changePassword(form, successCallback, errorCallback, dispatch) {
  const state = store.getState();
  const userLink = state && state.session && state.session.userLink;

  AJAXUtil.AJAX({
    method: 'POST',
    data: form,
    url: `${userLink.href}/password`
  }).then((res) => {
    if (typeof successCallback === 'function') {
      successCallback('Password changed successfully');
    }
  }).catch(function(error) {
    let eMessage = 'Something went wrong';
    if (error && error.response && error.response.data && error.response.data.errorMessage) {
      eMessage = error.response.data.errorMessage;
    }
    if (typeof errorCallback === 'function') {
      errorCallback(eMessage);
    }
  });
}

export function editMyProfile(form, successCallback, errorCallback, dispatch) {
  const state = store.getState();
  const userLink = state && state.session && state.session.userLink;

  AJAXUtil.AJAX({
    method: 'PATCH',
    data: form,
    url: userLink.href
  }).then((res) => {
    if (typeof successCallback === 'function') {
      successCallback('Successfully your profile is updated');
    }
  }).catch(function(error) {
    let eMessage = 'Something went wrong';
    if (error && error.response && error.response.data && error.response.data.errorMessage) {
      eMessage = error.response.data.errorMessage;
    }
    if (typeof errorCallback === 'function') {
      errorCallback(eMessage);
    }
  });
}

export function uploadUser(data, successCallback, errorCallback, uploadConfig) {
  const state = store.getState();
  const jwt = state && state.session && state.session.jwt;
  const tokenType = state && state.session && state.session.tokenType;
  const userLink = state && state.session && state.session.userLink;

  const config = {
    headers: {
      Authorization: `${tokenType} ${jwt}`,
      'Content-Type': 'multipart/form-data',
      'X-FlowMSP-Source': 'Web',
      'X-FlowMSP-Version': '2.40.0'
    }, // TODO: Replace with localStorage config or something
    onUploadProgress: function(progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (typeof uploadConfig === 'function') {
        uploadConfig(percentCompleted);
      }
    }
  };
  const url = `${userLink.href}/upload`;
  axios.put(url, data, config).then((res) => {
    if (res.data) {
      if (res.data.successFlag === 0) {
        if (typeof successCallback === 'function') {
          const msg = res.data.log;
          successCallback(msg);
        }
      } else {
        if (typeof errorCallback === 'function') {
          const msg = res.data.log;
          errorCallback(msg);
        }
      }
    } else {
      if (typeof errorCallback === 'function') {
        errorCallback('Error processing file');
      }
    }
  }).catch(function(error) {
    if (typeof errorCallback === 'function') {
      errorCallback('Error while uploading file');
    }
  });
}


// NEW v3 API
// Component Async Actions


export function fetchUser() {
  const state = store.getState();
  const userLink = state && state.session && state.session.userLink;

  return AJAXUtil.AJAX({
    method: 'GET',
    url: userLink.href
  });
}

// New Fn to get user profile
export function getUserAsync() {
  const state = store.getState();
  const userLink = state && state.session && state.session.userLink;

  return AJAXUtil.AJAX({
    method: 'GET',
    url: userLink.href
  }).then((res) => {
    return res.data;
  });
}

export function updateProfile(form) {
  const state = store.getState();
  const userLink = state && state.session && state.session.userLink;

  return AJAXUtil.AJAX({
    method: 'PATCH',
    data: form,
    url: userLink.href
  });
}

export function updateConfig(data) {
  const state = store.getState();
  const userLink = state && state.session && state.session.userLink;

  return AJAXUtil.AJAX({
    method: 'POST',
    data: data,
    url: `${userLink.href}/uiconfig`
  });
}

export function updatePassword(form) {
  const state = store.getState();
  const userLink = state && state.session && state.session.userLink;

  return AJAXUtil.AJAX({
    method: 'POST',
    data: form,
    url: `${userLink.href}/password`
  });
}
