import React from 'react';
import { format as formatDate, parseISO } from 'date-fns';
import * as util from '../../helpers/Util';
import { Box, HStack, Image, Text, Stack, Divider, Badge } from '@chakra-ui/react';

const HydrantsInfo = (props) => {
  return (
    <Box>
      <Stack spacing="1rem">
        {props.selectedHydrants.length === 0 ? (<Box>
          <Text size="md" fontWeight="600">No hydrants selected</Text>
          <Text size="sm">Choose hydrants on map to calculate total flow.</Text>
        </Box>
        ) : (props.selectedHydrants.map((hydrant, index) => (
          <HydrantBox key={`hydrantbox-${index}`} hydrant={hydrant} index={index} />
        )))}
      </Stack>
    </Box>
  );
};

export const HydrantBox = ({
  hydrant,
  index,
  ...props
}) => {

  return (
    <Box key={`hydrantbox-${index}`} borderWidth="1px" borderRadius="0.5rem" padding="0.5rem">
      <HStack key={`h-stack-${index}`}>
        <Image key={`h-img-${index}`} src={util.getMarkerIcon(hydrant.isMine, hydrant.dryHydrant, hydrant.inService, hydrant.flowRange && hydrant.flowRange.pinColor ? hydrant.flowRange.pinColor : null, true)} />
        <Stack key={`stack-data-${index}`} spacing="0.25rem" width="100%" paddingX="0.25rem">
          <HStack justifyContent="space-between">
            <Text fontSize="sm" fontWeight="600">{hydrant.flow ? `${hydrant.flow.toLocaleString()} gpm` : '--'}</Text>
            {hydrant.inService ? (
              <Badge colorScheme="green">In Service</Badge>
            ) : <Badge colorScheme="red">Out of Service</Badge>}
          </HStack>
          {!hydrant.inService && hydrant.outServiceDate && (<HStack justifyContent="space-between">
            <Text fontSize="sm" fontWeight="600">Out of Service Date:</Text>
            <Text fontSize="sm" fontWeight="600">{formatDate(parseISO(hydrant.outServiceDate), 'MM/dd/yyyy')}</Text>
          </HStack>
          )}
          {hydrant.streetAddress && <Divider />}
          <Text fontSize="sm">{hydrant.streetAddress}</Text>
          {hydrant.notes && (<React.Fragment>
            <Divider />
            <Text fontSize="sm" whiteSpace="pre-wrap"><Text as="span" fontSize="sm" fontWeight="600">Notes:&nbsp;</Text>{hydrant.notes}</Text>
          </React.Fragment>
          )}
          {hydrant.hydrantId && (<React.Fragment>
            <Divider />
            <Text fontSize="sm"><Text as="span" fontSize="sm" fontWeight="600">External ID:&nbsp;</Text>{hydrant.hydrantId}</Text>
          </React.Fragment>
          )}
          {hydrant.dryHydrant && <React.Fragment>
            <Divider />
            <Box>
              <Badge>Dry Hydrant</Badge>
            </Box>
          </React.Fragment>
          }
        </Stack>
      </HStack>
    </Box>
  );
};

export default HydrantsInfo;
