import { useToast } from '@chakra-ui/react';
import { addLocation, editLocation, setSelectedLocationId } from '../features/locations/locationsSlice';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';


export const PrePlanFormContainer = (props) => {
  // const customer = useSelector(state => state.customer);
  const selectedHydrantIds = useSelector(state => state.hydrants.selectedHydrantIds);
  // const { loading, error } = useSelector(state => state.locations);
  const methods = useForm();
  const toast = useToast();
  const dispatch = useDispatch();

  const formAction = props.locationDataProps.locationDataForm.action;


  const handleAddLocation = async(location) => {

    const resultAction = await dispatch(addLocation(location));

    if (addLocation.fulfilled.match(resultAction)) {
      dispatch(setSelectedLocationId(resultAction.payload.id));
      props.handleAddLocationSuccess();
      toast({
        title: 'Success',
        position: 'top',
        description: location.address.address1 ? `Pre-plan for ${location.address.address1} was successfully created.` : 'Pre-plan successfully created.',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
    } else {
      // @todo Validation errors
      toast({
        title: 'Failed to create location pre-plan',
        position: 'top',
        description: 'Please check location and building fields and try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }

  };

  // Need to determine which form action it is:
  // 'ADD_LOCATION' or 'NEW_BUILDING' appear to be same
  // 'REPLAN_LOCATION' has seperate behavior...

  const onSubmit = (data) => {
    // we need prePlanGeoOutline from mapcontainer state for now
    const location = {
      name: data.locationName,
      geoOutline: props.prePlanGeoOutline,
      address: {
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip
      },
      storey: data.storey,
      storeyBelow: data.storeyBelow,
      lotNumber: data.lotNumber,
      customerZone: data.customerZone,
      doNotShare: data.doNotShare,
      isHighHazard: data.isHighHazard,
      highHazardDescription: data.highHazardDescription,
      riskAssessmentScore: data.riskAssessmentScore,
      roofArea: data.roofArea,
      requiredFlow: data.requiredFlow,
      hydrants: selectedHydrantIds,
      building: {
        occupancyType: data.occupancyType,
        constructionType: data.constructionType,
        roofType: data.roofType,
        roofConstruction: data.roofConstruction,
        roofMaterial: data.roofMaterial,
        sprinklered: data.sprinklered,
        standPipe: data.standPipe,
        fireAlarm: data.fireAlarm,
        normalPopulation: data.normalPopulation,
        hoursOfOperation: data.hoursOfOperation,
        ownerContact: data.ownerContact,
        ownerPhone: data.ownerPhone,
        notes: data.notes,
        partnerNotes: data.partnerNotes,
        commercialStructure: data.commercialStructure
      }
    };

    if (formAction === 'EDIT_LOCATION' || formAction === 'EDIT_BUILDING' || formAction === 'REPLAN_LOCATION') {
      return handleLocationUpdate(location);
    } else if (formAction === 'ADD_LOCATION') {
      return handleAddLocation(location);
    }
      return Promise.resolve();
  };

  const handleLocationUpdate = async(location) => {
    const locationTmp = [
      {
        op: 'replace',
        path: '/name',
        value: location.name
      },
      {
        op: 'replace',
        path: '/address/address1',
        value: location.address.address1
      },
      {
        op: 'replace',
        path: '/address/address2',
        value: location.address.address2
      },
      {
        op: 'replace',
        path: '/address/city',
        value: location.address.city
      },
      {
        op: 'replace',
        path: '/address/state',
        value: location.address.state
      },
      {
        op: 'replace',
        path: '/address/zip',
        value: location.address.zip
      },
      {
        op: 'replace',
        path: '/storey',
        value: location.storey
      },
      {
        op: 'replace',
        path: '/storeyBelow',
        value: location.storeyBelow
      },
      {
        op: 'replace',
        path: '/lotNumber',
        value: location.lotNumber
      },
      {
        op: 'replace',
        path: '/customerZone',
        value: location.customerZone
      },
      {
        op: 'replace',
        path: '/doNotShare',
        value: location.doNotShare
      },
      {
        op: 'replace',
        path: '/isHighHazard',
        value: location.isHighHazard
      },
      {
        op: 'replace',
        path: '/highHazardDescription',
        value: location.highHazardDescription
      },
      {
        op: 'replace',
        path: '/riskAssessmentScore',
        value: location.riskAssessmentScore
      },
      {
        op: 'replace',
        path: '/roofArea',
        value: location.roofArea
      },
      {
        op: 'replace',
        path: '/requiredFlow',
        value: location.requiredFlow
      },
      {
        op: 'replace',
        path: '/hydrants',
        value: location.hydrants
      },
      {
        op: 'replace',
        path: '/building/occupancyType',
        value: location.building.occupancyType
      },
      {
        op: 'replace',
        path: '/building/constructionType',
        value: location.building.constructionType
      },
      {
        op: 'replace',
        path: '/building/roofType',
        value: location.building.roofType
      },
      {
        op: 'replace',
        path: '/building/roofConstruction',
        value: location.building.roofConstruction
      },
      {
        op: 'replace',
        path: '/building/roofMaterial',
        value: location.building.roofMaterial
      },
      {
        op: 'replace',
        path: '/building/sprinklered',
        value: location.building.sprinklered
      },
      {
        op: 'replace',
        path: '/building/standPipe',
        value: location.building.standPipe
      },
      {
        op: 'replace',
        path: '/building/fireAlarm',
        value: location.building.fireAlarm
      },
      {
        op: 'replace',
        path: '/building/normalPopulation',
        value: location.building.normalPopulation
      },
      {
        op: 'replace',
        path: '/building/hoursOfOperation',
        value: location.building.hoursOfOperation
      },
      {
        op: 'replace',
        path: '/building/ownerContact',
        value: location.building.ownerContact
      },
      {
        op: 'replace',
        path: '/building/ownerPhone',
        value: location.building.ownerPhone
      },
      {
        op: 'replace',
        path: '/building/notes',
        value: location.building.notes
      },
      {
        op: 'replace',
        path: '/building/commercialStructure',
        value: location.building.commercialStructure
      }
    ];

    if (props.prePlanGeoOutline) {
      if (props.prePlanGeoOutline.length > 2) {
        locationTmp.push({
          op: 'replace',
          path: '/geoOutline',
          value: props.prePlanGeoOutline
        });
      }
    }
    
    if(props.prePlanLatLng) {
      locationTmp.push({
        op: 'replace',
        path: '/latLon',
        value: props.prePlanLatLng
      });
    }

    const updateData = [];
    //Remove all such items whose value is null or undefined
    for (let ii = 0; ii < locationTmp.length; ii++) {
      if (typeof (locationTmp[ii].value) === 'undefined') {
        continue;
      }

      if (locationTmp[ii].value === null) {
        continue;
      }
      updateData.push(locationTmp[ii]);
    }

    const resultAction = await dispatch(editLocation({id: props.locationDataProps.locationDataForm.locationId, data: updateData}));

    if (editLocation.fulfilled.match(resultAction)) {
      props.handleEditLocationSuccess();
      toast({
        title: 'Success',
        position: 'top',
        description: location.address.address1 ? `Pre-plan for ${location.address.address1} was successfully updated.` : 'Pre-plan successfully updated.',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
    } else {
      toast({
        title: 'Update failed',
        position: 'top',
        description: 'Please check location and building fields and try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {props.children}
      </form>
    </FormProvider>
  );
};
