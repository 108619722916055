import React from 'react';
import PasswordResetComponent from '../components/PasswordReset/PasswordResetComponent';
import { ExternalContainer } from './ExternalContainer';
import { Flex } from '@chakra-ui/react';

const PasswordResetContainer = () => {

  return (
    <ExternalContainer>
      <Flex flexDirection="column" justifyContent="center">
        <PasswordResetComponent />
      </Flex>
    </ExternalContainer>
  );
};

export default PasswordResetContainer;
