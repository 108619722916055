import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PDFViewer, Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { Box, Text as ChText, Divider, Heading, Center, Spinner, Flex, Button } from '@chakra-ui/react';
import { FlexWithBackgroundTheme } from '../../containers/ThemeContainer';
import * as AJAXUtil from '../../api/AJAXUtil';
import { fetchAllHydrants, selectAllHydrants } from '../../features/hydrants/hydrantsSlice';
import { buildStaticMapUrl } from '../map/StaticMap';
import { buildingDataFields } from '../common/BuildingDataComponent';
import { FaFilePdf } from '@react-icons/all-files/fa/FaFilePdf';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: '24px 12px'
  },
  section: {
    margin: 12,
    padding: '0px 24px',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 12,
  },
  text: {
    fontSize: 12,
    fontWeight: 600
  },
  image: {
    maxWidth: '1024px'
  }
});

// Create Document Component
const PrePlanPDF = ({
  location,
  hydrants
}) => {

  const staticMapUrl = buildStaticMapUrl(location, hydrants);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={styles.section}>
            <Text style={styles.text}>{location.name}</Text>
            <Text style={styles.text}>{location.address.address1}</Text>
            <Text style={styles.text}>{location.address.address2}</Text>
            <Text style={styles.text}>{location.address.city}, {location.address.state} {location.address.zip}</Text>
            {location.lotNumber && <Text style={styles.text}>Lot #: {location.lotNumber}</Text>}
            {location.customerZone && <Text style={styles.text}>District: {location.customerZone}</Text>}
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>Floors Above: {location.storey}</Text>
            <Text style={styles.text}>Floors Below: {location.storeyBelow}</Text>
            <Text style={styles.text}>Roof Area: {location.roofArea}</Text>
            <Text style={styles.text}>Required Flow: {location.requiredFlow}</Text>
          </View>
        </View>
        {location.isHighHazard && <View style={styles.section}>
          <Text style={styles.text}>HIGH HAZARD</Text>
          {location.highHazardDescription && <Text style={styles.text}>{location.highHazardDescription}</Text>}
        </View>
        }
        {staticMapUrl && (
          <View style={styles.section}>
            <Image src={staticMapUrl} />
          </View>
        )}
        {location.building && (
          <View style={styles.section}>
            {location.building.commercialStructure && (
              <View style={styles.section}>
                <Text style={styles.text}>Commercial</Text>
              </View>
            )}
            {buildingDataFields.map((field, index) => (
              <DataField key={index} label={field.label} value={location.building[field.name]} />
            ))}
          </View>
        )}
      </Page>
      {hydrants && (
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.title}>Hydrants</Text>
          </View>
          {hydrants.map((hydrant, index) => (
            <HydrantData key={index} hydrant={hydrant} />
          ))}
        </Page>
      )}
      {location.images && location.images.length > 0 && (
        <Page size="A4" style={styles.page}>
          {location.images && location.images.filter(image => image.hrefThumbnail && image.hrefThumbnail !== 'NoImage').map((image, index) => {
            return (
              <PdfImage key={index} image={image} />
            );
          }
          )}
        </Page>
      )}
    </Document>
  );
};

const HydrantData = ({
  hydrant
}) => {
  // @todo map to existing field structur -- external ID needed, too?
  return (
    <View style={styles.section} wrap={false}>
      <DataField label="Lat/Lon" value={`${hydrant.position.lat}, ${hydrant.position.lng}`} />
      <DataField label="Address" value={hydrant.streetAddress} />
      <DataField label="Flow (GPM)" value={hydrant.flow} />
      <DataField label="Size" value={hydrant.size} />
      <DataField label="Notes" value={hydrant.notes} />
      <DataField label="Dry Hydrant" value={hydrant.dryHydrant ? 'Yes' : 'No'} />
      <DataField label="In Service" value={hydrant.inService ? 'Yes' : 'No'} />
      <DataField label="Out of Service Date" value={hydrant.outServiceDate} />
      <DataField label="External Hydrant Identifier" value={hydrant.hydrantId} />
    </View>
  );
};

const DataField = ({
  label,
  value }) => {

  const dfStyle = StyleSheet.create({
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    label: {
      ...styles.text,
      fontWeight: 'bold',
      width: '40%',
    },
    value: {
      ...styles.text,
      width: '60%'
    }
  });

  return (
    <View style={dfStyle.wrapper}>
      <Text style={dfStyle.label}>{label}: </Text>
      <Text style={dfStyle.value}>{value ? value : '--'}</Text>
    </View>
  );
};

const PdfImage = ({ image }) => {
  return (
    <View style={styles.section} wrap={false}>
      <Image style={{ width: '100%', height: 'auto', maxHeight: '90vh', objectFit: 'contain' }} src={image.hrefAnnotated ? image.hrefAnnotated : image.href} />
    </View>
  );

};
export const PrePlanExport = () => {

  const [loading, setLoading] = useState(true);
  const [/*error*/, setError] = useState(null);
  const [location, setLocation] = useState(null);

  const { id } = useParams();
  const hydrants = useSelector(selectAllHydrants);
  const customer = useSelector(state => state.customer);
  const dispatch = useDispatch();

  const [hydrantIds, setHydrantIds] = useState([]);
  useEffect(() => {
    if (hydrants.length === 0) {
      dispatch(fetchAllHydrants());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [dispatch, hydrants]);

  useEffect(() => {
    if (location && location.hydrants) {
      setHydrantIds(location.hydrants.map(hydrant => hydrant.id));
    }
  }, [location]);

  useEffect(() => {
    if (id) {
      const locationsLink = customer.links && customer.links.find(x => x.rel === 'locations');

      AJAXUtil.AJAX({
        method: 'GET',
        url: `${locationsLink.href}/${id}`
      }).then(res => {
        setLoading(false);
        setLocation(res.data);
      }).catch(err => {
        setError(err);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [id]);

  return (
    <FlexWithBackgroundTheme height={'calc(100vh - 4.25rem)'} overflow="auto" w="100%" flexDirection="column" paddingBottom="1.5rem" paddingX={'1.5rem'}>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading as="h2" mt="1rem" size="lg">Pre-Plan Export</Heading>
        <ChText>
          Location ID: {id}
        </ChText>
      </Flex>
      <Divider mb={4} />
      {
        location && location.images && location.images.length > 0 && (
          location.images.filter(image => image.hrefThumbnail === 'NoImage').length && (
            <Box mb={4}>
              <ChText fontSize="sm" mb={2}>We are unable to collate PDFs attached to this location. Please download these documents separately as needed.</ChText>
              <Flex flexDirection="column" alignItems="flex-start">{location.images.filter(image => image.hrefThumbnail === 'NoImage').map(pdf => {
                return (
                <Button variant="link"
                  fontSize="sm"
                  color={'blue.400'}
                  href={pdf.href}
                  leftIcon={<FaFilePdf />}
                >
                  {pdf.originalFileName}
                </Button>);
              }
              )}</Flex>
            </Box>
          )
        )
      }
      {loading && (
        <Center height="100vh" margin="auto auto">
          <Spinner
            thickness=".25rem"
            speed=".5s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      )
      }
      {
        !loading && location && (
          <Box h="100%" w="100%">
            <PDFViewer style={{
              height: '100%',
              width: '100%'
            }}>
              <PrePlanPDF hydrants={hydrants.filter(hyd => hydrantIds.includes(hyd.id))} location={location} />
            </PDFViewer>
          </Box>
        )
      }
    </FlexWithBackgroundTheme>
  );
};
