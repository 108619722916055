import React, { useEffect, useState } from 'react';
// import { GoogleMap, InfoWindow, Marker, Polygon, MarkerClusterer } from '@react-google-maps/api';
import { GMAPS_STATIC_URL, GMAPS_API_KEY, midPoint } from '../../helpers/GoogleMaps';
import { getMarkerIcon } from '../../helpers/Util';

const DEFAULT_ZOOM = 18;
const DEFAULT_SIZE_HORIZONTAL = 600;
const DEFAULT_SIZE_VERTICAL = 400;
const DEFAULT_SCALE = 2; // pixel density
const DEFAULT_FORMAT = 'png8';
const DEFAULT_MAP_TYPE = 'hybrid';
const DEFAULT_MAP_STYLES = 'feature:poi|element:labels|visibility:off';

const pathStyles = {
  weight: 2,
  color: 'blue',
  fillcolor: 'blue'
};

export const buildStaticMapUrl = (
  location, // building outline
  hydrants = [], // hydrants
  // need polygon and marker data
  size = `${DEFAULT_SIZE_HORIZONTAL}x${DEFAULT_SIZE_VERTICAL}`,
  zoom = DEFAULT_ZOOM,
  scale = DEFAULT_SCALE,
  format = DEFAULT_FORMAT,
  mapType = DEFAULT_MAP_TYPE,
) => {
  const locationCenter = midPoint(location.geoOutline.map(coor => ({ lat: coor.latitude, lng: coor.longitude })));
  const mapCenter = `${locationCenter.lat()},${locationCenter.lng()}`;

  // turn off points of interest labels
  const markerParams = hydrants.map(hyd => `markers=icon:${encodeURI(`https://app.flowmsp.com${getMarkerIcon(hyd.isMine, hyd.dryHydrant, hyd.inService, hyd.pinColor, hyd.isSelected, 18)}`)}|${hyd.position.lat},${hyd.position.lng}`).join('&');

  const polygonParams = `${Object.keys(pathStyles).map(key => `${key}:${pathStyles[key]}`).join('|')}|${location.geoOutline.map(coord => `${coord.latitude},${coord.longitude}`).join('|')}`;
  return `${GMAPS_STATIC_URL}?key=${GMAPS_API_KEY}&style=${DEFAULT_MAP_STYLES}&path=${polygonParams}&center=${mapCenter}&zoom=${zoom}&size=${size}&scale=${scale}&format=${format}&maptype=${mapType}${hydrants.length > 0 ? `&${markerParams}` : ''}`;
};

export const buildStaticMapHydrantUrl = (
  hydrant,
  size = `${DEFAULT_SIZE_HORIZONTAL}x${DEFAULT_SIZE_VERTICAL}`,
  zoom = DEFAULT_ZOOM,
  scale = DEFAULT_SCALE,
  format = DEFAULT_FORMAT,
  mapType = DEFAULT_MAP_TYPE,
) => {
  const markerParams = `markers=icon:${encodeURI(`https://app.flowmsp.com${getMarkerIcon(hydrant.isMine, hydrant.dryHydrant, hydrant.inService, hydrant.pinColor, hydrant.isSelected, 18)}`)}|${hydrant.position.lat},${hydrant.position.lng}`;
  const mapCenter = `${hydrant.position.lat},${hydrant.position.lng}`;
  return `${GMAPS_STATIC_URL}?key=${GMAPS_API_KEY}&style=${DEFAULT_MAP_STYLES}&center=${mapCenter}&zoom=${zoom}&size=${size}&scale=${scale}&format=${format}&maptype=${mapType}&${markerParams}`;
};

export const StaticMap = ({
  location,
  hydrants,
  ...props
}) => {
  const [staticMapUrl, setStaticMapUrl] = useState();

  useEffect(() => {
    if (location && location.geoOutline) {
      const url = buildStaticMapUrl(location, hydrants);
      setStaticMapUrl(url);
    }
  }, [location, hydrants]);

  return (
    <div>
      {staticMapUrl && (
        <img src={staticMapUrl} alt="static map" />
      )}
    </div>
  );
};
