import React, { forwardRef } from 'react';
import { Table, Tr, Td } from '@chakra-ui/react';

function ComponentToPrint(props, ref) {

  // constructor(props) {
  //   super(props);
  // }

  const renderRows = () => {
    return props.data.map(function(row, index) {
      return (<React.Fragment key={index}>
        {row.length > 0 && (
          <Tr key={index}>
            <Td px="0.25rem" column="Log">{row}</Td>
          </Tr>
        )}
      </React.Fragment>
      );
    });
  };


    if (!props.data) {
      return (
        <div ref={ref}>
          Data
        </div>
      );
    }

    return (
        <div ref={ref}>
          <Table><tbody>{renderRows()}</tbody></Table>
        </div>
    );

}

export default forwardRef(ComponentToPrint);
