import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { firebaseConfig } from '../helpers/Firebase';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setCurrentScreen } from 'firebase/analytics';

export function FirebaseAnalytics() {
  const location = useLocation();
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  useEffect(() => {
    const pagePath = location.pathname + location.search;
    setCurrentScreen(analytics, pagePath);
    logEvent(analytics, 'page_view', { pagePath });
  }, [location, analytics]);
  return null;
}
