import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  IconButton,
  Text,
  HStack,
  Flex,
  Select,
  Grid,
  Spinner,
  Center,
  Input,
  Divider,
  InputGroup,
  InputLeftElement
} from '@chakra-ui/react';
import { FiArrowDown } from '@react-icons/all-files/fi/FiArrowDown';
import { FiArrowUp } from '@react-icons/all-files/fi/FiArrowUp';
import { FaAngleDoubleLeft } from '@react-icons/all-files/fa/FaAngleDoubleLeft';
import { FaAngleDoubleRight } from '@react-icons/all-files/fa/FaAngleDoubleRight';
import { FaAngleLeft } from '@react-icons/all-files/fa/FaAngleLeft';
import { FaAngleRight } from '@react-icons/all-files/fa/FaAngleRight';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';

import ErrorBoundary from '../../containers/ErrorBoundary';
import { useTable, useResizeColumns, /*useBlockLayout, useGridLayout,*/ useSortBy, usePagination, /*Column,*/ useGlobalFilter, useFilters, useAsyncDebounce, useRowSelect, /*useFlexLayout*/ } from 'react-table';
// import { useState } from 'react';

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  gotoPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);

  useEffect(() => {
    if (globalFilter !== value) {
      setValue(globalFilter);
    }
  }, [globalFilter]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [globalFilter, value]);

  const onChange = useAsyncDebounce(val => {
    setGlobalFilter(val || undefined);
    gotoPage && gotoPage(0);
  }, 200);

  return (
    <InputGroup my="0.5rem" maxWidth="36rem">
      <InputLeftElement
        pointerEvents={'none'}
        children={<FaSearch color="gray.500" />}
      />
      <Input
        value={value || ''}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search ${count} record${count > 1 ? 's' : ''}`}
      />
    </InputGroup>
  );
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <input type="checkbox" ref={resolvedRef} {...rest} />
    );
  }
);

export function DataTable({
  data,
  columns,
  hiddenColumns = null,
  loading,
  globalSearch = false,
  selectable = false,
  Filters = null,
  activeFilters = null,
  savedFilters = [],
  chakraProps,
  initialSortBy = null,
  ...props
}) {
  const defaultColumn = React.useMemo(
    () => ({
      width: 80,
    }),
    []
  );

  const {
    allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    state,
    visibleColumns,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    rows,
    setGlobalFilter,
    setFilter,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
    setHiddenColumns
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      initialState: {
        hiddenColumns: hiddenColumns || [],
        sortBy: initialSortBy || []
      },
      defaultColumn
    },
    useFilters,
    // useFlexLayout,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useResizeColumns,
    hooks => {
      if (selectable) {
        hooks.visibleColumns.push(cols => [
          {
            id: 'selection',
            width: 35,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Box pl={4} pr={2} as="span">
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </Box>
            ),
            Cell: ({ row }) => (
              <Box pl={4} pr={2}>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </Box>
            ),
          },
          ...cols
        ]);
      }
    }
  );

  useEffect(() => {
    if (typeof props.rowsSelected === 'function') {
      props.rowsSelected(selectedFlatRows);
    }
  }, [selectedRowIds]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [selectedRowIds, props, selectedFlatRows]);

  useEffect(() => {
    if (typeof props.setVisibleHeaderIds === 'function') {
      props.setVisibleHeaderIds(visibleColumns.map(c => c.id));
    }
  }, [visibleColumns]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [visibleColumns, props]);

  useEffect(() => {
    if (activeFilters) {
      Object.keys(activeFilters).forEach(key => setFilter(key, activeFilters[key] ? activeFilters[key] : null));
    }
  }, [activeFilters]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [activeFilters, setFilter]);

  useEffect(() => {
    if (hiddenColumns) {
      setHiddenColumns(hiddenColumns);
    }
  }, [hiddenColumns]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [hiddenColumns, setHiddenColumns]);

  return (
    <ErrorBoundary>
      <Box overflowX="auto">
        {Filters && (<React.Fragment>
          <Filters
            rows={rows}
            data={data}
            visibleColumns={visibleColumns}
            allColumns={allColumns}
            onResetFilters={() => setGlobalFilter('')} />
          <Divider mb="0.5rem" />
        </React.Fragment>)}
        <Box>
          {globalSearch && (<React.Fragment>
            <Flex px="1rem" width="100%" alignItems="center" justifyContent="space-between">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                gotoPage={gotoPage}
              />
              {props.actionComponent}
            </Flex>
            <Divider mt="0.5rem" />
          </React.Fragment>
          )}
          <Table size="sm" {...getTableProps()} {...chakraProps}>
            <Thead>
              {headerGroups.map((headerGroup) => {
                const { key, ...headerTrProps} = headerGroup.getHeaderGroupProps();
                return (
                    <Tr key={key} {...headerTrProps}>
                      {headerGroup.headers.map((column) => {
                        const { key, ...columnProps } = column.getHeaderProps(
                            column.getSortByToggleProps()
                        );
                        return (
                            <Th
                                key={key}
                                {...columnProps}
                                isNumeric={column.isNumeric}
                                verticalAlign="bottom"
                            >
                              <HStack>
                                <Text>{column.render('Header')}</Text>
                                {column.isSorted ? (
                                    column.isSortedDesc ? (
                                        <FiArrowDown display="inline" aria-label="sorted descending"/>
                                    ) : (
                                        <FiArrowUp display="inline" aria-label="sorted ascending"/>
                                    )
                                ) : null}
                              </HStack>
                            </Th>
                        );
                      })}
                    </Tr>
                );
              })}
            </Thead>
            {loading ? (
              <Tbody>
                <Tr>
                  <Td
                    textAlign="center"
                    bg="white"
                    colSpan="100%"
                    >
                    <TableLoader />
                  </Td>
                </Tr>
              </Tbody>
            ) : (
              <Tbody {...getTableBodyProps()}>
                {page.length < 1 ? (
                  <Tr>
                    <Td
                     colSpan="100%"
                      textAlign="center"
                      bg="white"
                    >
                      <Box margin={8}>
                        <Text fontStyle="italic">No records found</Text>
                      </Box>
                    </Td>
                  </Tr>
                ) : (
                  page.map((row) => {
                    prepareRow(row);
                    const { key, ...rowProps } = row.getRowProps();
                    return (
                      <Tr key={key} {...rowProps}>
                        {row.cells.map((cell) => {
                          const { key, ...colProps } = cell.getCellProps({
                            className: cell.column.className
                          });
                          return (
                              <Td
                                  key={key}
                                  {...colProps}
                                  isNumeric={cell.column.isNumeric}
                                  // display="flex"
                                  // alignItems="center"
                              >
                                {cell.render('Cell')}
                              </Td>
                          );
                        })}
                      </Tr>
                    );
                  }))}
              </Tbody>)}
          </Table>
          <Grid mt="1rem">
            <Box justifySelf="start">
              <Select
                placeholder=""
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map(size => (
                  <option key={`pagesize-option-${size}`} value={size}>
                    Show {size}
                  </option>
                ))}
              </Select>
            </Box>
            <HStack justifySelf="center">
              <HStack>
                <IconButton
                  aria-label="Go to Page 1"
                  icon={<FaAngleDoubleLeft />}
                  onClick={() => gotoPage(0)}
                  isDisabled={!canPreviousPage}
                />
                <IconButton
                  aria-label="Previous Page"
                  icon={<FaAngleLeft />}
                  onClick={() => previousPage()}
                  isDisabled={!canPreviousPage}
                />
              </HStack>
              <Box>
                <Text>
                  Page <strong>{pageIndex + 1}</strong> of <strong>{pageOptions.length}</strong>
                </Text>
              </Box>
              <HStack>
                <IconButton
                  aria-label="Next page"
                  icon={<FaAngleRight />}
                  onClick={() => nextPage()}
                  isDisabled={!canNextPage}
                />
                <IconButton
                  aria-label="Go to Last Page"
                  icon={<FaAngleDoubleRight />}
                  onClick={() => gotoPage(pageCount - 1)}
                  isDisabled={!canNextPage}
                />
              </HStack>
            </HStack>
          </Grid>
        </Box>
      </Box>
    </ErrorBoundary>
  );
}

const TableLoader = () => {

  return (
    <Center>
      <Spinner
        thickness=".25rem"
        speed=".5s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Center>
  );
};
