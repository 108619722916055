import React from 'react';
import { CompactPicker } from 'react-color';
import { Box, Button, Divider, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger } from '@chakra-ui/react';

// class ColorPicker extends React.Component {
//   state = {
//     displayColorPicker: false,
//     color: this.props.color
//   };
//
//   handleClick = e => {
//     this.setState({
//       displayColorPicker: !this.state.displayColorPicker,
//       x: e.pageX
//     });
//   };
//
//   handleClose = () => {
//     this.setState({ displayColorPicker: false });
//   };
//
//   handleChange = color => {
//     this.setState({ color: color.rgb, displayColorPicker: false });
//     this.props.onChange(color.hex);
//   };
//
//   render() {
//     return (
//       <Popover>
//         <PopoverTrigger>
//           <Button size="sm" disabled={this.props.disabled} onClick={this.handleClick}>
//             Color ⏷
//             <Divider marginX={2} orientation={'vertical'} />
//             <Box width={4} height={4} background={'rgb(0,0,0)'} />
//           </Button>
//         </PopoverTrigger>
//         <PopoverContent>
//           <PopoverArrow />
//           <PopoverCloseButton />
//           <PopoverBody>
//             <CompactPicker
//               color={this.state.color}
//               onChange={this.handleChange}
//             />
//           </PopoverBody>
//         </PopoverContent>
//       </Popover>
//     );
//   }
// }

const ColorPickerFunc = (props) => {

  const [state, setState] = React.useState({
    displayColorPicker: false,
    color: props.colors
  });

  const [colorRGB, setColorRGB] = React.useState('rgb(0,0,0)');

  const handleClick = e => {
    setState({
      ...state,
      displayColorPicker: !state.displayColorPicker,
      x: e.pageX
    });
  };

  // const handleClose = () => {
  //   setState({ ...state, displayColorPicker: false });
  // };

  const handleChange = color => {
    setState({ ...state, color: color.rgb, displayColorPicker: false });
    props.onChange(color.hex);
  };

  React.useEffect(() => {
    if (state.color) {
      setColorRGB(`rgb(${state.color.r}, ${state.color.g}, ${state.color.b})`);
    } else {
      setColorRGB('rgb(0,0,0)');
    }
  }, [state]);

  return (
    <Popover>
      <PopoverTrigger>
        <Button size="sm" isDisabled={props.disabled} onClick={handleClick}>
          Color ⏷
          <Divider marginX={2} orientation={'vertical'} />
          <Box width={4} height={4} background={colorRGB} />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <CompactPicker
            color={state.color}
            onChange={handleChange}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
export default ColorPickerFunc;

