import React from 'react';

class MarkerInfo extends React.Component {

  render() {
    const marker = this.props.marker;
    return (
      <div className="info-window-container">
        {marker.externalRef && (
          <div className="info-window-detail">
            <span className="info-window-label">Hydrant: </span>
            <span>{marker.externalRef}</span>
          </div>
        )}
        {marker.flow && (
          <div className="info-window-detail">
            <span className="info-window-label">Flow Rate: </span>
            <span>{marker.flow}</span>
          </div>
        )}
        {marker.streetAddress && (
          <div className="info-window-detail">
            <span className="info-window-label">Address: </span>
            <span>{marker.streetAddress}</span>
          </div>
        )}
        {marker.notes && (
          <div className="info-window-detail">
            <span className="info-window-label">Notes: </span>
            <span>{marker.notes}</span>
          </div>
        )}
        {marker.hydrantId && (
          <div className="info-window-detail">
            <span className="info-window-label">External ID: </span>
            <span>{marker.hydrantId}</span>
          </div>
        )}
        {!(marker.externalRef || marker.streetAddress || marker.flow || marker.notes) && (
          <div className="info-window-detail">
            <span>No hydrant data provided</span>
          </div>
        )}
      </div>
    );
  }

}

export default MarkerInfo;
