import React from 'react';
import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { createAssignment } from '../../features/assignments/assignmentsSlice';
import { assignmentFieldValues } from '../../models/Assignment';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import FormComponent from '../form/FormComponent';
import { SubmitButton } from '../form/Button';


export const AssignmentStatusPopover = (props) => {
  const methods = useForm();
  const toast = useToast();
  const dispatch = useDispatch();

  const { onOpen, onClose, isOpen } = useDisclosure();
  const onSubmit = async(data) => {
    // const editableFields = ['firstName', 'lastName'];
    // // @todo refactor form structure on profile
    // Object.keys(values).filter(k => editableFields.includes(k)).forEach(k => data.push({
    //   op: 'replace',
    //   path: `/${k}`,
    //   value: values[k]
    // }));
    const resultAction = await dispatch(createAssignment({
      customerId: props.assignment.customerId,
      locationId: props.assignment.locationId,
      assignedTo: props.assignment.assignedTo,
      assignmentDueOn: props.assignment.assignmentDueOn,
      assignmentStatus: data.assignmentStatus,
    }));

    if (createAssignment.fulfilled.match(resultAction)) {
      toast({
        title: 'Success',
        position: 'top',
        description: 'Assignment updated',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
      onClose();
    } else {
      // @todo Validation errors
      toast({
        title: 'Error',
        position: 'top',
        description: 'Failed to update assignment. Please try again.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  };

  const formData = {
    assignmentStatus: props.assignment.assignmentStatus,
  };

  const formFields = [
    {
      id: 'assignmentStatus',
      name: 'assignmentStatus',
      type: 'select',
      label: 'Assignment Status',
      options: Object.keys(assignmentFieldValues).map(key => ({
        label: assignmentFieldValues[key],
        value: key
      }))

    }
  ];

  return props.canEdit ? (
    <Popover
      onOpen={onOpen}
      onClose={onClose}
      isOpen={isOpen}
      placement="left"
    >
      <PopoverTrigger>
        {props.children}
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Box p={2}>
                  <FormComponent formFields={formFields} data={formData} />
                  <SubmitButton width="100%" isLoading={methods.formState.isSubmitting} mt={4} colorScheme="blue" type="submit">
                    Update Assignment
                  </SubmitButton>
                </Box>
              </form>
            </FormProvider>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>)
    : props.children;
};
