import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { push } from 'connected-react-router';
import { logoutUser as logoutUserAction } from '../actions/session-actions';
import { setCustomerLogOut } from '../actions/customer-actions';
import * as customerAPI from '../api/CustomerAPI';
import * as dispatchAPI from '../api/DispatchAPI';
import { Center, Container, Flex, Spinner, useColorModeValue } from '@chakra-ui/react';
import { Sidebar } from '../components/dashboard/Sidebar';
import { HeaderContainer } from './HeaderContainer';
import ErrorBoundary from './ErrorBoundary';
import { useJsApiLoader } from '@react-google-maps/api';
import { GMAPS_API_KEY, GMAPS_API_LIBRARIES } from '../helpers/GoogleMaps';
import { fetchUser } from '../features/user/userSlice';
import { fetchAllAssignments } from '../features/assignments/assignmentsSlice';
import {useLocation, useNavigate} from "react-router-dom";


export const NavContext = React.createContext({
  isOpen: false,
  toggleMenu: () => { },
  openMenu: () => { },
  closeMenu: () => { }
});

export const MainContainer = (props) => {
  // const [state, setState] = useState({
  //   showModal: false,
  //   modal: { heading: null, body: null },
  //   msg: null
  // });
  const [navOpen, setNavOpen] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const customer = useSelector(v => v.customer);
  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    if (props.isLoaded && customer.customerId && !userLoaded) {
      dispatchAPI.dispatchEventSource(dispatch, customer.customerId);
      setUserLoaded(true);
    }
  }, [customer, props.isLoaded, dispatch, userLoaded]);

  useEffect(() => {
    if (props.isLoaded) {
      if (!customer.customerid) {
        customerAPI.getCustomerV2(dispatch).then(newCustomer => {
          return Promise.all([
            customerAPI.getCustomerUsersV2(dispatch, newCustomer && newCustomer.links.find(x => x.rel === 'users')),
            customerAPI.getCustomerPartnersV2(dispatch, newCustomer && newCustomer.links.find(x => x.rel === 'partners')),
            dispatch(fetchUser()),
            dispatch(fetchAllAssignments())
          ]);
        }).catch(err => {
          console.error('Error on customerAPI.getCustomerV2()', err);
        });
      }
    }
  }, [props.isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [props.isLoaded, dispatch, customer.customerid]);

  // const logout = (e) => {
  //   e.preventDefault();
  //   setState({
  //     ...state,
  //     showModal: true,
  //     modal: {
  //       heading: 'Logout',
  //       body: <ConfirmForm
  //         body="Are you sure you want to logout?"
  //         onConfirm={logoutUser}
  //         onDecline={toggleModal}
  //       />
  //     }
  //   });
  // };

  const logoutUser = () => {
    dispatch(logoutUserAction());
    dispatch(setCustomerLogOut());
    navigate('/login');


    window.location.reload();
  };

  // const toggleModal = () => {
  //   setState({ ...state, showModal: !state.showModal });
  // };

  const navContextValue = {
    isOpen: navOpen,
    toggleMenu: () => setNavOpen(!navOpen),
    openMenu: () => setNavOpen(true),
    closeMenu: () => setNavOpen(false)
  };
  return (
    <NavContext.Provider value={navContextValue}>
      <Flex w="100%" direction="column" bg={useColorModeValue('gray.50', 'gray.900')}>
        <Container maxW="1920px" boxShadow="lg" bg={useColorModeValue('white', 'gray.900')} padding="0">
          <ErrorBoundary>

            {userLoaded ? (
              <React.Fragment>
                <HeaderContainer />
                <Flex w="100%">
                  <Sidebar logout={logoutUser} />
                  {props.isLoaded ? (
                    props.children
                  ) : (
                    <Center margin="0 auto">
                      <Spinner
                        thickness=".25rem"
                        speed=".5s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                      />
                    </Center>
                  )}
                </Flex>
              </React.Fragment>
            ) : <Center height="100vh" margin="auto auto">
              <Spinner
                thickness=".25rem"
                speed=".5s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>}
          </ErrorBoundary>
        </Container>
      </Flex>
    </NavContext.Provider>
  );
};

// We need Google Maps to be fully loaded before we can render the app.
const MapLoader = (props) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GMAPS_API_KEY,
    libraries: GMAPS_API_LIBRARIES
  });
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <MainContainer isLoaded={isLoaded} loadError={loadError} navigate={navigate} location={location} {...props}>
      {props.children}
    </MainContainer>
  );

};

export default MapLoader;
