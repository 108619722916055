import {
  parse,
  format,
  isBefore,
  subDays,
  isAfter,
  parseISO
} from 'date-fns';

const tz = require('tzdata-northamerica');


// Location data returns w/ format of "MM-dd-yyyy HH.mm.ss
export const formatLocationDate = (stringDate) => parse(stringDate, 'MM-dd-yyyy HH.mm.ss', new Date());

export const formatMonthYear = (date) => format(date, 'MMM yyyy');

export const formatDate = (date) => format(date, 'MM/dd/yyyy');
export const formatDateFromISO = (dateISO) => format(parseISO(dateISO), 'MM/dd/yyyy');

export const formatDateTime = (date) => format(date, 'MM/dd/yyyy HH:mm');

// Creates a label/value list for select dropdown fields
export const usTimeZones = () => {
  const timeZones = Object.keys(tz.zones).filter(name => (name.includes('America') || name.includes('Pacific')) && !name.includes('America/Argentina/'));
  const timeZonesList = [];
  timeZones.forEach((timeZone, i) => {
    timeZonesList.push({
      label: timeZone,
      value: timeZone
    });
  });
  return timeZonesList;
};

export const isBeforeSubDays = (date, days) => {
  return isBefore(date, subDays(new Date(), days));
};

export const isAfterSubDays = (date, days) => {
  return isAfter(date, subDays(new Date(), days));
};
