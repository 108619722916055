import { Text, Heading, Divider, Stack, Button, Flex } from '@chakra-ui/react';
import React from 'react';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error: error
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };
  }

  componentDidCatch(error, errorInfo) {    // You can also log the error to an error reporting service
    console.error('Error:', [error, errorInfo]);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flex direction="column" width="100%" alignItems="center" justifyContent="center">
          <Flex direction="column" shadow="lg" borderWidth="1px" borderRadius="0.75rem" p="1.5rem">
            <Stack spacing="0.5rem">
              <Heading as="h4" size="md">Error</Heading>
              <Text size="sm">An unexpected error occured. Please refresh your browser and try again.</Text>
              <Divider />
            </Stack>
            <Button
              alignSelf="center"
              marginTop="1.5rem"
              width="100%"
              bg={'blue.400'}
              color={'white'}
              _hover={{
                bg: 'blue.500',
              }}
              onClick={() => window.location.reload()}
            >
              Refresh
            </Button>
          </Flex>
        </Flex>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
