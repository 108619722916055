import React from 'react';
import Routes from '../routes';
import { ChakraProvider } from '@chakra-ui/react';
import flowTheme from '../styles/theme/default';
// import { BrowserRouter } from 'react-router-dom';
// import { FirebaseAnalytics } from '../analytics/FirebaseAnalytics';
const App = () =>
(
  <ChakraProvider theme={flowTheme}>
    {Routes}
  </ChakraProvider>
);

export default App;
