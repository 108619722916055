import { setSessionFromLocal } from '../api/SessionAPI';

class Authenticator {
  static loggedIn() {

    if (!sessionStorage.jwt && localStorage.jwt) {
      setSessionFromLocal();
    }

    return !!sessionStorage.jwt;
  }

  static logout() {
    localStorage.clear();
    sessionStorage.clear();
  }
}

export default Authenticator;
