import React, {useCallback, useEffect} from 'react';
import {Box, Divider, Heading, useColorModeValue, Flex, HStack, useToast, Switch} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { DataTable } from '../dashboard/DataTable';
import { useDispatch } from 'react-redux';
import {fetchDispatchUnits, setSelectedUnits} from '../../features/dispatch/dispatchSlice';
import {OutlineButton, PrimaryButton} from '../form/Button';
import {FaCheckDouble} from '@react-icons/all-files/fa/FaCheckDouble';
import {FaTimes} from '@react-icons/all-files/fa/FaTimes';

export const UnitsCard = (props) => {
  const {units, unitsLastFetch, unitsLastUpdated } = useSelector(state => state.dispatch);
  // const [ isUpdating, /*setIsUpdating*/ ] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();


    useEffect(() => {
        if (!unitsLastFetch) {
          dispatch(fetchDispatchUnits());
        }
      // }, [units]);
      }, [units, dispatch, unitsLastFetch]);

    const onEnableAll = useCallback((e) => {

        // Get all the unit ids and submit
        const unitIds = units.map(unit => unit.id);
        dispatch(setSelectedUnits(unitIds));
        // setDispatchUnits(unitIds)
        //     .then(res => {
        //         console.log('api call result', res);
        //     }, err => {
        //         console.error('api call failed', err);
        //     })
        // ;

    // }, [isUpdating, units]);
    }, [units, dispatch]);

    const onDisableAll = useCallback((e) => {

        // Get all the unit ids and submit
        dispatch(setSelectedUnits([]));
        // setDispatchUnits([])
        //     .then(res => {
        //         console.log('api call result', res);
        //     }, err => {
        //         console.error('api call failed', err);
        //     })
        // ;

    // }, [isUpdating]);
    }, [dispatch]);

    const onToggleUnit = useCallback((unitId, enabled) => {

        // Get all currently selected units
        const unitIdsSet = new Set(units
            .filter(unit => unit.selected)
            .map(unit => unit.id)
        );

        if (enabled) {
            unitIdsSet.add(unitId);
        } else {
            unitIdsSet.delete(unitId);
        }

        dispatch(setSelectedUnits(Array.from(unitIdsSet)));

    // }, [isUpdating, units]);
    }, [units, dispatch]);

    useEffect(() => {
        if (unitsLastUpdated) {
            toast({
                title: 'Success',
                position: 'top',
                description: 'Unit selection updated.',
                status: 'success',
                duration: 2500,
                isClosable: true
            });
        }
    // }, [unitsLastUpdated]);
    }, [unitsLastUpdated, toast]);


  const unitColumns = [
    {
      Header: 'Unit',
      accessor: 'unit',
    },
    {
      Header: 'Selected',
      accessor: 'selected',
      maxWidth: 100,
      Cell: ({ value, row }) => {
        return (
            // FaRegEdit, FaRegTrashAlt, FaRedoAlt
            <HStack justifyContent="start" width="100%">
              <Switch isChecked={row.original.selected} size="md" onChange={e => {
                  onToggleUnit(row.original.id, e.target.checked);
              }} />
              {/*<ResetUserPassword user={row.original} />*/}
            </HStack>
        );
      }
    }
  ];

  return (
      <Box marginTop="2rem" maxWidth="32rem"  bg={useColorModeValue('white', 'gray.900')} p="1rem" borderWidth="1px" borderRadius="0.5rem" boxShadow="md">
        <Flex px="1rem" align="center" justify="space-between">
          <Heading as="h4" size="md" style={{flexGrow: 1}}>Units</Heading>
          <PrimaryButton aria-label="Select All Units" leftIcon={<FaCheckDouble />} onClick={onEnableAll}>Select All</PrimaryButton>
          <OutlineButton aria-label="Deselect All Units" leftIcon={<FaTimes />} style={{marginLeft: '0.5rem'}} onClick={onDisableAll}>Select None</OutlineButton>
        </Flex>
        <Divider my="0.5rem" />
        <DataTable globalSearch columns={unitColumns} data={units} />
      </Box>
  );
};
