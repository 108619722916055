/* global google */

import React from 'react';

import {
  chakra,
  Button,
  Text,
  HStack,
  Heading,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useTab,
  // useStyles,
  Badge,
  useColorModeValue,
  Flex,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem, useStyleConfig
} from '@chakra-ui/react';
import { FaDrawPolygon } from "@react-icons/all-files/fa/FaDrawPolygon";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { FaTrash } from "@react-icons/all-files/fa/FaTrash";
import { userRoleSelector } from '../features/user/userSlice';


import { NavLink as RouterLink } from 'react-router-dom';
import LocationData from '../components/common/LocationDataComponent';
import BuildingData from '../components/common/BuildingDataComponent';
import { FlowDataAndHydrantsInfo } from '../components/common/FlowDataComponent';
import { useFormContext } from 'react-hook-form';
import { OutlineButton, SubmitButton } from '../components/form/Button';
import { DispatchTab } from '../components/dispatch/DispatchTab';
import { AssignmentCard } from '../components/assignments/AssignmentCard';
import { FaFilePdf } from '@react-icons/all-files/fa/FaFilePdf';
import { useSelector } from 'react-redux';


export const tabKeys = ['location', 'building', 'firstIn', 'command'];
export const TabsContainer = ({
  currentTab,
  handleTabSelect,
  selectedLocation, //this.state.selectedLocation
  locationDataProps,
  selectALocation,
  handleContextMenuItemSelect,
  ...props }) => {


  const tabChange = (index) => {
    handleTabSelect(tabKeys[index]);
  };


  const selectDispatch = (message) => {

    // @todo we should really create a geocoder hook
    // @todo we should provide feedback on error.
    // This checks if the message included a latLng
    // If not, we geocode the address
    if (!message.latLon && message.address) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'address': message.address }, (results, status) => {
        if (status === 'OK') {
          selectALocation({
            ...message,
            latLon: {
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng()
            }
          });
        }
      });
    } else {
      selectALocation(message);
    }
  };

  return (
    <Box bg={useColorModeValue('white', 'gray.900')}>
      <DispatchTab isPrePlanningMode={locationDataProps.isPrePlanningMode} selectDispatch={selectDispatch} />
      <Box>
        <Accordion defaultIndex={[0, 1]} allowMultiple>
          <AccordionItem borderTopWidth="0">
            <AccordionButton>
              <Heading textAlign="left" flex="1" as="h5" size="md">Pre-Plan</Heading>
              {locationDataProps.isPrePlanningMode && <Box paddingBottom="0.25rem">
                <Badge colorScheme="green">Editing</Badge>
              </Box>}
              {!locationDataProps.isPrePlanningMode && locationDataProps.location && !locationDataProps.location.isMine && <Box paddingBottom="0.25rem">
                <Badge colorScheme="blue">Partner Data</Badge>
              </Box>}
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <PrePlanTab
                currentTab={currentTab}
                tabChange={tabChange}
                locationDataProps={locationDataProps}
                selectedLocation={selectedLocation}
                handleContextMenuItemSelect={handleContextMenuItemSelect}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Heading textAlign="left" flex="1" as="h5" size="md">Hydrants</Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <FlowDataAndHydrantsInfo />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  );
};

/**
 * const locationDataProps = {
      customer: this.props.customer,
      location: this.state.selectedLocation,
      locationDataForm: this.state.locationDataForm,
      buildingDataForm: this.state.buildingDataForm,
      isPrePlanningMode: this.state.isPrePlanningMode,
      isPolygonDrawn: this.state.isPolygonDrawn,
      isGettingPreplan: this.state.isGettingPreplan,
      onCancelPrePlan: this.handleCancelPrePlanLocation,
      onPrePlanFormSubmit: this.handlePrePlanFormSubmit,
      onBuildingFormSubmit: this.handleBuildingFormSubmit,
      onPrePlanFormChange: this.handlePrePlanFormChange,
      onBuildingFormChange: this.handleBuildingFormChange,
      onRePlanSubmit: this.handleRePlanSubmit,
      onRePlanStart: this.handleRePlanStart,
      reDrawPolygon: this.state.reDrawPolygon
    };
 */
const PrePlanTab = (props) => {
  const methods = useFormContext();
  const userRole = useSelector(userRoleSelector);

  // Custom tab component for styling current selected tab

  const StyledTab = chakra('button', { themeKey: 'Tabs.Tab' });
  // const StyledTab = <Button themeKey="Tabs.Tab" />;

  const TabHeader = React.forwardRef((props, ref) => {
    const tabProps = useTab({ ...props, ref });
    // const styles = useStyles();
    const styles = useStyleConfig('Tabs')
    const isSelected = !!tabProps['aria-selected'];
    return (
      <StyledTab __css={styles.tab} {...tabProps}>
        <Heading fontWeight={isSelected ? '600' : 'normal'} textAlign="left" as="h5" size="sm">{props.children}</Heading>
      </StyledTab>
    );
  });

  return (
    <Box>
      <Box p="0.5rem" borderWidth="1px" borderRadius="0.5rem" boxShadow="sm">
        <Tabs
          index={tabKeys.indexOf(props.currentTab)}
          onChange={props.tabChange}
        >
          <TabList flexWrap="wrap" borderBottomWidth="2px">
            <TabHeader>Location</TabHeader>
            <TabHeader>Building</TabHeader>
            {props.selectedLocation && <TabHeader>First In</TabHeader>}
            {props.selectedLocation && <TabHeader>Command</TabHeader>}
          </TabList>
          <TabPanels>
            <TabPanel>
              <LocationData {...props.locationDataProps} />
            </TabPanel>
            <TabPanel>
              <BuildingData {...props.locationDataProps} />
            </TabPanel>
            {props.selectedLocation && <TabPanel>
              <FIOInfo
                selectedLocation={props.selectedLocation}
              />
            </TabPanel>
            }
            {props.selectedLocation && <TabPanel>
              <CommandInfo />
            </TabPanel>
            }
          </TabPanels>
        </Tabs>
        {props.locationDataProps.isPrePlanningMode ? (
          <React.Fragment>
            <Divider />
            <Flex paddingTop="0.5rem" alignItems="center" justifyContent="space-between" width="100%">
              {props.locationDataProps.isPolygonDrawn && <SubmitButton width="100%" mr="1rem" isLoading={methods.formState.isSubmitting}>Save</SubmitButton>}
              <OutlineButton width="100%" onClick={props.locationDataProps.onCancelPrePlan}>Cancel</OutlineButton>
            </Flex>
          </React.Fragment>
        ) : props.selectedLocation && (
          <React.Fragment>
            <Divider />
            <Flex paddingTop="0.5rem" paddingX="1rem" alignItems="center" justifyContent="space-between" width="100%">
              {(userRole === "ADMIN" || userRole === "PLANNER") &&
                <Menu>
                  <MenuButton
                    variant="outline"
                    fontSize="sm"
                    color="blue.400"
                    as={Button}
                    rightIcon={<FaChevronDown />}
                  >
                    Update Pre-Plan
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      key={"menu-edit"}
                      icon={<FaEdit />}
                      onClick={() => props.handleContextMenuItemSelect(
                        {
                          value: "EDIT_LOCATION"
                        },
                        {
                          location: props.selectedLocation
                        }
                      )}
                    >
                      Edit Location
                    </MenuItem>
                    <MenuItem
                      key={"menu-redraw"}
                      icon={<FaDrawPolygon />}
                      onClick={() => props.handleContextMenuItemSelect(
                        {
                          value: "REPLAN_LOCATION"
                        },
                        {
                          location: props.selectedLocation
                        }
                      )}
                    >
                      Re-Plan Location
                    </MenuItem>
                    <MenuItem
                      key={"menu-delete"}
                      icon={<FaTrash />}
                      onClick={() => props.handleContextMenuItemSelect(
                        {
                          value: "DELETE_LOCATION"
                        },
                        {
                          location: props.selectedLocation
                        }
                      )}
                    >
                      Delete Location
                    </MenuItem>
                  </MenuList>
                </Menu>
              }
              <OutlineButton
                fontSize="sm"
                color={'blue.400'}
                as={RouterLink}
                end
                to={`/export/${props.locationDataProps.location.id}`}
                leftIcon={<FaFilePdf />}
              >
                Export to PDF
              </OutlineButton>
            </Flex>
          </React.Fragment>
        )}
      </Box>
      <AssignmentCard />
    </Box >
  );
};

const FIOInfo = ({
  selectedLocation,
  ...props }) => (
  <Stack spacing="2rem" padding="0.5rem">
    <HStack paddingX="0.5rem">
      <Heading as="h5" size="sm">
        Roof Type:
      </Heading>
      {
        selectedLocation && selectedLocation.building && selectedLocation.building.roofType && <Text fontSize="md">
          {selectedLocation.building.roofType}
        </Text>
      }
      {
        selectedLocation && selectedLocation.building && !selectedLocation.building.roofType && <Text fontSize="md">
          {'Not defined'}
        </Text>
      }
    </HStack>
  </Stack>
);

const CommandInfo = () => (
  <div>
    <div className="command-container section-container">
      <div className="section-content">
        {''}
      </div>
    </div>
  </div>
);
