import { Box, Text, HStack, Switch, useColorModeValue } from '@chakra-ui/react';
// import { setCustomerConfig } from '../../actions/customer-actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserConfig } from '../../features/user/userSlice';


export const MapSettings = (/*props*/) => {
  const dispatch = useDispatch();
  // const config = useSelector(state => state.customer.config);
  const uiConfig = useSelector(state => state.user.uiConfig);

  const onDisableDblClickChange = (e) => {
    dispatch(updateUserConfig({disableDblClick: e.target.checked ? 'true' : 'false'}));
    // dispatch(setCustomerConfig({disableDblClick: e.target.checked}));
  };

  return (
    <Box bg={useColorModeValue('white', 'gray.900')}>
      {uiConfig ? (
      <HStack pt="0.25rem" spacing="1rem" display="flex" alignItems="center">
        <Switch h="1.5rem" isChecked={uiConfig && uiConfig.disableDblClick === 'true'} onChange={onDisableDblClickChange}/>
        <Text pb="0.25rem" size="sm">Double-Tap for Right-Click</Text>
      </HStack>
      ) : <span>Loading User Settings...</span>}
    </Box>
  );
};
