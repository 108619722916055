import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import { Heading, Box, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, useColorModeValue, Flex, useAccordion, Tag, HStack, TagLabel, TagRightIcon, Button } from '@chakra-ui/react';
import { DispatchMessage, SMSListInfo } from '../common/SMSListComponent';
import {
  fetchDispatchMessages,
  selectAllDispatches
} from '../../features/dispatch/dispatchSlice';
import { VscRadioTower } from '@react-icons/all-files/vsc/VscRadioTower';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import {FaFilter} from '@react-icons/all-files/fa/FaFilter';
import {updateUserConfig} from '../../features/user/userSlice';


export const DispatchTab = (props) => {
  const [ { lastFetch, /*units, selectedDispatchId, error, currentRequestId, loading*/ }, { enableUnitFilter } ] = useSelector(state => [ state.dispatch, state.user.uiConfig ], shallowEqual);
  const dispatchMessages = useSelector(selectAllDispatches);
  const [newMessages, setNewMessages] = useState(false);
  const accordion = useAccordion({
    allowMultiple: true
  });
  const dispatch = useDispatch();

    // Handle IncidentId Parameter
  // const { search } = useLocation();
  // const searchParams = React.useMemo(() => new URLSearchParams(search), [search]);

  // useEffect(() => {
  //   console.log('DispatchTab incidentId', searchParams);


  // }, [searchParams]);

  useEffect(() => {
    if (!lastFetch) {
      dispatch(fetchDispatchMessages());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // }, );

  useEffect(() => {
    if (dispatchMessages.length > 0) {
      setNewMessages(true);
    }
  }, [dispatchMessages]);

  useEffect(() => {
    if (props.isPrePlanningMode) {
      setSelectedMessage(null);
      accordion.setIndex(-1);
    }
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [props, accordion]);

  const [selectedMessage, setSelectedMessage] = useState(null);
  const handleSelectMessage = (message) => {
    // If a preplan exists for location, collapse the accordion
    // @todo there might be an instance where a preplan exists, but a locationID was not provided by API
    try {

      if (message.locationID) {
        accordion.setIndex(-1);
      }
      setSelectedMessage(message);
      props.selectDispatch(message);
    } catch (e) {
      console.error('Error selecting dispatch', e); // eslint-disable-line no-console
    }
  };

  // Handle filter toggle click
  const toggleDispatchFilter = useCallback((e) => {
    e.stopPropagation();
    dispatch(updateUserConfig({enableUnitFilter: (enableUnitFilter !== 'true') ? 'true' : 'false'}));

  }, [enableUnitFilter]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [enableUnitFilter, dispatch]);

  const planAvailStyle = {
    margin: '-0.8rem',
    marginBottom: '0.5rem',
    bg: 'gray.600',
    padding: '0.25rem 0.75rem',
    borderTopRadius: '0.5rem',
  };

  const accordionBackground = useColorModeValue('white', 'gray.900');

  const { index, descendants } = accordion;
  return (
    <Box position="relative">
      <Accordion reduceMotion borderTopWidth="0" index={index} descendants={descendants}>
        <AccordionItem isDisabled={!(dispatchMessages && dispatchMessages.length > 0)} >
          {({ isExpanded }) => (<React.Fragment>
            <AccordionButton onClick={() => {
              accordion.setIndex(isExpanded ? -1 : 0);
              setNewMessages(false);
            }}>
              <Flex direction="column" width="100%">
                <Flex justifyContent="space-between" alignItems="center">
                  <Heading textAlign="left" flex="1" as="h5" size="md">Dispatch</Heading>
                  <HStack spacing={2}>
                    {newMessages && <Tag colorScheme="red" size="sm">
                      <TagLabel>NEW</TagLabel>
                      <TagRightIcon as={VscRadioTower} />
                    </Tag>}
                    <Button
                        variant="ghost"
                        as={'div'}
                        color={enableUnitFilter === 'true' ? 'green.400' : 'gray.400'}
                        onClick={toggleDispatchFilter}
                    ><FaFilter />&nbsp;{enableUnitFilter ? 'My Calls' : 'All Calls'}</Button>
                    {/*<GhostButton color={enableUnitFilter === 'true' ? 'green.400' : 'gray.400'} onClick={toggleDispatchFilter}><FaFilter />&nbsp;{enableUnitFilter ? 'My Calls' : 'All Calls'}</GhostButton>*/}
                    <AccordionIcon />
                  </HStack>
                </Flex>
              </Flex>
            </AccordionButton>
            {(selectedMessage && !isExpanded) &&
              <Box
                boxShadow="md"
                borderWidth="1px"
                borderRadius="0.5rem"
                padding="0.75rem"
                bg="white"
                textAlign="left"
                mx={4}
                mb={4}
                mt={2}>
                <Box {...planAvailStyle}>
                  <Flex justifyContent="space-between">
                    <Button p={0} display="flex" justifyContent="space-between" width="100%" variant="text" textTransform="uppercase" fontWeight="600" size="xs" rightIcon={<FaTimes />} color={'white'} onClick={() => setSelectedMessage(null)}>
                      Selected Dispatch
                    </Button>
                  </Flex>
                </Box>
                <DispatchMessage {...selectedMessage} />
              </Box>
            }
            <AccordionPanel width="100%" position="absolute" zIndex="1" height={'calc(100vh - 7rem)'} background={accordionBackground} overflow="auto">
              <Box background={accordionBackground}>
                <SMSListInfo
                  selectALocation={handleSelectMessage}
                />
              </Box>
            </AccordionPanel>
          </React.Fragment>)}

        </AccordionItem>
      </Accordion>
    </Box>
  );
};
