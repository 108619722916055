import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import * as assignmentAPI from '../../api/AssignmentAPI';

// Setting up according to redux-toolkit best practices
// Reference: https://redux-toolkit.js.org/usage/usage-guide


// Thunks
export const fetchAllAssignments = createAsyncThunk(
  'assignments/fetchAllAssignments',
  async(data, thunkAPI) => {
    const { customer } = thunkAPI.getState();
    const slug = customer && customer.slug;
    const customerId = customer && customer.customerId;

    const response = await assignmentAPI.fetchAllAssignments(slug, customerId);
    return response.data;
  }
);

export const createAssignments = createAsyncThunk(
  'assignments/createAssignments',
  async(assignments, thunkAPI) => {
    const { customer } = thunkAPI.getState();
    const slug = customer && customer.slug;

    const response = await assignmentAPI.createAssignments(assignments, slug);
    return response.data;
  }
);

export const clearAssignments = createAsyncThunk(
  'assignments/clearAssignments',
  async(assignments, thunkAPI) => {
    const { customer } = thunkAPI.getState();
    const slug = customer && customer.slug;

    const response = await assignmentAPI.createAssignments(assignments, slug);
    return response.data;
  }
);


export const createAssignment = createAsyncThunk(
  'assignments/createAssignment',
  async(assignment, thunkAPI) => {
    const { customer } = thunkAPI.getState();
    const slug = customer && customer.slug;

    const response = await assignmentAPI.createAssignment(assignment, slug);
    return response.data;
  }
);
// export const editAssignment = createAsyncThunk(
//   'assignments/editAssignment',
//   async(assignment, thunkAPI) => {
//     const response = await assignmentAPI.editAssignment(assignment.id, assignment.data);
//     return response.data;
//   }
// );
// export const deleteAssignment = createAsyncThunk(
//   'assignments/deleteAssignment',
//   async(assignmentId, thunkAPI) => {
//     return await assignmentAPI.deleteAssignment(assignmentId).then(res => {
//       if (res.data && res.data.success) {
//         return { ...res.data, id: assignmentId };
//       }
//       return thunkAPI.rejectWithValue(res.data);
//     });

//   }
// );

export const assignmentsAdapter = createEntityAdapter();
const initialState = assignmentsAdapter.getInitialState({
  loading: 'idle',
  updatingAction: null,
  currentRequestId: undefined,
  error: null,
  lastFetch: null
});

export const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState,
  reducers: {
    assignmentsLoading: (state, /*action*/) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    assignmentsReceived: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.entities = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    // @todo consider using entityAdapter
    // Add reducers for additional action types here, and handle loading state as needed
    // fetchAllAssignments
    builder
      .addCase(fetchAllAssignments.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchAllAssignments.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.lastFetch = Date.now();
        if (action.payload.data) {
          assignmentsAdapter.upsertMany(state, action.payload.data);
        }
      })
      .addCase(fetchAllAssignments.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.error;
          state.lastFetch = Date.now();
          state.currentRequestId = undefined;
        }
      });

    // createAssignments
    builder
      .addCase(createAssignments.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.updatingAction = 'update';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(createAssignments.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.currentRequestId = undefined;
          assignmentsAdapter.addMany(state, action.payload.data);
        }
      })
      .addCase(createAssignments.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.error;

          state.currentRequestId = undefined;
        }
      });

    // clearAssignments
    builder
      .addCase(clearAssignments.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.updatingAction = 'clear';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(clearAssignments.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.currentRequestId = undefined;
          assignmentsAdapter.addMany(state, action.payload.data);
        }
      })
      .addCase(clearAssignments.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.error;

          state.currentRequestId = undefined;
        }
      });

      builder
      .addCase(createAssignment.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(createAssignment.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.currentRequestId = undefined;
          assignmentsAdapter.setOne(state, action.payload);
        }
      })
      .addCase(createAssignment.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.error;

          state.currentRequestId = undefined;
        }
      });
  }
});

const { /*actions,*/ reducer } = assignmentsSlice;

export default reducer;


export const {
  selectById: selectAssignmentById,
  selectAll: selectAllAssignments,
  selectTotal: selectTotalAssignments,
  selectIds: selectAssignmentIds
} = assignmentsAdapter.getSelectors(state => state.assignments);
