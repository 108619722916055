import React, { useEffect, useState } from 'react';
import { Input as ChakraInput, Textarea as ChakraTextarea, RadioGroup as ChakraRadioGroup, Checkbox as ChakraCheckbox, Select as ChakraSelect, Text, FormControl, FormLabel, FormErrorMessage, Stack, Radio, Button, Heading, HStack, VisuallyHiddenInput, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Box } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';

export const InputWithLabel = ({
  labelProps,
  ...props }) => {
  const methods = useFormContext();

  return (
    <FormControl id={props.id} isInvalid={methods.formState.errors[props.id]}>
      {props.label ? (
        <FormLabel fontSize={props.size} {...labelProps}>{props.label}</FormLabel>
      ) : <React.Fragment />}
      <ChakraInput
        size={props.size}
        id={props.id}
        type={props.type}
        placeholder={props.placeholder ? props.placeholder : ''}
        {...methods.register(props.id, {
          required: props.required,
        })}
        {...props}
      />
      {methods.formState.errors[props.id] && (
        <FormErrorMessage>{methods.formState.errors[props.id].message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export const Input = ({
  labelProps,
  ...props }) => {
  const methods = useFormContext();

  return (
    <FormControl id={props.id} isInvalid={methods.formState.errors[props.id]}>
      <ChakraInput
        size={props.size}
        id={props.id}
        type={props.type}
        placeholder={props.placeholder ? props.placeholder : ''}
        {...methods.register(props.id, {
          required: props.required
        })}
        {...props}
      />
      {props.description && (<Text fontSize="xs" fontStyle="italic">{props.description}</Text>)}
      {methods.formState.errors[props.id] && (
        <FormErrorMessage>{methods.formState.errors[props.id].message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export const InputHidden = ({
  labelProps,
  ...props }) => {
  const methods = useFormContext();

  return (
    <FormControl id={props.id} isRequired={props.required} isInvalid={methods.formState.errors[props.id]}>
      <VisuallyHiddenInput />
    </FormControl>
  );
};

export const TextArea = ({
  labelProps,
  ...props }) => {
  const methods = useFormContext();

  return (
    <FormControl id={props.id} isInvalid={methods.formState.errors[props.id]}>
      <ChakraTextarea
        size={props.size}
        id={props.id}
        type={props.type}
        placeholder={props.placeholder ? props.placeholder : ''}
        {...methods.register(props.id, {
          required: props.required,
        })}
      />
      {methods.formState.errors[props.id] && (
        <FormErrorMessage>{methods.formState.errors[props.id].message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export const Checkbox = ({
  labelProps,
  ...props }) => {
  const methods = useFormContext();

  return (<Controller
    control={methods.control}
    rules={{
      required: props.required
    }}
    name={props.name}
    render={({
      field,
      fieldState
    }) => (<FormControl isInvalid={fieldState.invalid}>
      <ChakraCheckbox
        size={props.size ? props.size : 'md'}
        onBlur={field.onBlur}
        onChange={field.onChange}
        isChecked={field.value}
      >
        <Text fontSize={props.size ? props.size : 'md'}>
          {props.description ? props.description : props.label}
        </Text>
      </ChakraCheckbox>
    </FormControl>
    )}
  />);
};


export const Select = ({
  labelProps,
  ...props }) => {
  const methods = useFormContext();

  return (
    <FormControl>
      <ChakraSelect
        id={props.id}
        placeholder={props.placeholder}
        {...methods.register(props.name, {
          required: props.required,
        })}
      >
        {props.options.map((option) => (
          <option key={`${props.name}-${option.value}`} value={option.value}>{option.label}</option>
        ))}
      </ChakraSelect>
    </FormControl>
  );
};


export const RadioGroup = ({
  labelProps,
    defaultValue=null,
  ...props }) => {
  const methods = useFormContext();

  return (
    <FormControl>
      <Controller
        name={props.name}
        control={methods.control}
        defaultValue={defaultValue}
        rules={{
          required: props.required
        }}
        render={({
          field,
          // fieldState
        }) => (
          <ChakraRadioGroup value={field.value} name={field.name} onChange={field.onChange} onBlur={field.onBlur}>
            {props.groupName && (
              <HStack spacing="0.5rem" marginBottom="0.5rem" height="1.5rem">
                <Heading as="h5" size="sm">{props.groupName}</Heading>
                {field.value && (
                  <Button variant="text" size="xs" onClick={() => methods.resetField(field.name)}>
                    Reset
                  </Button>
                )}
              </HStack>
            )}
            <Stack direction={props.direction} spacing="2rem">
              {props.options.map((option) => {
                return (
                  <Radio isChecked={field.value && field.value === option.value} value={option.value} key={`${props.name}-${option.value}`}>
                    {option.label}
                  </Radio>
                );
              })
              }
            </Stack>
          </ChakraRadioGroup>
        )}
      />

    </FormControl>
  );
};

export const DatePicker = ({
  ...props }) => {
  const methods = useFormContext();

  return (
    <Controller
      name={props.name}
      control={methods.control}
      rules={{
        required: props.required
      }}
      render={({
        field,
        // fieldState,
        formState
      }) => (
        <FormControl isInvalid={formState.errors[props.id]}>
          <div className="light-theme">
            <ReactDatePicker
              ref={field.ref}
              selected={field.value}
              onChange={field.onChange}
              placeholderText="Select date"
              isClearable
              showPopperArrow={false}
              className="react-datapicker__input-text"
            />
          </div>
          {formState.errors[props.id] && (
            formState.errors[props.id].type === 'required') && (
              <FormErrorMessage>Date is required</FormErrorMessage>
            )}
        </FormControl>
      )}
    />
  );
};

export const DateRangePicker = (props) => {
  // const startingRange = [new Date('2015-01-01'), new Date()];
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    setStartDate(props.startDate && new Date(props.startDate));
  }, [props.startDate]);

  useEffect(() => {
    setEndDate(props.endDate && new Date(props.endDate));
  }, [props.endDate]);

  const onChange = (startDate, endDate) => {
    if (startDate && endDate) {
      props.onChange([startDate.getTime(), endDate.getTime()]);
    }
  };

  return (
    <div className="light-theme xs">
      <Stack width="12rem">
        <HStack justify="space-between">
          <ReactDatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              onChange(date, endDate);
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="--"
          />
          <Text fontSize="xs">to</Text>
          <ReactDatePicker
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
              onChange(startDate, date);
            }}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="--"
          />
        </HStack>
      </Stack>
    </div>
  );
};

export const ConditionalField = ({
  ...props }) => {
  const methods = useFormContext();


  const output = useWatch({
    name: props.conditionName,
    control: methods.control
  });

  return (<React.Fragment>
    {props.condition(output) && props.children}
  </React.Fragment>
  );


};

export const NumberSlider = ({
  ...props
}) => {
  const [range, setRange] = useState([props.min, props.max]);

  useEffect(() => {
    setRange(props.value);
  }, [props.value]);

  const onChangeEnd = (values) => {
    props.onChangeEnd(values);
  };

  return (
    <Stack spacing={0} alignItems="center">
      <Box px={2} width="100%">
        <RangeSlider aria-label={'min, max'} step={5} onChange={setRange} onChangeEnd={onChangeEnd} value={range}>
          <RangeSliderTrack>
            <RangeSliderFilledTrack />
          </RangeSliderTrack>

          <RangeSliderThumb borderWidth="1px" borderColor="gray.300" index={0} />
          <RangeSliderThumb borderWidth="1px" borderColor="gray.300" index={1} />
        </RangeSlider>
      </Box>
      <HStack justify="space-between" width="100%">
        <Text fontSize="sm" fontWeight={600}>{range[0]}{props.unit}</Text>
        <Text fontSize="sm" fontWeight={600}>{range[1]}{props.unit}</Text>
      </HStack>
    </Stack>

  );
};
