import * as z from 'zod';
import Building from './Building';

export const PreplanExport = z.object({
  id: z.string(),
  businessName: z.string().optional(),
  address1: z.string().optional(),
  address2: z.optional(z.string()),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
  lotNumber: z.string().optional(),
  customerZone: z.string().optional(),
  storey: z.number().optional(),
  storeyBelow: z.number().optional(),
  roofArea: z.number().optional(),
  hydrants: z.number().optional(),
  imageLength: z.number().optional(),
  completion: z.number().optional(),
  floorPlan: z.boolean().optional(),
  doNotShare: z.boolean().optional(),
  isHighHazard: z.boolean().optional(),
  highHazardDescription: z.string().optional(),
  riskAssessmentScore: z.string().optional(),
  reviewStatus: z.string().optional(),
  lastReviewedOn: z.string().optional(),
  lastReviewedBy: z.string().optional(),
  originalPrePlan: z.string().optional(),
  createdDate: z.string().optional(),
  createdBy: z.string().optional(),
  assignedBy: z.string().optional(),
  assignedTo: z.string().optional(),
  assignmentStatus: z.string().optional(),
  assignmentDueOn: z.string().optional(),
  imageAnnotations: z.number().optional(),
  building: Building.optional()
});
