/* global google */
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  setCustomerCurrentLocation,
  setCustomerDefaultState
} from '../actions/customer-actions';
import Map from '../components/map/MapComponent';
import ContextMenu from '../components/common/ContextMenuComponent';
import Modal from '../components/common/ModalComponent';
import HydrantDetailsForm from '../components/form/HydrantDetailsFormComponent';
import ConfirmForm from '../components/form/ConfirmFormComponent';
import DialogForm from '../components/form/DialogFormComponent';
import ImageStrip from '../components/imageStrip/ImageStrip';
import * as customerAPI from '../api/CustomerAPI';
import * as hydrantAPI from '../api/HydrantAPI';
import * as locationAPI from '../api/LocationAPI';
import * as UserAPI from '../api/UserAPI';
import AnnotatorContainer from '../containers/AnnotatorContainer';
import { MapFilters } from '../components/drawer/MapFilters';
import { TabsContainer } from './TabsContainer';
import Capture from 'html2canvas';
import { FaCamera } from '@react-icons/all-files/fa/FaCamera';
import { FaRuler } from '@react-icons/all-files/fa/FaRuler';

import { getDistance } from '../helpers/GoogleMaps';
import {
  Flex,
  Box,
  Button,
  FormControl,
  Tooltip,
  Switch,
  HStack,
  Icon,
  Spinner,
  Text,
  Stack,
  Center
} from '@chakra-ui/react';
import { PrePlanFormContainer } from './PrePlanFormContainer';
import { OutlineButton, PrimaryButton } from '../components/form/Button';
import { FlexWithBackgroundTheme } from './ThemeContainer';
import { deleteLocation, fetchAllLocations, fetchPartnerLocations, locationUpdated, selectAllLocations, selectLocationById, setLocationFilters, setSelectedLocationId } from '../features/locations/locationsSlice';
import { fetchAllHydrants, hydrantSelected, clearSelectedHydrants, setSelectedHydrants, selectAllHydrants, deleteHydrant, fetchPartnerHydrants } from '../features/hydrants/hydrantsSlice';

const mapMenuItems = [
  {
    value: 'PREPLAN_LOCATION',
    label: 'Pre-Plan Location'
  },
  {
    value: 'ADD_HYDRANT',
    label: 'Add Hydrant'
  }
];

const hydrantMenuItems = [
  {
    value: 'EDIT_HYDRANT',
    label: 'Edit Hydrant'
  },
  {
    value: 'MOVE_HYDRANT',
    label: 'Move Hydrant'
  },
  {
    value: 'DELETE_HYDRANT',
    label: 'Delete Hydrant'
  }
];

const locationMenuItems = [
  {
    value: 'EDIT_LOCATION',
    label: 'Edit Location'
  },
  {
    value: 'REPLAN_LOCATION',
    label: 'Re-Plan Location'
  },
  {
    value: 'MOVE_LOCATION',
    label: 'Move Location'
  },
  {
    value: 'DELETE_LOCATION',
    label: 'Delete Location'
  }
];

class MapContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      changeCounter: new Date().getTime(),
      showLightbox: false,
      showAnnotations: true,
      currentImageIndex: 0,
      contextMenu: null,
      showModal: false,
      modal: {
        heading: null,
        body: null
      },
      activeTabKey: 'location',
      isPrePlanningMode: false,
      showDrawingTools: false,
      isPolygonDrawn: false,
      prePlanGeoOutline: [],
      prePlanLatLng: null,
      locationDataForm: {
        lotNumber: null,
        customerZone: null,
        doNotShare: null,
        isHighHazard: null,
        highHazardDescription: null,
        riskAssessmentScore: null,
        storey: null,
        storeyBelow: null,
        roofArea: null,
        requiredFlow: null
      },
      buildingDataForm: {
        occupancyType: null,
        constructionType: null,
        roofType: null,
        roofConstruction: null,
        roofMaterial: null,
        sprinklered: null,
        standPipe: null,
        fireAlarm: null,
        normalPopulation: null,
        hoursOfOperation: null,
        ownerContact: null,
        ownerPhone: null,
        originalPrePlan: null,
        lastReviewedOn: null,
        lastReviewedBy: null,
        notes: null,
        commercialStructure: null
      },
      isGettingPreplan: false,
      isAnnotationMode: false,
      reDrawPolygon: false,
      locationKounter: null,
      getDistacebyPolyline: '',
      distancePolyline: null,
      prevPolylineData: [],
      showDrawingToolsOption: null,
      formStorey: null,
      formStoreyBelow: null,
      hydrantInMove: null,
      buildingInMove: null,
      buildingDragStartingPoint: null,
    };
    this.setBuildingDragStartingPoint = this.setBuildingDragStartingPoint.bind(this);
    this.handleHydrantMove = this.handleHydrantMove.bind(this);
    this.handleBuildingMove = this.handleBuildingMove.bind(this);
    this.onBuildingDragEnd = this.onBuildingDragEnd.bind(this);
    this.handleBuildingPinMove = this.handleBuildingPinMove.bind(this);
    this.handleLocationClick = this.handleLocationClick.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleMapRightClick = this.handleMapRightClick.bind(this);
    this.handleContextMenuItemSelect = this.handleContextMenuItemSelect.bind(this);
    this.handleLocationNotSubmit = this.handleLocationNotSubmit.bind(this);
    this.handleHydrantFormSubmissionSuccess = this.handleHydrantFormSubmissionSuccess.bind(this);
    this.handleHydrantFormSubmissionError = this.handleHydrantFormSubmissionError.bind(this);
    this.handleHydrantFormSubmissionSuccessAll = this.handleHydrantFormSubmissionSuccessAll.bind(this);
    this.handleHydrantFormSubmissionErrorAll = this.handleHydrantFormSubmissionErrorAll.bind(this);
    this.deleteHydrantAll = this.deleteHydrantAll.bind(this);
    this.handleHydrantRightClick = this.handleHydrantRightClick.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleGetHydrantsSuccess = this.handleGetHydrantsSuccess.bind(this);
    this.handleGetHydrantsError = this.handleGetHydrantsError.bind(this);
    this.handleCancelPrePlanLocation = this.handleCancelPrePlanLocation.bind(this);
    this.handleCancelHydrantMove = this.handleCancelHydrantMove.bind(this);
    this.onCancelAction = this.onCancelAction.bind(this);
    this.handleCancelPrePlanConfirm = this.handleCancelPrePlanConfirm.bind(this);
    this.handleSubmitLocationConfirm = this.handleSubmitLocationConfirm.bind(this);
    this.handleSubmitLocationConfirmEdit = this.handleSubmitLocationConfirmEdit.bind(this);
    this.handlePrePlanFormSubmit = this.handlePrePlanFormSubmit.bind(this);
    this.handleBuildingFormSubmit = this.handleBuildingFormSubmit.bind(this);
    this.handlePrePlanFormChange = this.handlePrePlanFormChange.bind(this);
    this.handleBuildingFormChange = this.handleBuildingFormChange.bind(this);
    this.handlePolygonComplete = this.handlePolygonComplete.bind(this);
    this.handleGetPreplanSuccess = this.handleGetPreplanSuccess.bind(this);
    this.handleGetPreplanSuccessRedraw = this.handleGetPreplanSuccessRedraw.bind(this);
    this.handleGetPreplanSuccessRedrawFirst = this.handleGetPreplanSuccessRedrawFirst.bind(this);
    this.handleAddLocationSuccess = this.handleAddLocationSuccess.bind(this);
    this.handleLocationRightClick = this.handleLocationRightClick.bind(this);
    this.handleGetPreplanByLocationSuccess = this.handleGetPreplanByLocationSuccess.bind(this);
    this.handleLocationDeleteSuccess = this.handleLocationDeleteSuccess.bind(this);
    this.handleLocationDeleteConfirm = this.handleLocationDeleteConfirm.bind(this);
    this.handleEditLocationSuccess = this.handleEditLocationSuccess.bind(this);
    this.handlePrePlanSuccess = this.handlePrePlanSuccess.bind(this);
    this.toggleLightbox = this.toggleLightbox.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.falseModal = this.falseModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeContextMenu = this.closeContextMenu.bind(this);
    this.setHydrantProps = this.setHydrantProps.bind(this);
    this.selectHydrantsByLocation = this.selectHydrantsByLocation.bind(this);
    this.showInitialLoading = this.showInitialLoading.bind(this);
    this.populateLocationDataForm = this.populateLocationDataForm.bind(this);
    this.selectALocation = this.selectALocation.bind(this);
    this.handleNewMessageSuccess = this.handleNewMessageSuccess.bind(this);
    this.handleNewMessageError = this.handleNewMessageError.bind(this);
    this.handleNewMessageConnect = this.handleNewMessageConnect.bind(this);
    this.handleNewMessageClosed = this.handleNewMessageClosed.bind(this);
    this.resetSelectedLocationConfirm = this.resetSelectedLocationConfirm.bind(this);
    this.resetSelectedLocationConfirmSelection = this.resetSelectedLocationConfirmSelection.bind(this);
    this.resetSelectedLocationDecline = this.resetSelectedLocationDecline.bind(this);
    this.handleRePlanSubmit = this.handleRePlanSubmit.bind(this);
    this.handleRePlanStart = this.handleRePlanStart.bind(this);
    this.showLoadingLocation = this.showLoadingLocation.bind(this);
    this.handleLocationSuccess = this.handleLocationSuccess.bind(this);
    this.handleLocationError = this.handleLocationError.bind(this);
    this.handleFilterCriteria = this.handleFilterCriteria.bind(this);
    this.handleLocatorClick = this.handleLocatorClick.bind(this);
    this.handleNextLocatorClick = this.handleNextLocatorClick.bind(this);
    this.handlePrevLocatorClick = this.handlePrevLocatorClick.bind(this);
    this.handlePolylineComplete = this.handlePolylineComplete.bind(this);
    this.handleDistanceMode = this.handleDistanceMode.bind(this);
    this.onScreenshot = this.onScreenshot.bind(this);
    this.setMarkersFromSearch = this.setMarkersFromSearch.bind(this);
    // @todo required for location centering in order for map to render correctly
    // we can ensure the global is loaded beacuse we are wrapped using a loader function
    // so this component only renders when the global google object is loaded.
    this.geocoder = new google.maps.Geocoder();

    this.setModal = this.setModal.bind(this);

  }

  componentDidMount() {
    this.setState({
      changeCounter: new Date().getTime()
    });
  }

  handleNewMessageSuccess(event) {
    this.setState({
      newMsg: true,
      errorConnection: false,
      closedConnection: false
    });
  }

  handleNewMessageError(event) {
    this.setState({
      newMsg: false,
      errorConnection: true,
      closedConnection: false
    });
  }

  handleNewMessageConnect(event) {
    this.setState({
      errorConnection: false,
      closedConnection: false
    });
  }

  handleNewMessageClosed(event) {
    this.setState({
      newMsg: false,
      errorConnection: true,
      closedConnection: true
    });
  }

  // handledFailedCentereLocation = (nextProps) => {
  //   // @todo handle failed center?
  //   this.geocoder.geocode({ 'address': `${nextProps.customer.city} ${nextProps.customer.state}` }, function (results, status) {
  //     if (status === 'OK') {
  //       nextProps.customer.config.lat = results[0].geometry.location.lat();
  //       nextProps.customer.config.lon = results[0].geometry.location.lng();
  //     } else {
  //       nextProps.customer.config.lat = 41.1209;
  //       nextProps.customer.config.lon = -88.8354;
  //     }
  //   });
  // }

  showLoadingLocation() {
    this.setState({
      loading: true
    });
  }

  // @todo confirm this is what we want...
  handleLocationSuccess(location) {
    const { locations } = this.props;
    const selectedLocation = locations.find(l => l.id === location.id);
    if (this.props.selectedLocation) {
      if (this.props.selectedLocation.id !== selectedLocation.id) {
        this.setState({
          isPrePlanningMode: false
        });
      }
    }
  }

  handleLocationError() {
    this.setState({
      loading: false
    });
    const message = 'Oops.. Something went wrong!!';
    const newState = Object.assign({}, this.state);
    newState.modal.body = (
      <div>
        <div> {message} </div>
        <div> Check your internet connection and refresh the browser </div>
        <div className="text-align-right margin-top-10px">
          <Button type="button" bsStyle="primary" onClick={this.toggleModal}>
            OK
          </Button>
        </div>
      </div>
    );
    this.setState(newState);
    this.showModal();
  }

  handleLocationClick(isLocationSelected, location, callback) {
    // this.props.setCustomerCurrentLocation(
    //   isLocationSelected ? null : location
    // );
    if (this.state.isPrePlanningMode || this.state.hydrantInMove || this.state.buildingInMove) {
      this.handleCancelPrePlanLocation();
    } else {
      callback();
    }
    this.closeContextMenu();
    //Check Location Reordering
  }

  handleMapClick(e) {
    this.closeContextMenu();
  }

  handleMapRightClick(pixel, latLng, address) {
    if (this.state.isPrePlanningMode ||
        this.state.hydrantInMove ||
        this.state.buildingInMove ||
        this.state.activeTabKey === 'firstIn' ||
        this.state.activeTabKey === 'command') {
      return;
    }
    this.setState({
      contextMenu: {
        entity: {
          x: pixel.x + 35,
          y: pixel.y + 40,
          latLng: latLng,
          streetAddress: address
        },
        items: mapMenuItems
      }
    });
  }

  handleHydrantRightClick(pixel, hydrant) {
    if (this.state.activeTabKey === 'firstIn' || this.state.activeTabKey === 'command') {
      return;
    }
    this.setState({
      contextMenu: {
        entity: {
          id: hydrant.id,
          x: pixel.x,
          y: pixel.y,
          latLng: {
            lat: hydrant.position.lat,
            lng: hydrant.position.lng
          },
          streetAddress: hydrant.streetAddress,
          flow: hydrant.flow,
          size: hydrant.size,
          notes: hydrant.notes,
          inService: hydrant.inService,
          dryHydrant: hydrant.dryHydrant,
          outServiceDate: hydrant.outServiceDate,
          hydrantId: hydrant.hydrantId,
        },
        items: hydrantMenuItems
      }
    });
  }

  handleLocationRightClick(pixel, location) {
    if (this.state.isPrePlanningMode ||
        this.state.hydrantInMove ||
        this.state.buildingInMove ||
        this.state.activeTabKey === 'firstIn' ||
        this.state.activeTabKey === 'command') {
      return;
    }
    this.setState({
      contextMenu: {
        entity: {
          location: location,
          x: pixel.x,
          y: pixel.y
        },
        items: locationMenuItems
      }
    });
  }

  handleContextMenuItemSelect(menuItem, entity) {

    const { locations } = this.props;
    this.closeContextMenu();
    switch (menuItem.value) {
      case 'ADD_HYDRANT':
        this.setState({
          modal: {
            heading: 'Add Hydrant',
            body: (
              <HydrantDetailsForm
                hydrant={entity}
                closeModal={this.toggleModal}
                action={menuItem.value}
              />
            )
          }
        });
        this.toggleModal();
        break;

      case 'EDIT_HYDRANT':
        this.setState({
          modal: {
            heading: 'Edit Hydrant',
            body: (
              <HydrantDetailsForm
                hydrant={entity}
                closeModal={this.toggleModal}
                action={menuItem.value}
              />
            )
          }
        });
        this.toggleModal();
        break;

      case 'MOVE_HYDRANT':
        this.setState({
          hydrantInMove: entity,
        });
        break;

      case 'DELETE_HYDRANT':
        this.setState({
          modal: {
            heading: 'Delete a Hydrant',
            body: <HydrantDetailsForm hydrant={entity} closeModal={this.toggleModal} action={menuItem.value} />,
          }
        });
        this.toggleModal();
        break;
      case 'DELETE_HYDRANT_ALL':
        this.setState({
          modal: {
            heading: 'Delete All Hydrants',
            body: (
              <ConfirmForm
                body="Are you sure you want to delete all hydrants?"
                onDecline={this.toggleModal}
                form={{
                  action: menuItem.value,
                  entity: entity
                }}
              />
            )
          }
        });
        this.toggleModal();
        break;
      case 'PREPLAN_LOCATION':
        this.setState({
          activeTabKey: 'location',
          isPrePlanningMode: true,
          showDrawingTools: true,
          showDrawingToolsOption: 'Polygon',
          // @todo unsure about this
          locationDataForm: {...this.state.locationDataForm, action: 'DRAWING_LOCATION'}
        });
        this.props.clearSelectedHydrants();
        break;

      case 'EDIT_LOCATION':
        const loc = locations.find(l => l.id === entity.location.id);
        this.props.setSelectedLocationId(loc.id);
        this.setState({
          activeTabKey: 'location',
          isPrePlanningMode: true,
          isPolygonDrawn: true,
          isSelected: true,
        }, () => {
          this.selectHydrantsByLocation(loc);
          this.populateLocationDataForm(loc, 'EDIT_LOCATION');
        });
        break;

      case 'REPLAN_LOCATION':
        const rePlanLocation = locations.find(l => l.id === entity.location.id);
        this.props.setSelectedLocationId(rePlanLocation.id);

        this.setState({
          isGettingPreplan: true,
          activeTabKey: 'location',
          isPrePlanningMode: true,
          showDrawingTools: false,
          isPolygonDrawn: false
        });

        this.props.getPreplanByLocation(
          rePlanLocation,
          this.handleGetPreplanByLocationSuccess,
          this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'locations')
        );
        break;

      case 'MOVE_LOCATION':
        const location = locations.find(l => l.id === entity.location.id);
        this.props.setSelectedLocationId(location.id);

        this.setState({
          buildingInMove: entity,
          buildingDragStartingPoint: null,
          isGettingPreplan: true,
          activeTabKey: 'location',
        });

        this.props.getPreplanByLocation(
          location,
          this.handleGetPreplanByLocationSuccess,
          this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'locations')
        );
        break;

      case 'DELETE_LOCATION':
        this.setState({
          modal: {
            heading: 'Delete Location',
            body: (<Text>Are you sure you want to delete this location?</Text>),
            footer: (<HStack justifyContent="end" spacing="1rem">
              <OutlineButton onClick={this.toggleModal} width="8rem">No</OutlineButton>
              <PrimaryButton onClick={() => this.handleLocationDeleteConfirm({
                entity: entity.location
              })} width="8rem">Yes</PrimaryButton>
            </HStack>)
          }
        });
        this.toggleModal();
        break;

      default:
        break;
    }
  }

  handleHydrantMove(pixel, latLng) {
    const newHydrant = {
      ...this.state.hydrantInMove,
      x: pixel.x,
      y: pixel.y,
      latLng: latLng,
    };

    this.setState({
      modal: {
        heading: 'Edit Hydrant',
        body: (
          <HydrantDetailsForm
            hydrant={newHydrant}
            closeModal={() => {
              this.setState({
                hydrantInMove: null,
                showModal: !this.state.showModal
              });
            }}
            action={'EDIT_HYDRANT'}
          />
        )
      }
    });
    this.toggleModal();
  }

  setBuildingDragStartingPoint(latLng) {
    this.setState({
      buildingDragStartingPoint: latLng
    });
  }

  onBuildingDragEnd({lat, lng}) {
    if (!this.state.buildingDragStartingPoint) return;

    const distance = {
      lat: lat - this.state.buildingDragStartingPoint.lat,
      lng: lng - this.state.buildingDragStartingPoint.lng
    };
    this.handleBuildingMove(distance);
  }

  handleBuildingMove(distance) {
    if (!this.state.buildingInMove) return;

    const newGeoOutline = this.state.buildingInMove.location.geoOutline.map(
      coord => ({
        latitude: coord.latitude + distance.lat,
        longitude: coord.longitude + distance.lng
      }));

    const newLocation = {
      ...this.state.buildingInMove.location,
      geoOutline: newGeoOutline
    };

    this.setState({
      buildingDragStartingPoint: null,
      isGettingPreplan: true,
      activeTabKey: 'location',
      buildingInMove: {
        ...this.state.buildingInMove,
        location: newLocation
      }
    });

    this.props.getPreplan(
      newGeoOutline,
      this.state.locationDataForm.storey,
      this.state.locationDataForm.storeyBelow,
      this.handleGetPreplanSuccess,
      this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'preplan')
    );
    
  }

  handleBuildingPinMove(latLng) {
    const loc = this.props && this.props.locations.find(l => l.id === this.state.buildingInMove.location.id);

    this.setState({
      activeTabKey: 'location',
      isPolygonDrawn: true,
      isPrePlanningMode: true,
      isSelected: false,
      prePlanLatLng: latLng,
    }, () => {
      if (loc) {
        this.selectHydrantsByLocation(loc);
        this.populateLocationDataForm(loc, 'EDIT_LOCATION');
      }
    });
  }

  handleLocationNotSubmit(form) {
    this.toggleModal();
  }

  handleHydrantFormSubmissionSuccess() {
    this.toggleModal();
    this.props.clearSelectedHydrants();
  }

  handleHydrantFormSubmissionError(result) {
    const newState = Object.assign({}, this.state);
    if (result && result.data && result.data.conflictingLocations) {
      newState.modal = {
        heading: 'Cannot Delete Hydrant',
        body: <Text>Cannot delete hydrants binded to a location.</Text>,
        footer: <Flex justifyContent="right"><PrimaryButton onClick={this.toggleModal} w="8rem">Close</PrimaryButton></Flex>
      };
    } else {
      newState.modal.body = (
        <div>
          <span>There was an error submitting your request. Please try again. </span>
        </div>
      );
    }
    this.setState(newState);
  }

  handleHydrantFormSubmissionSuccessAll() {
    this.falseModal();
    this.props.clearSelectedHydrants();
    this.setState({
      errorDeleteAll: false
    });
  }

  handleHydrantFormSubmissionErrorAll() {
    const newState = Object.assign({}, this.state);
    newState.modal.body = (
      <div>
        <span>Error handling hydrant form submission. Please try again. </span>
      </div>
    );
    this.setState(newState);
    this.props.clearSelectedHydrants();
  }


  handleTabSelect(key) {
    this.setState({
      activeTabKey: key
    });
  }

  handleGetHydrantsSuccess(message) {
    if (message) {
      const newState = Object.assign({}, this.state);
      newState.modal.body = (
        <div>
          <div> {message} </div>
          <div className="text-align-right margin-top-10px">
            <Button type="button" bsStyle="primary" onClick={this.toggleModal}>
              OK
            </Button>
          </div>
        </div>
      );
      this.setState(newState);
      this.showModal();
    } else {
      this.falseModal();
    }
  }

  handleGetHydrantsError(msg) {
    let message = 'Error fetching hydrants';
    if (msg) {
      message = msg;
    }
    const newState = Object.assign({}, this.state);
    newState.modal.body = (
      <div>
        <div> {message} </div>
        <div> Check your internet connection and refresh the browser </div>
        <div className="text-align-right margin-top-10px">
          <Button type="button" bsStyle="primary" onClick={this.toggleModal}>
            OK
          </Button>
        </div>
      </div>
    );
    this.setState(newState);
    this.showModal();
  }

  handleCancelPrePlanLocation() {
    this.setState({
      modal: {
        heading: 'Exit Pre-planning',
        body: <Text>Are you sure you want to exit from Pre-planning?</Text>,
        footer: (<HStack justifyContent="end" spacing="1rem">
          <OutlineButton onClick={this.toggleModal} width="8rem">No</OutlineButton>
          <PrimaryButton onClick={this.handleCancelPrePlanConfirm} width="8rem">Yes</PrimaryButton>
        </HStack>)
      }
    });
    this.toggleModal();
  }

  handleCancelHydrantMove() {
    this.setState({
      modal: {
        heading: 'Exit Hydrant Move',
        body: <Text>Are you sure you want to cancel moving hydrant?</Text>,
        footer: (<HStack justifyContent="end" spacing="1rem">
          <OutlineButton onClick={this.toggleModal} width="8rem">No</OutlineButton>
          <PrimaryButton onClick={this.handleCancelPrePlanConfirm} width="8rem">Yes</PrimaryButton>
        </HStack>)
      }
    });
    this.toggleModal();
  }

  handleCancelBuildingMove() {
    this.setState({
      modal: {
        heading: 'Exit Building Move',
        body: <Text>Are you sure you want to cancel moving building?</Text>,
        footer: (<HStack justifyContent="end" spacing="1rem">
          <OutlineButton onClick={this.toggleModal} width="8rem">No</OutlineButton>
          <PrimaryButton onClick={this.handleCancelPrePlanConfirm} width="8rem">Yes</PrimaryButton>
        </HStack>)
      }
    });
    this.toggleModal();
  }

  onCancelAction() {
    if (this.state.hydrantInMove) this.handleCancelHydrantMove();
    else if (this.state.buildingInMove) this.handleCancelBuildingMove();
    else this.handleCancelPrePlanLocation();
  }

  setMarkersFromSearch(markers) {
    this.setState({ markersFromSearch: markers });
  }

  selectALocation(p) {
    //Check Location Reordering
    if (this.props.selectedLocation) {
      if (this.props.selectedLocationImageReorder && (this.props.selectedLocation.id === this.props.selectedLocationImageReorder)) {
        this.handleImageReorderingSelection(p);
        return false;
      }
    }

    if (p.locationID) {
      const loc = this.props.locations.find(l => l.id === p.locationID);
      if (loc !== this.props.selectedLocation) {
        this.setState({
          isPrePlanningMode: false,
          showDrawingTools: false,
          isPolygonDrawn: false,
          prePlanGeoOutline: [],
          prePlanLatLng: null,
          isSelected: true,
          newMsg: false
        }, () => {
          this.selectHydrantsByLocation(loc);
        });
        // Moving selected location logic outside of local state.
        // this.props.setCustomerCurrentLocation(loc);
        this.props.setSelectedLocationId(loc.id);

      }
    } else {
      this.setState({
        isPrePlanningMode: false,
        showDrawingTools: false,
        isPolygonDrawn: false,
        prePlanGeoOutline: [],
        prePlanLatLng: null,
        isSelected: false,
        newMsg: false
      }, () => {
        this.props.clearSelectedHydrants();
      });
      // Reset current location and move it outside of current state.
      // this.props.setCustomerCurrentLocation(null);
      this.props.setSelectedLocationId(null);
    }

    if (p.latLon) {
      const marker = { position: { lat: p.latLon.latitude, lng: p.latLon.longitude } };
      this.setMarkersFromSearch([
        marker
      ]);
    }

    return true;
  }

  handleSubmitLocationConfirmEdit() {
    const selectLocation = this.props.locations[this.props.locations.findIndex(l => l.id === this.props.selectedLocation.id)];
    // this.toggleModal();
    this.setState({
      isPrePlanningMode: false,
      activeTabKey: 'building',
      isSelected: true,
      showDrawingTools: false,
      isPolygonDrawn: false,
      prePlanGeoOutline: [],
      prePlanLatLng: null,
    }, () => {
      this.removePolygons();
      this.selectHydrantsByLocation(selectLocation);
    });
  }

  handleSubmitLocationConfirm() {
    // const selectLocation = this.props.locations[this.props.locations.findIndex(l => l.id === this.props.selectedLocation.id)];
    // this.toggleModal();
    this.setState({
      isPrePlanningMode: false,
      activeTabKey: 'location',
      isSelected: true,
      showDrawingTools: false,
      isPolygonDrawn: false,
      prePlanGeoOutline: [],
      buildingInMove: null,
      buildingDragStartingPoint: null,
      prePlanLatLng: null,
    }, () => {
      this.removePolygons();
      this.selectHydrantsByLocation(this.props.selectedLocation);
    });
  }

  handleCancelPrePlanConfirm() {
    this.toggleModal();
    this.setState({
      isPrePlanningMode: false,
      hydrantInMove: null,
      buildingInMove: null,
      buildingDragStartingPoint: null,
      showDrawingTools: false,
      isPolygonDrawn: false,
      prePlanGeoOutline: [],
      prePlanLatLng: null,
      locationDataForm: {
        lotNumber: null,
        customerZone: null,
        doNotShare: null,
        isHighHazard: null,
        highHazardDescription: null,
        riskAssessmentScore: null,
        storey: null,
        storeyBelow: null,
        roofArea: null,
        requiredFlow: null
      },
      buildingDataForm: {
        occupancyType: null,
        constructionType: null,
        roofType: null,
        roofConstruction: null,
        roofMaterial: null,
        sprinklered: null,
        standPipe: null,
        fireAlarm: null,
        normalPopulation: null,
        hoursOfOperation: null,
        ownerContact: null,
        ownerPhone: null,
        originalPrePlan: null,
        lastReviewedOn: null,
        lastReviewedBy: null,
        notes: null,
        commercialStructure: null
      },
      reDrawPolygon: false
    });
    this.props.setSelectedLocationId(null);
    this.props.clearSelectedHydrants();
    this.removePolygons();
  }

  handlePrePlanFormChange(form) {
    if (form.action === 'REPLAN_LOCATION') {
      if (this.state.formStorey !== form.storey || this.state.formStoreyBelow !== form.storeyBelow) {
        this.setState({
          isGettingPreplan: true
        });
        this.props.getPreplan(this.state.recentLocationCoords, form.storey, form.storeyBelow, this.handleGetPreplanSuccessRedrawFirst, this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'preplan'));
        this.setState({
          formStorey: form.storey,
          formStoreyBelow: form.storeyBelow
        });
      }
    } else if (form.action === 'ADD_LOCATION' || form.action === 'EDIT_LOCATION' || form.action === 'REPLAN_LOCATION') {
      if (this.state.locationDataForm.storey !== form.storey || this.state.locationDataForm.storeyBelow !== form.storeyBelow) {
        //need to fetch pre-plan as well
        //Uptill 1 there is no need
        let noOfStoriesState = 0;
        let noOfStories = 0;

        if (this.state.locationDataForm.storey && this.state.locationDataForm.storey > 0) {
          noOfStoriesState += this.state.locationDataForm.storey;
        }
        if (this.state.locationDataForm.storeyBelow && this.state.locationDataForm.storeyBelow > 0) {
          noOfStoriesState += this.state.locationDataForm.storeyBelow;
        }

        if (form.storey && form.storey > 0) {
          noOfStories += form.storey;
        }
        if (form.storeyBelow && form.storeyBelow > 0) {
          noOfStories += form.storeyBelow;
        }


        if (noOfStoriesState !== noOfStories) {
          this.setState({
            isGettingPreplan: true
          });
          if (form.action === 'REPLAN_LOCATION') {
            this.props.getPreplan(this.state.recentLocationCoords, form.storey, form.storeyBelow, this.handleGetPreplanSuccessRedraw, this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'preplan'));
          } else if (form.action === 'EDIT_LOCATION') {
            this.props.getPreplan(this.props.selectedLocation.geoOutline, form.storey, form.storeyBelow, this.handleGetPreplanSuccessRedraw, this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'preplan'));
          } else {
            this.props.getPreplan(this.state.recentLocationCoords, form.storey, form.storeyBelow, this.handleGetPreplanSuccess, this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'preplan'));
          }
        }
      }
    }
    this.setState({
      locationDataForm: form
    });
  }

  handleBuildingFormChange(form) {
    this.setState({
      buildingDataForm: form
    });
  }

  handlePrePlanSubmit(form, locationForm, buildingForm) {

  }

  handleRePlanSubmit() {
    const form = this.state.locationDataForm;
    form.action = 'REPLAN_LOCATION';
    const locationForm = this.state.locationDataForm;
    const buildingForm = this.state.buildingDataForm;
    this.handlePrePlanSubmit(form, locationForm, buildingForm);
  }

  handleRePlanStart() {
    this.setState({
      showDrawingTools: true,
      showDrawingToolsOption: 'Polygon',
      isPolygonDrawn: false,
      prePlanGeoOutline: [],
      prePlanLatLng: null,
      reDrawPolygon: true,
      formStorey: null,
      formStoreyBelow: null
    }, () => {
      this.props.clearSelectedHydrants();
      this.removePolygons();
    });
  }

  handlePrePlanFormSubmit(form) {
    const locationForm = form;
    const buildingForm = this.state.buildingDataForm;
    this.handlePrePlanSubmit(form, locationForm, buildingForm);
  }

  handleBuildingFormSubmit(form) {
    const locationForm = this.state.locationDataForm;
    const buildingForm = form;
    this.handlePrePlanSubmit(form, locationForm, buildingForm);
  }

  handlePolygonComplete(coords) {
    this.setState({
      isGettingPreplan: true,
    });
    const locationCoords = [];
    coords.forEach(function(coord) {
      locationCoords.push({
        latitude: coord.lat,
        longitude: coord.lng
      });
    });
    locationCoords.push({
      latitude: coords[0].lat,
      longitude: coords[0].lng
    });
    this.setState({
      recentLocationCoords: locationCoords
    });
    if (this.state.locationDataForm) {
      if (this.state.locationDataForm.action === 'REPLAN_LOCATION') {
        this.props.getPreplan(locationCoords, this.state.locationDataForm.storey, this.state.locationDataForm.storeyBelow, this.handleGetPreplanSuccess, this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'preplan'));
        return;
      }
    }
    this.props.getPreplan(locationCoords, 1, 0, this.handleGetPreplanSuccess, this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'preplan'));
  }


  handlePolylineComplete(polyline) {
    const path = polyline.getPath();
    let dist = 0; // Distance in meters
    for (let ii = 1; ii < path.length; ii++) {
      dist += getDistance(path.getAt(ii - 1).lat(), path.getAt(ii - 1).lng(), path.getAt(ii).lat(), path.getAt(ii).lng());
    }
    this.setState({
      getDistacebyPolyline: `${Math.round(dist)}ft.`,
      distancePolyline: {
        polyline: polyline,
        distance: `${Math.round(dist)}ft.`
      }
    });
  }

  handleDistanceMode(e) {
    if (e.target.checked) {
      this.setState({
        showDrawingTools: true,
        showDrawingToolsOption: 'Polyline'
      });
    } else {
      this.setState({
        showDrawingTools: false,
        showDrawingToolsOption: null,
        getDistacebyPolyline: '',
        distancePolyline: null
      });
    }
  }

  handleGetPreplanSuccessRedraw(preplanData) {
    const alocationDataForm = this.state.locationDataForm;
    alocationDataForm.roofArea = preplanData.roofArea;
    alocationDataForm.requiredFlow = preplanData.requiredFlow;
    this.setState({
      isPolygonDrawn: true,
      isPrePlanningMode: true,
      prePlanGeoOutline: preplanData.geoOutline,
      locationDataForm: alocationDataForm,
      isGettingPreplan: false,
      reDrawPolygon: false,
      showDrawingTools: false,
      showDrawingToolsOption: null,
      getDistacebyPolyline: '',
      distancePolyline: null,
      formStorey: alocationDataForm.storey,
      formStoreyBelow: alocationDataForm.storeyBelow
    });
    this.selectHydrantsByLocation(preplanData);
  }

  handleGetPreplanSuccessRedrawFirst(preplanData) {
    const alocationDataForm = this.state.locationDataForm;
    alocationDataForm.roofArea = preplanData.roofArea;
    alocationDataForm.requiredFlow = preplanData.requiredFlow;
    this.setState({
      isPolygonDrawn: true,
      isPrePlanningMode: true,
      prePlanGeoOutline: preplanData.geoOutline,
      locationDataForm: alocationDataForm,
      isGettingPreplan: false,
      reDrawPolygon: false,
      showDrawingTools: false,
      showDrawingToolsOption: null,
      getDistacebyPolyline: '',
      distancePolyline: null,
    });
    this.selectHydrantsByLocation(preplanData);
  }

  handleGetPreplanSuccess(preplanData) {
    if (this.state.reDrawPolygon) {
      const alocationDataForm = this.state.locationDataForm;
      alocationDataForm.roofArea = preplanData.roofArea;
      alocationDataForm.requiredFlow = preplanData.requiredFlow;
      this.setState({
        isPolygonDrawn: true,
        isPrePlanningMode: true,
        prePlanGeoOutline: preplanData.geoOutline,
        locationDataForm: alocationDataForm,
        isGettingPreplan: false,
        reDrawPolygon: false,
        showDrawingTools: false,
        showDrawingToolsOption: null,
        getDistacebyPolyline: '',
        distancePolyline: null,
      });
      this.selectHydrantsByLocation(preplanData);
    } else if (this.state.buildingInMove) {
      const alocationDataForm = this.state.locationDataForm;
      alocationDataForm.roofArea = preplanData.roofArea;
      alocationDataForm.requiredFlow = preplanData.requiredFlow;
      alocationDataForm.action = 'EDIT_LOCATION';
      this.setState({
        isPolygonDrawn: true,
        isPrePlanningMode: true,
        prePlanGeoOutline: preplanData.geoOutline,
        locationDataForm: alocationDataForm,
        isGettingPreplan: false,
        isSelected: false
      });
      this.selectHydrantsByLocation(preplanData);
    } else {
      const locationDataForm = {
        storey: preplanData.storey,
        storeyBelow: preplanData.storeyBelow,
        roofArea: preplanData.roofArea,
        requiredFlow: preplanData.requiredFlow,
        action: 'ADD_LOCATION'
      };
      const buildingDataForm = {
        occupancyType: null,
        constructionType: null,
        roofType: null,
        roofConstruction: null,
        roofMaterial: null,
        sprinklered: null,
        standPipe: null,
        fireAlarm: null,
        normalPopulation: null,
        hoursOfOperation: null,
        ownerContact: null,
        ownerPhone: null,
        originalPrePlan: null,
        lastReviewedOn: null,
        lastReviewedBy: null,
        notes: null,
        commercialStructure: null,
        action: 'NEW_BUILDING'
      };
      // @todo handle location geocoding differently
      const _this = this;
      this.geocoder.geocode(
        {
          location: {
            lat: preplanData.planningCenter.latitude,
            lng: preplanData.planningCenter.longitude
          }
        },
        function(results, status) {
          // google.maps.GeocoderStatus.OK
          if (status === 'OK') {
            const tmp = { address1: null, city: null, state: null, zip: null };
            for (let i  = 0; i < results.length && results[i]; i++) {
              tmp.address1 = null;
              tmp.city = null;
              tmp.state = null;
              tmp.zip = null;
              results[i].address_components.forEach(function(address) {
                const addressComponent = address.short_name;
                if (address.types && address.types[0] && addressComponent) {
                  switch (address.types[0]) {
                    case 'street_number':
                      tmp.address1 = addressComponent;
                      break;
                    case 'route':
                      tmp.address1 = `${tmp.address1} ${addressComponent}`;
                      break;
                    case 'locality':
                      tmp.city = addressComponent;
                      break;
                    case 'administrative_area_level_1':
                      tmp.state = addressComponent;
                      break;
                    case 'postal_code':
                      tmp.zip = addressComponent;
                      break;
                    default:
                      break;
                  }
                }
              });
              if (tmp.address1) break;
            }
            locationDataForm.address1 = tmp.address1;
            locationDataForm.city = tmp.city;
            locationDataForm.state = tmp.state;
            locationDataForm.zip = tmp.zip;
          }
          _this.setState({
            isPolygonDrawn: true,
            isPrePlanningMode: true,
            prePlanGeoOutline: preplanData.geoOutline,
            locationDataForm: locationDataForm,
            buildingDataForm: buildingDataForm,
            isGettingPreplan: false,
            isSelected: false
          });
          _this.selectHydrantsByLocation(preplanData);
        }
      );
    }
  }

  handleAddLocationSuccess() {
    const selectLocation = this.props.locations[this.props.locations.findIndex(l => l.id === this.props.selectedLocation?.id)];
    // this.toggleModal();
    this.setState({
      isPrePlanningMode: false,
      activeTabKey: 'location',
      isSelected: true,
      showDrawingTools: false,
      isPolygonDrawn: false,
      prePlanGeoOutline: [],
    }, () => {
      this.removePolygons();
      this.selectHydrantsByLocation(selectLocation);
    });
  }

  handleEditLocationSuccess() {
    this.handleSubmitLocationConfirm();
  }

  handlePrePlanSuccess() {
    this.handleSubmitLocationConfirmEdit();
  }

  handleGetPreplanByLocationSuccess(location, preplanData) {
    this.populateLocationDataForm(location, 'REPLAN_LOCATION', preplanData);
  }

  async handleLocationDeleteConfirm(form) {
    const response = await this.props.deleteLocation(form.entity);
    if (deleteLocation.fulfilled.match(response)) {
      this.handleLocationDeleteSuccess();
    }
  }

  handleLocationDeleteSuccess() {
    this.toggleModal();
    this.props.clearSelectedHydrants();
  }

  deleteHydrantAll() {
    this.props.deleteAllHydrants(
      this.handleHydrantFormSubmissionSuccessAll,
      this.handleHydrantFormSubmissionErrorAll,
      this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'hydrants')
    );
  }

  showModal() {
    this.setState({
      showModal: true
    });
  }

  falseModal() {
    this.setState({
      showModal: false
    });
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  setModal(modal) {
    this.setState({
      modal: modal,
      showModal: true
    });
  }

  resetSelectedLocationConfirmSelection() {
    const selectedLocation = this.props.locations[this.props.locations.findIndex(l => l.id === this.props.selectedLocationImageReorder)];
    if (this.props.selectedLocationImageOriginal) {
      selectedLocation.images = this.props.selectedLocationImageOriginal;
    }
    this.setState({
      selectedLocationImageReorder: null,
      isSelected: false
    }, () => {
      this.props.clearSelectedHydrants();
      this.selectALocation(this.state.lastSelectedLocationMessage);
    });
    this.toggleModal();
  }

  resetSelectedLocationConfirm() {
    const selectedLocation = this.props.locations[this.props.locations.findIndex(l => l.id === this.props.selectedLocationImageReorder)];
    if (this.props.selectedLocationImageOriginal) {
      selectedLocation.images = this.props.selectedLocationImageOriginal;
    }

    this.setState({
      selectedLocationImageReorder: null,
      isSelected: false
    }, () => {
      this.props.clearSelectedHydrants();
    });
    this.toggleModal();
  }

  resetSelectedLocationDecline() {
    const selectedLocation = this.props.locations[this.props.locations.findIndex(l => l.id === this.props.selectedLocationImageReorder)];
    this.setState({
      isSelected: true
    }, () => {
      this.selectHydrantsByLocation(selectedLocation);
    });
    this.toggleModal();
  }

  annotateImage(index) {
    // const imageId = this.props.selectedLocation.images[index].id;
    // const locationId = this.props.selectedLocation.id;
    const imageId = this.props.selectedLocation.images[index].id;
    const locationId = this.props.selectedLocation.id;
    locationAPI.getAnnotation(locationId, imageId, this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'locations')).then((res, err) => {
      if (err) console.warn(err);
      let json = res.data;
      if (res.data === '') {
        json = null;
      } else if (res.data) {
        json = JSON.parse(res.data);
      }
      this.setState({
        currentImageIndex: index,
        annotationJson: json
      });
      locationAPI.getTags(locationId, imageId, this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'locations')).then((res1, err1) => {
        if (err1) console.warn(err1);
        let tags = [];
        if (res1.data === '') {
          tags = [];
        } else if (res1.data) {
          tags = [...res1.data];
        }
        const tagsRefined = [];
        for (let ii = 0; ii < tags.length; ii++) {
          const tag = { id: tags[ii], text: tags[ii] };
          tagsRefined.push(tag);
        }
        this.setState({
          isAnnotationMode: true
        });
      });
    });
  }

  deleteImage1(entity, index) {
    const imageName = entity.state.selectedLocation.images[index].title;
    entity.toggleModal();
    const imageId = entity.state.selectedLocation.images[index].id;
    const locationId = entity.state.selectedLocation.id;
    entity.props
      .deleteImage1(locationId, imageId)
      .then(res => {
        //Deleted Successfully
        console.info(`Deleted Image: ${imageName}`);
      })
      .catch(e => {
        entity.setState({
          modal: {
            heading: 'Image Deletion',
            body: (
              <DialogForm body="Image deletion failed .. Refresh the page"
                onConfirm={entity.toggleModal}
              />)
          }
        });
        entity.toggleModal();
        return entity.state;
      });
    entity.setState(prevState => {
      prevState.selectedLocation.images.splice(index, 1);
      return prevState;
    });
  }

  showSavingAnnotation(progress) {
    const savingModal = {
      heading: 'Saving',
      body: (
        <div>
          <span> Saving Annotated Image </span>
        </div>
      ),
      footer: (<span />)
    };
    this.setState({
      modal: savingModal
    }, () => {
      this.toggleModal();
    });
  }

  saveAnnotation(json, svg) {
    const imageId = this.props.selectedLocation.images[this.state.currentImageIndex].id;
    const locationId = this.props.selectedLocation.id;
    const selectLocation = this.props.locations[this.props.locations.findIndex(l => l.id === locationId)];

    this.showSavingAnnotation(90);

    this.props.saveAnnotation(locationId, imageId, json, svg, this.props.customerLinks && this.props.customerLinks.find(x => x.rel === 'locations')).then(({ data }) => {
      // Create new images array, replace image in place if id matches
      const images = [...selectLocation.images ? selectLocation.images : []].map(i => i.id === imageId ? data : i);
      this.props.locationUpdated({
        id: locationId,
        changes: {
          images
        }
      });
    }).then((res, err) => {
      if (err) console.warn('Annotation save failed', err);
      this.setState({
        isAnnotationMode: false,
        isSelected: true,
        changeCounter: new Date().getTime()
      }, () => {
        this.selectHydrantsByLocation(selectLocation);
        this.toggleModal();
      });
    });
  }

  cancelAnnotation() {
    const locationId = this.props.selectedLocation.id;
    const selectLocation = this.props.locations[this.props.locations.findIndex(l => l.id === locationId)];
    this.setState({
      isAnnotationMode: false,
      isSelected: true,
      changeCounter: new Date().getTime()
    }, () => {
      this.selectHydrantsByLocation(selectLocation);
    });
  }

  toggleLightbox() {
    this.setState({
      showLightbox: !this.state.showLightbox,
      showAnnotations: true
    });
  }

  closeContextMenu() {
    this.setState({
      contextMenu: null
    });
  }

  setHydrantProps(hydrant, props) {
    if ((this.state.isPrePlanningMode && !hydrant.isMine) || this.state.hydrantInMove || this.state.buildingInMove) {
      return;
    }
    this.props.hydrantSelected(hydrant.id);
  }

  selectHydrantsByLocation(targetLocation) {
    if (targetLocation?.hydrants) {
      const targetIds = targetLocation.hydrants.map(h => h.id);
      if (targetIds.length > 0) {
        this.props.setSelectedHydrants(targetIds);
      }
    }
  }

  showInitialLoading(msg, stepIndex, stepTotal) {
    let progressKnt = 100;
    let stepMsg = null;
    if (stepIndex > 0 && stepTotal > 0) {
      progressKnt = (stepIndex / stepTotal) * 100;
      if (progressKnt > 100) {
        progressKnt = 100;
      }
      stepMsg = `Step ${stepIndex}/${stepTotal}`;
    }
    let msgStr = 'Loading In Progress';
    if (msg) {
      msgStr = msg;
    }
    const loadingModal = {
      heading: 'Loading',
      body: (
        <div>
          <span> {msgStr} </span>
          <div> {stepMsg} </div>
        </div>
      )
    };
    this.setState({
      modal: loadingModal
    }, () => {
      this.toggleModal();
    });

  }

  populateLocationDataForm(location, action, preplanData) {
    const locationDataForm = {
      locationId: location.id,
      locationName: location.name,
      action: action,
    };
    const buildingDataForm = {
      locationId: location.id,
      occupancyType: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.occupancyType : null,
      constructionType: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.constructionType : null,
      roofType: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.roofType : null,
      roofConstruction: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.roofConstruction : null,
      roofMaterial: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.roofMaterial : null,
      sprinklered: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.sprinklered : null,
      standPipe: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.standPipe : null,
      fireAlarm: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.fireAlarm : null,
      normalPopulation: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.normalPopulation : null,
      hoursOfOperation: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.hoursOfOperation : null,
      ownerContact: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.ownerContact : null,
      ownerPhone: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.ownerPhone : null,
      originalPrePlan: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.originalPrePlan : null,
      lastReviewedOn: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.lastReviewedOn : null,
      lastReviewedBy: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.lastReviewedBy : null,
      notes: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.notes : null,
      commercialStructure: (location.building && (typeof (location.building) !== 'undefined')) ? location.building.commercialStructure : null,
      action: 'EDIT_BUILDING'
    };
    if (action === 'EDIT_LOCATION' || action === 'REPLAN_LOCATION') {
      // @todo this should not be prop by prop
      locationDataForm.storey = location.storey;
      locationDataForm.storeyBelow = location.storeyBelow;
      locationDataForm.lotNumber = location.lotNumber;
      locationDataForm.roofArea = location.roofArea;
      locationDataForm.requiredFlow = location.requiredFlow;
      locationDataForm.customerZone = location.customerZone;
      locationDataForm.doNotShare = location.doNotShare;
      locationDataForm.isHighHazard = location.isHighHazard;
      locationDataForm.highHazardDescription = location.highHazardDescription;
      locationDataForm.riskAssessmentScore = location.riskAssessmentScore;
    }
    for (const key in location.address) {
      if (location.address.hasOwnProperty(key)) {
        locationDataForm[key] = location.address[key];
      }
    }
    this.setState({
      locationDataForm: locationDataForm,
      buildingDataForm: buildingDataForm,
      isGettingPreplan: false
    });
    if (action === 'REPLAN_LOCATION') {
      this.selectHydrantsByLocation(preplanData);
    }
  }

  handleLocatorClick() {
    this.setLocation(1);
  }

  setLocation(kounter) {
    //Check Location Reordering
    let lKounter = kounter;
    if (lKounter < 1) {
      lKounter = 1;
    } else if (lKounter > this.props.locations.length) {
      lKounter = this.props.locations.length;
    }
    const selectLocation = this.props.locations[lKounter - 1];
    this.setState({
      isPrePlanningMode: false,
      showDrawingTools: false,
      showDrawingToolsOption: null,
      getDistacebyPolyline: '',
      distancePolyline: null,
      isPolygonDrawn: false,
      prePlanGeoOutline: [],
      isSelected: true,
      newMsg: false,
      locationKounter: lKounter
    }, () => {
      this.selectHydrantsByLocation(selectLocation);
    });
    return true;
  }

  handlePrevLocatorClick() {
    this.setLocation(this.state.locationKounter - 1);
  }

  handleNextLocatorClick() {
    this.setLocation(this.state.locationKounter + 1);
  }

  handleFilterCriteria(arr) {
    this.props.setSelectedLocationId(null);
    this.props.clearSelectedHydrants();
    this.props.setLocationFilters(arr);
  }

  //@todo move to utils
  dataURLtoBlob(dataurl) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  onScreenshot() {
    // const whichClass = this.props.selectedLocation ? '.map-container' : '.map-container1';
    // const docs = document.querySelector('.map-image-container');
    Capture(document.querySelector('.map-container'), {
      useCORS: true,
      ignoreElements: (el) => {
        if (el.classList.contains('gm-bundled-control')) {
          return true;
        }
        return el.nodeName === 'BUTTON' || el.nodeName === 'INPUT';
      },
      logging: true,
    }).then(canvas => {
      const imgData = canvas.toDataURL();
      const link = document.createElement('a');
      link.download = 'mapImage.png';
      const blob = this.dataURLtoBlob(imgData);
      const objurl = URL.createObjectURL(blob);
      link.href = objurl;
      document.body.appendChild(link);
      link.click();
    });
  }

  renderContext() {
    if (!this.state.contextMenu) return null;
    if (this.state.isPrePlanningMode ||
        this.state.hydrantInMove ||
        this.state.buildingInMove ||
        this.state.activeTabKey === 'firstIn' ||
        this.state.activeTabKey === 'command') return null;
    const contextMenuProps = {
      contextMenu: this.state.contextMenu,
      role: this.props.user.role,
      activeTabKey: this.state.activeTabKey,
      handleContextMenuItemSelect: this.handleContextMenuItemSelect
    };
    return <ContextMenu {...contextMenuProps} />;
  }

  render() {
    const mapProps = {
      markersFromSearch: this.state.markersFromSearch,
      handleHydrantRightClick: this.handleHydrantRightClick,
      handleLocationClick: this.handleLocationClick,
      handleLocationRightClick: this.handleLocationRightClick,
      handleMapClick: this.handleMapClick,
      handleMapRightClick: this.handleMapRightClick,
      setMarkerProps: this.setHydrantProps,
      clearSelectedHydrants: this.props.clearSelectedHydrants,
      selectHydrantsByLocation: this.selectHydrantsByLocation,
      setMarkersFromSearch: this.setMarkersFromSearch,
      handlePolygonComplete: this.handlePolygonComplete,
      handlePolylineComplete: this.handlePolylineComplete,
      distancePolyline: this.state.distancePolyline,
      setRemovePolygons: (f) => { this.removePolygons = f; },
      showDrawingTools: this.state.showDrawingTools,
      showDrawingToolsOption: this.state.showDrawingToolsOption,
      prevPolylineData: this.state.prevPolylineData,
      isInActionMode: this.state.isPrePlanningMode || this.state.hydrantInMove || this.state.buildingInMove,
      handleContextMenuItemSelect: this.handleContextMenuItemSelect,
      onCancelAction: this.onCancelAction,
      handleHydrantMove: this.handleHydrantMove,
      hydrantInMove: this.state.hydrantInMove,
      buildingInMove: this.state.buildingInMove,
      onBuildingDragEnd: this.onBuildingDragEnd,
      handleBuildingPinMove: this.handleBuildingPinMove,
      setBuildingDragStartingPoint: this.setBuildingDragStartingPoint,
    };

    const locationDataProps = {
      customer: this.props.customer,
      location: this.props.selectedLocation,
      locationDataForm: this.state.locationDataForm,
      buildingDataForm: this.state.buildingDataForm,
      isPrePlanningMode: this.state.isPrePlanningMode,
      isPolygonDrawn: this.state.isPolygonDrawn,
      isGettingPreplan: this.state.isGettingPreplan,
      onCancelPrePlan: this.handleCancelPrePlanLocation,
      onPrePlanFormSubmit: this.handlePrePlanFormSubmit,
      onBuildingFormSubmit: this.handleBuildingFormSubmit,
      onPrePlanFormChange: this.handlePrePlanFormChange,
      onBuildingFormChange: this.handleBuildingFormChange,
      onRePlanSubmit: this.handleRePlanSubmit,
      onRePlanStart: this.handleRePlanStart,
      reDrawPolygon: this.state.reDrawPolygon,
      onEditLocation: () => this.handleContextMenuItemSelect({value: 'EDIT_LOCATION'}, {location: this.props.selectedLocation}),
      buildingInMove: this.state.buildingInMove
    };

    const canEditImage = this.props.user && (this.props.user.role === 'ADMIN' || this.props.user.role === 'PLANNER');
    const showMap = !!(this.props.customer.config.lat && this.props.customer.config.lon);
    //@todo move to styles
    const customGlyphStyle = {
      position: 'absolute',
      top: '6rem',
      zIndex: 1,
      left: '0.75rem',
    };

    const imageProps = {
      selectedLocationImageReorder:
        this.props.selectedLocationImageReorder
          ? this.props.selectedLocationImageReorder
          : null,
      onAnnotation: index => this.annotateImage.bind(this, index),
      setImageIndex: (index) => { this.setState({ selectedImageIndex: index }); },
      activeTabKey: this.state.activeTabKey,
      isFIO: this.state.activeTabKey === 'firstIn',
      isCommand: this.state.activeTabKey === 'command',
      canEditImage: canEditImage,
      changeCounter: this.state.changeCounter,
      locationLoading: this.state.loading,
      toggleModal: this.toggleModal,
      setModal: this.setModal
    };

    return (
      <FlexWithBackgroundTheme w="100%" height={'calc(100vh - 4.25rem)'} overflow="hidden">
        {(showMap && !this.state.isAnnotationMode) ? (
          <Flex width="100%">
            <Box height="100%" width="100%" position="relative" className="map-image-container">
              <Stack style={customGlyphStyle} spacing="1rem">
                <Tooltip placement="right" label="Toggle distance mode">
                  <div>
                    <HStack boxShadow="md" background="#fff" paddingX="0.5rem" borderRadius="0.25rem">
                      {this.state.distancePolyline && <span>{this.state.distancePolyline.distance}</span>}
                      <Icon width={'2rem'} height={'2rem'} padding={'0.25rem'} as={FaRuler} />
                      <FormControl>
                        <Switch id="distanceMode" isChecked={this.state.showDrawingTools && this.state.showDrawingToolsOption === 'Polyline'} onChange={this.handleDistanceMode} />
                      </FormControl>
                    </HStack>
                  </div>
                </Tooltip>
                <Tooltip placement="right" label="Download Map Screenshot">
                  <Box height="2rem" width="2rem" >
                    <Icon
                      cursor={'pointer'}
                      as={FaCamera}
                      width={'2rem'}
                      height={'2rem'}
                      zIndex={1}
                      padding={'0.5rem'}
                      borderRadius={'0.25rem'}
                      background={'#fff'}
                      onClick={this.onScreenshot}
                      boxShadow="md"
                    />
                  </Box>
                </Tooltip>
                <MapFilters
                  onFilterChange={this.handleFilterCriteria}
                />
              </Stack>
              <Map {...mapProps} />
              <ImageStrip {...imageProps} />
            </Box>
            <Box width={'28rem'} flexShrink={'0'} overflow={'auto'}>
              <PrePlanFormContainer
                locationDataProps={locationDataProps}
                prePlanGeoOutline={this.state.prePlanGeoOutline}
                prePlanLatLng={this.state.prePlanLatLng}
                // editLocation={this.props.editLocation}
                handleEditLocationSuccess={this.handleEditLocationSuccess}
                // addLocation={this.props.addLocation}
                handleAddLocationSuccess={this.handleAddLocationSuccess}
              >
                <TabsContainer
                  currentTab={this.state.activeTabKey}
                  handleTabSelect={this.handleTabSelect}
                  selectedLocation={this.props.selectedLocation}
                  locationDataProps={locationDataProps}
                  selectALocation={this.selectALocation}
                  handleContextMenuItemSelect={this.handleContextMenuItemSelect}
                />
              </PrePlanFormContainer>
            </Box>
          </Flex>
        ) : !this.state.isAnnotationMode ? (
          <Center margin="0 auto">
            <Spinner
              thickness=".25rem"
              speed=".5s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Center>)
          : null}
        {this.state.isAnnotationMode ? (
          <AnnotatorContainer
            image={
              this.props.selectedLocation.images[this.state.currentImageIndex]
            }
            onSave={(json, svg) => this.saveAnnotation(json, svg)}
            onCancel={() => this.cancelAnnotation()}
            json={this.state.annotationJson}
          />
        ) : null}
        {this.renderContext()}
        <Modal
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          modal={this.state.modal}
        />
      </FlexWithBackgroundTheme>
    );
  }
}

const mapStateToProps = function(store) {
  return {
    customer: store.customer,
    user: store.user,
    smsList: store.customer.smsList
  };
};

function mapDispatchToProps(dispatch) {
  return {

    getCustomer: (showLoader, successCallback, errorCallback) =>
      customerAPI.getCustomer(dispatch, showLoader, successCallback, errorCallback),
    locateLocation: (locationKounter) => customerAPI.locateLocation(dispatch, locationKounter),
    getLocation: (locationLink, showLoader, successCallback, errorCallback) =>
      customerAPI.getLocation(locationLink, showLoader, successCallback, errorCallback),
    addHydrant: (hydrant, successCallback, errorCallback, hydrantsLink) =>
      hydrantAPI.addHydrant(dispatch, hydrant, successCallback, errorCallback, hydrantsLink),
    editHydrant: (id, hydrant, successCallback, errorCallback, hydrantsLink) =>
      hydrantAPI.editHydrant(dispatch, id, hydrant, successCallback, errorCallback, hydrantsLink),
    deleteAllHydrants: (successCallback, errorCallback, hydrantsLink) =>
      hydrantAPI.deleteAllHydrants(dispatch, successCallback, errorCallback, hydrantsLink),
    hydrantSelected: (hydrantId) => dispatch(hydrantSelected(hydrantId)),
    setSelectedHydrants: (hydrantIds) => dispatch(setSelectedHydrants(hydrantIds)),
    clearSelectedHydrants: () => dispatch(clearSelectedHydrants()),
    setDefaultState: () => dispatch(setCustomerDefaultState()),
    setCustomerCurrentLocation: (location) => dispatch(setCustomerCurrentLocation(location)),
    getPreplan: (locationCoords, storey, storeyBelow, successCallback, preplanLink) => locationAPI.getPreplan(locationCoords, storey, storeyBelow, successCallback, preplanLink),
    // editLocation: (id, location, successCallback, errCallback) => locationAPI.editLocation(dispatch, id, location, successCallback, errCallback),
    getPreplanByLocation: (location, successCallback, locationsLink) => locationAPI.getPreplanByLocation(location, successCallback, locationsLink),
    saveAnnotation: (locationId, imageId, annotationJson, annotationSVG, locationsLink) =>
      locationAPI.saveAnnotation(locationId, imageId, annotationJson, annotationSVG, locationsLink, dispatch),
    saveTags: (locationId, imageId, tags, locationsLink) => locationAPI.saveTags(locationId, imageId, tags, locationsLink, dispatch),
    getUser: () => UserAPI.getUser(dispatch),
    setNewMessage: (successCallback, errorCallback, connectCallback, closedCallback, customerId) =>
      customerAPI.eventSourceMessage(successCallback, errorCallback, connectCallback, closedCallback, dispatch, customerId),
    reorderImage: (locationId, images, locationsLink, successCallback, errorCallback) =>
      locationAPI.reorderImage(locationId, images, locationsLink, dispatch, successCallback, errorCallback),
    locationUpdated: (changes) => dispatch(locationUpdated(changes)),
    setSelectedLocationId: (locationId) => dispatch(setSelectedLocationId(locationId)),
    setLocationFilters: (filters) => dispatch(setLocationFilters(filters)),
    deleteLocation: (location) => dispatch(deleteLocation(location)),
    deleteHydrant: (hydrant) => dispatch(deleteHydrant(hydrant)),
  };
}


const DataWrapper = (props) => {
  const dispatch = useDispatch();
  const { lastFetch: lastLocFetch, lastPartnerFetch: lastPartnerLocFetch } = useSelector(state => state.locations);
  const { lastFetch: lastHydFetch, lastPartnerFetch: lastPartnerHydFetch } = useSelector(store => store.hydrants);
  const { selectedLocationId } = useSelector(v => v.locations);
  const { partners, links } = useSelector(v => v.customer);
  const selectedLocation = useSelector(state => selectLocationById(state, selectedLocationId));
  const allLocations = useSelector(selectAllLocations);
  const allHydrants = useSelector(selectAllHydrants);

  useEffect(() => {
    if (!lastLocFetch) {
      dispatch(fetchAllLocations());
    }
    if (!lastHydFetch) {
      dispatch(fetchAllHydrants());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //}, );

  useEffect(() => {
    if (!lastPartnerLocFetch) {
      const locsFetch = [];
      partners.forEach(partnerId => {
        locsFetch.push(dispatch(fetchPartnerLocations(partnerId)));
      });
      Promise.all(locsFetch).then(() => {
        console.info('Partner locations fetched');
      });
    }
    if (!lastPartnerHydFetch) {
      const hydsFetch = [];
      partners.forEach(partnerId => {
        hydsFetch.push(dispatch(fetchPartnerHydrants(partnerId)));
      });
      Promise.all(hydsFetch).then(() => {
        console.info('Partner hydrants fetched');
      });
    }
  }, [partners]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [partners, dispatch, lastPartnerHydFetch, lastPartnerLocFetch]);


  return (
    <MapContainer {...props} selectedLocation={selectedLocation} locations={allLocations} customerHydrants={allHydrants} customerLinks={links} />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(DataWrapper));
