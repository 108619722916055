import { omit, functions, isEqual } from 'lodash';

export function shouldNotUpdate(props, nextProps) {
  try {
    const [funcs, nextFuncs] = [functions(props), functions(nextProps)];
    const noPropChange = isEqual(omit(props, funcs), omit(nextProps, nextFuncs));
    const noFuncChange =
      funcs.length === nextFuncs.length &&
      funcs.every(fn => props[fn].toString() === nextProps[fn].toString());
    return noPropChange && noFuncChange;
  } catch (e) {
    console.error('caught error shouldNotUpdate', e);
    return true;
  }
}
