import React, { useState, useRef } from 'react';
import * as HydrantAPI from '../api/HydrantAPI';
import * as LocationAPI from '../api/LocationAPI';
import * as UserAPI from '../api/UserAPI';
import { useReactToPrint } from 'react-to-print';
import ComponentToPrint from '../components/common/PrintFile';
import { FlexWithBackgroundTheme } from './ThemeContainer';
import { Box, Text, Divider, Heading, useColorModeValue, Flex, Stack, useToast, Button, FormControl, FormErrorMessage, Link } from '@chakra-ui/react';
import { LinkButton, SubmitButton } from '../components/form/Button';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { RadioGroup } from '../components/form/FormWrapper';
import { FileUpload } from '../components/form/FileUploadComponent';
import { FaFile } from '@react-icons/all-files/fa/FaFile';
import { FaDownload } from '@react-icons/all-files/fa/FaDownload';
import { useSelector } from 'react-redux';

const UploadDataContainer = (/*props*/) => {

  return (
    <FlexWithBackgroundTheme justifyContent="start" alignItems="center" height={'calc(100vh - 4.25rem)'} overflow="auto" w="100%" flexDirection="column" paddingBottom="1.5rem" paddingX={'1.5rem'}>
      <UploadDataForm />
    </FlexWithBackgroundTheme>
  );

};

const UploadDataForm = (/*props*/) => {
  // const user = useSelector(state => state.user);
  const customer = useSelector(state => state.customer);
  const toast = useToast();
  const [uploadData, setUploadData] = useState([]);
  const { formState: { isSubmitting, errors }, ...methods } = useForm();
  // const labelStyle = {
  //   fontSize: 'xs',
  //   textTransform: 'uppercase',
  //   lineHeight: '1.25rem',
  //   color: 'gray.700'
  // };

  const dataOptions = {
    placeholder: 'Select a data type',
    name: 'uploadType',
    defaultValue: 'not-selected',
    options: [
      {
        value: 'hydrant',
        label: 'Hydrant'
      },
      {
        value: 'preplan',
        label: 'Preplan'
      },
      {
        value: 'user',
        label: 'User'
      }
    ]
  };

  const validateFiles = (value) => {
    if (value.length < 1) {
      return 'Files is required';
    }
    for (const file of Array.from(value)) {
      const fsMb = file.size / (1024 * 1024);
      const MAX_FILE_SIZE = 10;
      if (fsMb > MAX_FILE_SIZE) {
        return 'Max file size 10mb';
      }
    }
    return true;
  };

  const onProgress = (prog) => {
  };

  const onSubmit = (data) => {
    setUploadData([]);
    return new Promise(resolve => {
      const onSuccess = (message) => {
        setUploadData(message.split('\n'));
        toast({
          title: 'Success',
          position: 'top',
          description: 'Upload complete',
          status: 'success',
          duration: 9000,
          isClosable: true
        });
        resolve();
      };
      const onError = (message) => {
        setUploadData(message.split('\n'));
        toast({
          title: 'Error',
          position: 'top',
          description: 'Upload failed.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
        resolve();
      };

      if (!data.uploadType) {
        onError('');
      }

      const formData = new FormData();
      if (data.file_[0]) {
        formData.append('file', data.file_[0]);
        if (data.uploadType === 'hydrant') {
          HydrantAPI.uploadHydrant(formData, onSuccess, onError, onProgress, customer.links && customer.links.find(x => x.rel === 'hydrants'));
        } else if (data.uploadType === 'preplan') {
          LocationAPI.uploadPrePlan(formData, onSuccess, onError, onProgress, customer.links && customer.links.find(x => x.rel === 'locations'));
        } else if (data.uploadType === 'user') {
          UserAPI.uploadUser(formData, onSuccess, onError, onProgress);
        }
      } else {
        resolve();
      }
    });

  };

  const background = useColorModeValue('white', 'gray.900');

  return (
    <Box>
      <Box marginTop="2rem" width="30rem" bg={useColorModeValue('white', 'gray.900')} p="1rem" borderWidth="1px" borderRadius="0.5rem" boxShadow="md">
        <Flex align="center" justify="space-between">
          <Heading as="h4" size="md">Upload Data</Heading>
        </Flex>
        <Divider my="0.5rem" />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing="1rem">
              <RadioGroup groupName={'Data Type'} direction={'row'} {...dataOptions} />
              <FormControl isInvalid={!!errors.file_} isRequired>
                <FileUpload
                  accept={'.csv,text/csv'}
                  multiple
                  register={methods.register('file_', { validate: validateFiles })}
                >
                  <Button variant="outline" leftIcon={<FaFile />}>
                    <FileName />
                  </Button>
                </FileUpload>
                <FormErrorMessage>
                  {errors.file_ && errors.file_.message}
                </FormErrorMessage>
              </FormControl>
              <Divider />
              <UserGuides />

              <Flex justifyContent="space-between" alignItems="center">
                <SubmitButton isLoading={isSubmitting} colorScheme="blue" type="submit">
                  Upload
                </SubmitButton>
                <LinkButton onClick={() => methods.reset()}>Cancel</LinkButton>
              </Flex>
            </Stack>
          </form>
        </FormProvider>
      </Box>
      {uploadData.length > 0 && (
        <Box marginTop="2rem" width="30rem" bg={background} p="1rem" borderWidth="1px" borderRadius="0.5rem" boxShadow="md">
          <ResultsTable data={uploadData} header={'Results'} />
        </Box>
      )}
    </Box>
  );
};

const FileName = () => {
  const file = useWatch({ name: 'file_' });
  const selectedFile = file ? file[0] : null;
  return (
    <Text>{selectedFile ? selectedFile.name : 'Select File'}</Text>
  );
};

const UserGuides = (props) => {
  const uploadType = useWatch({ name: 'uploadType' });
  return (<React.Fragment>
    {uploadType === 'hydrant' &&
      <Stack spacing="0.25rem">
        <Link display="flex" alignItems="baseline" isExternal href="/images/HydrantsSample.csv">Download: Sample input file (Hydrant)&nbsp;<FaDownload /></Link>
        <Link display="flex" alignItems="baseline" isExternal href="/images/HydrantsSampleData.csv">Download: Sample input file (with sample data) (Hydrant)&nbsp;<FaDownload /></Link>
        <Link display="flex" alignItems="baseline" isExternal href="/images/HydrantsManual.pdf">Download: Manual (Hydrant)&nbsp;<FaDownload /></Link>
      </Stack>
    }
    {uploadType === 'preplan' &&
      <Stack spacing="0.25rem">

        <Link display="flex" alignItems="baseline" isExternal href="/images/PreplanSample.csv">Download: Sample input file (Preplan)&nbsp;<FaDownload /></Link>
        <Link display="flex" alignItems="baseline" isExternal href="/images/PreplanSampleData.csv">Download: Sample input file (with sample data) (Preplan)&nbsp;<FaDownload /></Link>
        <Link display="flex" alignItems="baseline" isExternal href="/images/PreplanManual.pdf">Download: Manual (Preplan)&nbsp;<FaDownload /></Link>
      </Stack>
    }
    {uploadType === 'user' &&
      <Stack spacing="0.25rem">
        <Link display="flex" alignItems="baseline" isExternal href="/images/UserSample.csv">Download: Sample input file (User)</Link>
        <Link display="flex" alignItems="baseline" isExternal href="/images/UserSampleData.csv">Download: input file (with sample data) (User)&nbsp;<FaDownload /></Link>
        <Link display="flex" alignItems="baseline" isExternal href="/images/UserManual.pdf">Download: Manual (User)&nbsp;<FaDownload /></Link>
      </Stack>
    }
  </React.Fragment>
  );
};


const ResultsTable = (props) => {
  // const [ref, setRef] = useState(null);
  const contentRef = useRef();
  const handlePrint = useReactToPrint({ contentRef });

  return (
    <Box>
      {props.data &&
        <Flex justifyContent="space-between" alignItems="center">
          <Heading as="h4" size="sm">Log</Heading>
          <Button onClick={() => {
            // console.log({ contentRef, handlePrint });
            handlePrint();
          }} variant="text" color="blue.400" fontWeight="400">Print this out</Button>
        </Flex>
      }
      <ComponentToPrint
        ref={contentRef}
        data={props.data}
        header={props.header}
      />
    </Box>
  );
};

export default UploadDataContainer;
