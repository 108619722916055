import React, { useEffect, useState } from 'react';
import { Box, Divider, Heading, useColorModeValue, Flex, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { PrimaryButton } from '../form/Button';
import { FaUserPlus } from '@react-icons/all-files/fa/FaUserPlus';
import { DataTable } from '../dashboard/DataTable';
import { useDispatch } from 'react-redux';
import { selectAllUnits, fetchAllUnits } from '../../features/departmentUnits/departmentUnitsSlice';
import { AddUnitModal } from './AddUnitModal';
import { EditUnitModal } from './EditUnitModal';
import { DeleteUnitModal } from './DeleteUnitModal';


export const AccountUnitsCard = (props) => {
  const units = useSelector(selectAllUnits);
  const user = useSelector(state => state.user);
  const customer = useSelector(state => state.customer);
  const dispatch = useDispatch();
  const [isAdmin, setIsAdmin] = useState(false);
  const [psapIdAvailable, setPsapIdAvailable] = useState(false);

  const canAdd = isAdmin && psapIdAvailable;
  const canEdit = isAdmin;
  const canDelete = isAdmin;

  useEffect(() => {
    dispatch(fetchAllUnits());
  }, [dispatch]);

  useEffect(() => {
    if (user && user.role === 'ADMIN') {
      setIsAdmin(true);
    }
  }, [user]);

  useEffect(() => {
    if (customer && customer.psapId) {
      setPsapIdAvailable(true);
    }
  }, [customer]);

  const [unitModalOpen, setUnitModalOpen] = useState(false);

  const unitColumns = [
    {
      Header: 'Unit',
      accessor: 'unit',
    },
    {
      Header: 'Description',
      accessor: 'desc',
    },
    {
      Header: 'Created On',
      accessor: 'createdOn',
    },
    {
      Header: 'Modified On',
      accessor: 'modifiedOn',
    },
    {
      accessor: 'actions',
      maxWidth: 100,
      Cell: ({ value, row }) => {
        return (
          <HStack justifyContent="end" width="100%">
            <EditUnitModal isDisabled={!canEdit} unit={row.original} units={units} />
            <DeleteUnitModal isDisabled={!canDelete} unit={row.original} />
          </HStack>
        );
      }
    }
  ];

  return (
    <Box marginTop="2rem" minWidth="32rem" bg={useColorModeValue('white', 'gray.900')} p="1rem" borderWidth="1px" borderRadius="0.5rem" boxShadow="md">
      <Flex px="1rem" align="center" justify="space-between">
        <Heading as="h4" size="md">Units</Heading>
        <PrimaryButton isDisabled={!canAdd} onClick={() => setUnitModalOpen(true)} aria-label="Add Unit" leftIcon={<FaUserPlus />}>Add Unit</PrimaryButton>
      </Flex>
      <Divider my="0.5rem" />
      <DataTable
        globalSearch
        columns={unitColumns}
        data={units}
        initialSortBy={[
          {
            id: 'unit',
            desc: false
          }
        ]}
      />
      <AddUnitModal isOpen={unitModalOpen} closeModal={() => setUnitModalOpen(false)} units={units} />
    </Box>
  );
};
