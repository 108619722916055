import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import * as departmentUnitAPI from '../../api/DepartmentUnitAPI';


export const fetchAllUnits = createAsyncThunk('departmentUnits/fetchAllUnits', async() => {
  const response = await departmentUnitAPI.getAllUnits();
  return response.data;
});

// export const fetchUnit = createAsyncThunk('departmentUnits/fetchUnit', async (unitId) => {
//   const response = await departmentUnitAPI.getUnit(unitId);
//   return response.data;
// });

export const addUnit = createAsyncThunk('departmentUnits/addUnit', async(data, thunkAPI) => {
  const { customer } = thunkAPI.getState();
  data.slug = customer && customer.slug;
  data.customerId = customer && customer.customerId;

  const response = await departmentUnitAPI.addUnit(data);
  if (response.data && response.data.errors) return thunkAPI.rejectWithValue(response.data.errors);
  return response.data && response.data.unit;
});

export const updateUnit = createAsyncThunk('departmentUnits/updateUnit', async({ unitId, data }) => {
  const response = await departmentUnitAPI.editUnit(unitId, data);
  return response.data && response.data.unit;
});

export const deleteUnit = createAsyncThunk('departmentUnits/deleteUnit', async(unitId) => {
  const response = await departmentUnitAPI.deleteUnit(unitId);
  return response.data && response.data.id;
});


const departmentUnitsAdapter = createEntityAdapter();

const departmentUnitsSlice = createSlice({
  name: 'departmentUnits',
  initialState: departmentUnitsAdapter.getInitialState(),
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllUnits.fulfilled, departmentUnitsAdapter.setMany);
    builder.addCase(updateUnit.fulfilled, departmentUnitsAdapter.setOne);
    builder.addCase(addUnit.fulfilled, departmentUnitsAdapter.setOne);
    builder.addCase(deleteUnit.fulfilled, departmentUnitsAdapter.removeOne);
  },
});

const { /*actions,*/ reducer } = departmentUnitsSlice;

export const {
  selectAll: selectAllUnits,
} = departmentUnitsAdapter.getSelectors(state => state.departmentUnits);

export default reducer;
