import React, { useState, useEffect } from 'react';
import CreateDepartmentForm from '../components/CreateDepartment/CreateDepartmentForm';
import * as sessionAPI from '../api/SessionAPI';
import { Center, Spinner, Flex, useToast } from '@chakra-ui/react';
import { ExternalContainer } from './ExternalContainer';
import { useNavigate } from "react-router-dom";

const CreateDepartmentContainer = (props) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [validationStatus, setValidationStatus] = useState("loading");

    const getTokenValue = (name) => {
        const url = window.location.href;
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    useEffect(() => {
        sessionAPI.validateCreateDepartmentLinkPart(getTokenValue('linkPart'),
            () => {
                setValidationStatus("success");
            },
            () => {
                toast({
                    title: 'Error',
                    position: 'top',
                    description: 'Registration link is invalid, please request a new link to set up your account.',
                    status: 'error',
                    duration: null,
                    isClosable: false
                });
                setValidationStatus("fail");
            }
        );
    }, [toast]);

    return (
        <ExternalContainer>
            <Flex flexDirection="column" justifyContent="center">
                {validationStatus === "loading" && <Center height="100vh" margin="auto auto">
                    <Spinner
                        thickness=".25rem"
                        speed=".5s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                    />
                </Center>}
                {validationStatus === "success" && <CreateDepartmentForm
                    linkPart={getTokenValue('linkPart')}
                    toggleModal={() => { navigate('/login'); }}
                />}
            </Flex>
        </ExternalContainer>
    );
};

export default CreateDepartmentContainer;
