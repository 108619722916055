import React from 'react';
import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { FaExclamationCircle } from '@react-icons/all-files/fa/FaExclamationCircle';


export const HighHazardTag = ({ description, ...props }) => {

  return (
    <Tag colorScheme="orange" size={props.tagSize ? props.tagSize : 'md'}>
      <TagLeftIcon as={FaExclamationCircle} />
      <TagLabel fontWeight={600}>HIGH HAZARD</TagLabel>
    </Tag>
  );
};
