import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalOverlay, ModalCloseButton, ModalFooter, ModalContent, useDisclosure } from '@chakra-ui/react';


export const ModalComponent = (props) => {
  const { isOpen, onClose } = useDisclosure({
    isOpen: props.showModal,
    onClose: props.toggleModal
  });


  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {props.modal.heading}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {props.modal.body}
        </ModalBody>
        {props.modal.footer && (
          <ModalFooter>
            {props.modal.footer}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal >
  );
};

export default ModalComponent;
