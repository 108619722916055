import * as React from 'react';
import { Badge } from '@chakra-ui/react';


export const AssignmentBadge = (props) => {
  const badgeStyle = {

  };
  switch (props.status) {
    case 'OPEN':
      return <Badge {...badgeStyle} variant="outline" colorScheme="gray">Open</Badge>;
    case 'IN_PROGRESS':
      return <Badge {...badgeStyle} variant="outline" colorScheme="blue">In Progress</Badge>;
    case 'COMPLETE':
      return <Badge {...badgeStyle} variant="outline" colorScheme="green">Complete</Badge>;
    case 'NEEDS_FEEDBACK':
      return <Badge {...badgeStyle} variant="outline" colorScheme="orange">Needs Feedback</Badge>;
    case 'CANCELLED':
      return <Badge {...badgeStyle} colorScheme="red">Cancelled</Badge>;
    default:
      return <Badge {...badgeStyle} variant="outline" colorScheme="blue">Open</Badge>;
  }
};
