import { Box, Stack, Divider, HStack, useDisclosure } from '@chakra-ui/react';
import { OutlineButton, PrimaryButton } from '../form/Button';
import Modal from '../common/ModalComponent';
import React from 'react';


export const ConfirmModal = (props) => {
  const { isOpen, onClose } = useDisclosure({
    isOpen: props.isOpen,
    onClose: props.onClose
  });

  return (
    <Modal
      showModal={isOpen}
      onClose={onClose}
      modal={{
        body: (
          <Box>
            <Stack spacing="1rem">
              <div>{props.children}</div>
              <Divider />
              <HStack justifyContent="end" spacing="1rem">
                <OutlineButton onClick={props.onDecline} width="8rem">No</OutlineButton>
                <PrimaryButton onClick={props.onConfirm} width="8rem">Yes</PrimaryButton>
              </HStack>
            </Stack>
          </Box>
        )
      }}
    />
  );
};
