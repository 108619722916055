import React, { useState } from 'react';
import {
  Flex,
  Stack,
  Text,
  Box,
  Heading,
  Divider,
  useToast,
  useColorModeValue
} from '@chakra-ui/react';

import { FormProvider, useForm } from 'react-hook-form';
import { LinkButton, SubmitButton } from '../../components/form/Button';
import FormComponent from '../../components/form/FormComponent';


export const NotificationSettingsCard = ({ config, onSubmit }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Box marginTop="2rem" maxWidth="32rem" minWidth="24rem" bg={useColorModeValue('white', 'gray.900')} p="1rem" borderWidth="1px" borderRadius="0.5rem" boxShadow="md">
      <Flex align="center" justify="space-between">
        <Heading as="h4" size="md">Assignments Notification Settings</Heading>
        <LinkButton onClick={() => setIsEditing(!isEditing)}>{isEditing ? 'Cancel' : 'Edit'}</LinkButton>
      </Flex>
      <Divider my="0.5rem" />
      {!config && <Text>Loading...</Text>}
      {config && (
        isEditing ? <NotificationSettingsForm config={config} setIsEditing={setIsEditing} onSubmit={onSubmit}/> : <NotificationSettingsInfo config={config} />
      )}
    </Box>
  );
};

const NotificationSettingsInfo = ({ config: { assignmentDeliveryEmail, assignmentDeliveryPushNotification }}) => {
  const receiveEmailString = (assignmentDeliveryEmail === 'true' ? 'Yes' : assignmentDeliveryEmail === 'false' ? 'No' : 'Not Set');
  const receivePushNotificationString = (assignmentDeliveryPushNotification === 'true' ? 'Yes' : assignmentDeliveryPushNotification === 'false' ? 'No' : 'Not Set');
  const labelStyle = {
    fontSize: 'xs',
    textTransform: 'uppercase',
    lineHeight: '1.25rem',
    color: 'gray.700'
  };

  return (
    <Box>
      <Stack spacing="0.5rem">
        <Stack spacing="0">
          <Text {...labelStyle}>Receive Email</Text>
          <Text fontWeight="600">{receiveEmailString}</Text>
        </Stack>
        <Divider />
        <Stack spacing="0">
          <Text {...labelStyle}>Receive Push Notification</Text>
          <Text fontWeight="600">{receivePushNotificationString}</Text>
        </Stack>
      </Stack>
    </Box>
  );
};

const NotificationSettingsForm = ({ setIsEditing, config: { assignmentDeliveryEmail, assignmentDeliveryPushNotification }, ...props }) => {
  const methods = useForm();
  const toast = useToast();

  const onSubmit = async(values) => {
    const data = {
      assignmentDeliveryEmail: values.assignmentDeliveryEmail ? 'true' : 'false',
      assignmentDeliveryPushNotification: values.assignmentDeliveryPushNotification ? 'true' : 'false',
    };
    
    const result = await props.onSubmit(data);

    if (result) {
      toast({
        title: 'Success',
        position: 'top',
        description: 'Notification settings updated.',
        status: 'success',
        duration: 2500,
        isClosable: true
      });
      setIsEditing(false);
    } else {
      toast({
        title: 'Error',
        position: 'top',
        description: 'Failed to update notification settings. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  const notificationSettingsFormData = {
    assignmentDeliveryEmail: assignmentDeliveryEmail === 'true', 
    assignmentDeliveryPushNotification: assignmentDeliveryPushNotification === 'true'
  };

  const formFields = [
    {
      id: 'assignmentDeliveryEmail',
      name: 'assignmentDeliveryEmail',
      type: 'checkbox',
      label: 'Receive Email',
    },
    {
      id: 'assignmentDeliveryPushNotification',
      name: 'assignmentDeliveryPushNotification',
      type: 'checkbox',
      label: 'Receive Push Notification',
    }
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormComponent formFields={formFields} data={notificationSettingsFormData} />
        <SubmitButton isLoading={methods.formState.isSubmitting} mt={4} colorScheme="blue" type="submit">
          Save
        </SubmitButton>
      </form>
    </FormProvider>
  );
};
