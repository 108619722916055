import React, { Component } from 'react';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { FaArrowLeft } from '@react-icons/all-files/fa/FaArrowLeft';
import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight';
import { FaPencilAlt } from '@react-icons/all-files/fa/FaPencilAlt';
import { FaTag } from '@react-icons/all-files/fa/FaTag';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import { FaFilePdf } from '@react-icons/all-files/fa/FaFilePdf';

import GracefulImage from '../../helpers/GracefulImage';
export default class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      error: false
    };
    this.handleImageLoadError = this.handleImageLoadError.bind(this);
  }

  handleImageLoadError(err) {
    this.setState({ error: true });
  }

  render() {
    const iconStyles = {
      cursor: 'pointer',
      height: '1.5rem',
      width: '1.5rem',
      marginY: '0.5rem',
      marginX: '0.75rem'
    };
    return (
      <span
        className={this.props.supportsTouch ? "imageContainer touch-screen" : "imageContainer"}
        onMouseEnter={e => this.setState({ hover: true })}
        onMouseLeave={e => this.setState({ hover: false })}
        onClick={this.props.onImageClick}
      >
        <div className="image-wrapper">
          <RetryImage src={this.props.src} isPDF={this.props.isPDF} pdfFileName={this.props.pdfFileName} />
          <span
            // style={{ visibility: this.state.hover ? 'visible' : 'hidden' }}
          >
            {!this.props.extremeleft && (this.props.canEditImage || this.props.isPDF) && !this.props.isFIO && !this.props.isCommand && this.props.isMine &&
              <Icon
                {...iconStyles}
                as={FaArrowLeft}
                onClick={e => {
                  this.props.onLeftClick();
                  e.stopPropagation();
                }}
              />
            }
            {!this.props.extremeright && (this.props.canEditImage || this.props.isPDF) && !this.props.isFIO && !this.props.isCommand && this.props.isMine &&
              <Icon
                {...iconStyles}
                as={FaArrowRight}
                onClick={e => {
                  this.props.onRightClick();
                  e.stopPropagation();
                }}
              />
            }
            {
              !this.state.error && !this.props.reorder && this.props.canEditImage && !this.props.isFIO && !this.props.isCommand && this.props.isMine &&
              <Icon
                {...iconStyles}
                as={FaPencilAlt}
                onClick={e => {
                  this.props.onAnnotationClick();
                  e.stopPropagation();
                }}
              />
            }
            {
              !this.props.reorder && (this.props.canEditImage || this.props.isPDF) && !this.props.isFIO && !this.props.isCommand && this.props.isMine &&
              <Icon
                {...iconStyles}
                as={FaTag}
                onClick={e => {
                  this.props.onTaggingClick();
                  e.stopPropagation();
                }}
              />
            }
            {
              !this.props.reorder && (this.props.canEditImage || this.props.isPDF) && !this.props.isFIO && !this.props.isCommand && this.props.isMine &&
              <Icon
                {...iconStyles}
                as={FaTimes}
                onClick={e => {
                  this.props.onDeleteClick();
                  e.stopPropagation();
                }}
              />
            }
          </span>
        </div>
      </span>
    );
  }
}

// @todo quick workaround for retry image. to refactor.
const RetryImage = (props) => {

  return (
    <React.Fragment>
      {props.isPDF ? (
        <Flex
          position="absolute"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
          flexDir="column"
          overflow="hidden"
         >
           <Icon as={FaFilePdf} height="4rem" width="4rem" color="red.400" />
           <Text fontSize="xs">
            {props.pdfFileName}
          </Text>
         </Flex>

      ) : (<GracefulImage
        src={props.src}
        noLazyLoad
        height={200}
        placeholder={props.src}
      />)}
    </React.Fragment>
  );

};
