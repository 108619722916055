// import * as AJAXUtil from '../../api/AJAXUtil';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import * as locationAPI from '../../api/LocationAPI';
import { formatLocationDate, isAfterSubDays, isBeforeSubDays } from '../../helpers/Date';
// Setting up according to redux-toolkit best practices
// Reference: https://redux-toolkit.js.org/usage/usage-guide

// interface ThunkAPI {
//   dispatch: Function
//   getState: Function
//   extra?: any
//   requestId: string
//   signal: AbortSignal
// }

// Thunks
export const fetchAllLocations = createAsyncThunk(
  'locations/fetchAllLocations',
  async(data, thunkAPI) => {
    const { customer } = thunkAPI.getState();
    const locationsLink = customer.links && customer.links.find(x => x.rel === 'locations');

    const response = await locationAPI.fetchAllLocations(locationsLink);
    return response.data;
  }
);

export const addLocation = createAsyncThunk(
  'locations/addLocation',
  async(location, thunkAPI) => {
    const { customer } = thunkAPI.getState();
    const locationsLink = customer.links && customer.links.find(x => x.rel === 'locations');

    const response = await locationAPI.addLocation(location, locationsLink);
    return response.data;
  }
);

export const editLocation = createAsyncThunk(
  'locations/editLocation',
  async(form, thunkAPI) => {
    const { customer } = thunkAPI.getState();
    const locationsLink = customer.links && customer.links.find(x => x.rel === 'locations');

    const response = await locationAPI.editLocation(form.id, form.data, locationsLink);
    return response.data;
  }
);

export const deleteLocation = createAsyncThunk(
  'locations/deleteLocation',
  async(form, thunkAPI) => {
    const { customer } = thunkAPI.getState();
    const locationsLink = customer.links && customer.links.find(x => x.rel === 'locations');

    const response = await locationAPI.deleteLocation(form, locationsLink);
    return { ...response.data, id: form.id };
  }
);
// Partners
export const fetchPartnerLocations = createAsyncThunk(
  'locations/fetchPartnerLocations',
  async(partnerId, thunkAPI) => {
    const { customer } = thunkAPI.getState();
    const locationsLink = customer.links && customer.links.find(x => x.rel === 'locations');

    const response = await locationAPI.fetchPartnerLocations(partnerId, locationsLink);
    return response.data;
  }
);


// Adapter & State
export const locationsAdapter = createEntityAdapter();
const initialState = locationsAdapter.getInitialState({
  loading: 'idle',
  partnerLoading: 'idle',
  error: null,
  lastFetch: null,
  lastPartnerFetch: null,
  selectedLocationId: null,
  filters: [],
  filteredLocationIds: []
});

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    locationsLoading: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    locationsReceived: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.entities = action.payload;
      }
    },
    locationUpdated: locationsAdapter.updateOne,
    setSelectedLocationId: (state, action) => {
      state.selectedLocationId = action.payload;
    },
    addLocationImage: (state, action) => {
      const location = state.entities[action.payload.id];
      const locationImages = location.images ? location.images : [];
      location.images = [...locationImages, action.payload.image];
    },
    setLocationFilters: (state, action) => {
      const locs = locationsAdapter.getSelectors().selectAll(state);
      state.filteredLocationIds = getFilteredLocations(locs, action.payload);
    },
    resetLastPartnerFetch: (state, action) => {
      state.lastPartnerFetch = null;
    },
    filterPartnerLocations: (state, action) => {
      // given a new list of partner ids, filter locations to make sure they are in the list
      const excludedPartners = locationsAdapter.getSelectors().selectAll(state).filter(loc => !loc.isMine).filter(loc => !action.payload.includes(loc.customerId));
      locationsAdapter.removeMany(state, excludedPartners.map(loc => loc.id));
    }
  },
  extraReducers: (builder) => {
    // @todo consider using entityAdapter
    // Add reducers for additional action types here, and handle loading state as needed
    // fetchAllLocations
    builder
      .addCase(fetchAllLocations.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(fetchAllLocations.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.lastFetch = Date.now();
        if (action.payload.data && action.payload.data.length > 0) {
          locationsAdapter.upsertMany(state, action.payload.data.map(loc => ({ ...loc, isMine: true })));
        }
      })
      .addCase(fetchAllLocations.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
          state.lastFetch = Date.now();
        }
      });
    // addLocation
    builder
      .addCase(addLocation.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(addLocation.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          locationsAdapter.addOne(state, { ...action.payload, isMine: true });
        }
      })
      .addCase(addLocation.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // editLocation
    builder
      .addCase(editLocation.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(editLocation.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          locationsAdapter.setOne(state,
            {...action.payload, isMine: true}
          );
        }
      })
      .addCase(editLocation.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // deleteLocation
    builder
      .addCase(deleteLocation.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
        }
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          locationsAdapter.removeOne(state, action.payload.id);
        }
      })
      .addCase(deleteLocation.rejected, (state, action) => {
        if (
          state.loading === 'pending'
        ) {
          state.loading = 'idle';
          state.error = action.error;
        }
      });
    // fetchPartnerLocations
    builder
      .addCase(fetchPartnerLocations.pending, (state, action) => {
        if (state.partnerLoading === 'idle') {
          state.partnerLoading = 'pending';
        }
      })
      .addCase(fetchPartnerLocations.fulfilled, (state, action) => {
          state.partnerLoading = 'idle';
        if (action.payload.data) {
          locationsAdapter.upsertMany(state, action.payload.data.map(loc => ({ ...loc, isMine: false })));
          state.lastPartnerFetch = Date.now();
        }
      })
      .addCase(fetchPartnerLocations.rejected, (state, action) => {
        if (
          state.partnerLoading === 'pending'
        ) {
          state.partnerLoading = 'idle';
          state.error = action.error;
        }
      });
  }
});


// Selector?
const getFilteredLocations = (locs, filters) => {
  // @todo refactor: this is from previous reducer
  const locationsFilterData = filters;
  const locationFilterApplied = [];
  let prevLocationsArr = locs;
  for (let knt1 = 0; knt1 < locationsFilterData.length; knt1++) {
    const eachFieldVal = locationsFilterData[knt1].value;
    const eachFieldName = locationsFilterData[knt1].field;
    if (eachFieldName === 'excludePartner') {
      prevLocationsArr = prevLocationsArr.filter(x => x.isMine);
      locationFilterApplied.push(' Exclude partner');
    } else if (eachFieldName === 'selectBuildingInfo') {
      if (eachFieldVal === 'Present') {
        locationFilterApplied.push(`Building info ${eachFieldVal}`);
        prevLocationsArr = prevLocationsArr.filter(x => (x.building && (x.building.occupancyType || x.building.constructionType || x.building.roofType || x.building.roofConstruction || x.building.roofMaterial || x.building.sprinklered || x.building.standPipe || x.building.fireAlarm || x.building.normalPopulation || x.building.hoursOfOperation || x.building.ownerContact || x.building.ownerPhone)));
      } else if (eachFieldVal === 'Not present') {
        prevLocationsArr = prevLocationsArr.filter(x => (!x.building || (!x.building.occupancyType && !x.building.constructionType && !x.building.roofType && !x.building.roofConstruction && !x.building.roofMaterial && !x.building.sprinklered && !x.building.standPipe && !x.building.fireAlarm && !x.building.normalPopulation && !x.building.hoursOfOperation && !x.building.ownerContact && !x.building.ownerPhone)));
        locationFilterApplied.push(`Building info ${eachFieldVal}`);
      }
    } else if (eachFieldName === 'commercial') {
      prevLocationsArr = prevLocationsArr.filter(x => x.building && ((x.building.occupancyType === 'Business / Mercantile') || (x.building.occupancyType === 'Industrial')));
      locationFilterApplied.push(' Commercial');
    } else if (eachFieldName === 'vacant') {
      prevLocationsArr = prevLocationsArr.filter(x => x.building && (x.building.normalPopulation === 'Vacant'));
      locationFilterApplied.push(' Vacant');
    } else if (eachFieldName === 'sprinklered') {
      prevLocationsArr = prevLocationsArr.filter(x => x.building && ((x.building.sprinklered === 'Dry System') || (x.building.sprinklered === 'Wet System') || (x.building.sprinklered === 'Both')));
      locationFilterApplied.push(' Sprinklered');
    } else if (eachFieldName === 'nonSprinklered') {
      prevLocationsArr = prevLocationsArr.filter(x => x.building && ((!x.building.sprinklered) || (x.building.sprinklered && ((x.building.sprinklered === '') || (x.building.sprinklered === 'None')))));
      locationFilterApplied.push(' Non sprinklered');
    } else if (eachFieldName === 'withPictures') {
      prevLocationsArr = prevLocationsArr.filter(x => x.imageLength > 0);
      locationFilterApplied.push(' With pictures');
    } else if (eachFieldName === 'withoutPictures') {
      prevLocationsArr = prevLocationsArr.filter(x => x.imageLength <= 0);
      locationFilterApplied.push(' Without pictures');
    } else if (eachFieldName === 'trussRoof') {
      prevLocationsArr = prevLocationsArr.filter(x => x.building && (x.building.roofType === 'Bowstring Truss' || x.building.roofConstruction === 'Steel Truss - Open Web' || x.building.roofConstruction === 'Wood Truss - Closed Web' || x.building.roofConstruction === 'Wood Truss - Open Web'));
      locationFilterApplied.push(' Truss Roof');
    } else if (eachFieldName === 'standpipes') {
      prevLocationsArr = prevLocationsArr.filter(x => x.building && x.building.standPipe === 'Yes');
      locationFilterApplied.push(' Standpipes');
    } else if (eachFieldName === 'fireAlarm') {
      prevLocationsArr = prevLocationsArr.filter(x => x.building && x.building.fireAlarm === 'Yes');
      locationFilterApplied.push(' Fire Alarms');
    } else if (eachFieldName === 'multiFamily') {
      prevLocationsArr = prevLocationsArr.filter(x => x.building && x.building.occupancyType === 'Multi-Family');
      locationFilterApplied.push(' Multi-family');
    } else if (eachFieldName === 'special') {
      prevLocationsArr = prevLocationsArr.filter(x => x.building && x.building.occupancyType === 'Special Structures');
      locationFilterApplied.push(' Special Structures');
    } else if (eachFieldName === 'selectPreplanAge') {

      if (eachFieldVal === 'withinThirtyDays') {
        prevLocationsArr = prevLocationsArr.filter(x => x.building && isAfterSubDays(formatLocationDate(x.building.originalPrePlan), 30));
        locationFilterApplied.push(' Pre-plan within last 30 days');
      } else if (eachFieldVal === 'olderThanAYear') {
        prevLocationsArr = prevLocationsArr.filter(x => x.building && isBeforeSubDays(formatLocationDate(x.building.originalPrePlan), 365));
        locationFilterApplied.push(' Pre-plan older than a year');
      }
    } else if (eachFieldName === 'roofAreaMin') {
      prevLocationsArr = prevLocationsArr.filter(x => x.roofArea >= eachFieldVal);
      locationFilterApplied.push(` Area minimum  ${eachFieldVal}`);
    } else if (eachFieldName === 'roofAreaMax') {
      prevLocationsArr = prevLocationsArr.filter(x => x.roofArea <= eachFieldVal);
      locationFilterApplied.push(` Area maximum ${eachFieldVal}`);
    }
  }
  const filteredIds = prevLocationsArr.map(x => x.id);
  return filteredIds;
};


const { actions, reducer } = locationsSlice;

export const {
  locationUpdated,
  setSelectedLocationId,
  addLocationImage,
  setLocationFilters,
  resetLastPartnerFetch,
  filterPartnerLocations
} = actions;

export default reducer;


export const {
  selectById: selectLocationById,
  selectAll: selectAllLocations,
  selectTotal: selectTotalLocations,
} = locationsAdapter.getSelectors(state => state.locations);
