import {parseISO} from 'date-fns';

/**
 * Returns all assignments for a location sorted by most recent
 * @param assignments
 * @param locationId
 * @returns {*}
 */
export function getLocationAssignments(assignments, locationId) {
    return assignments
        .filter(assignment => assignment.locationId === locationId)
        .sort((a, b) => {
            return parseISO(b.modifiedOn).getTime() - parseISO(a.modifiedOn).getTime(); // descending order - latest first
        })
    ;
}

/**
 * Returns the latest assignment record or null
 * @param assignments
 * @param locationId
 * @returns {*|null}
 */
export function getLatestLocationAssignments(assignments, locationId) {
    return assignments
        .filter(assignment => assignment.locationId === locationId)
        .sort((a, b) => {
            return parseISO(b.modifiedOn).getTime() - parseISO(a.modifiedOn).getTime(); // descending order - latest first
        })
    [0] || null;
}
