import React from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';

export const FlexWithBackgroundTheme = (props) => (
  <Flex
  borderTop="1px"
  borderTopColor={useColorModeValue('gray.200', 'gray.700')}
  {...props}
  >
    {props.children}
  </Flex>
);
