// import './wdyr'; // why-did-you-render debug tool
import React from 'react';
import ReactDOM from 'react-dom/client';
import { configureStore, history } from './store/configureStore';
import Root from './containers/Root';
import ErrorBoundary from './containers/ErrorBoundary';
import './styles/app.scss';
import {ColorModeScript} from '@chakra-ui/react';
import theme from './styles/theme/default';
import reportWebVitals from './reportWebVitals';
import { pdfjs } from 'react-pdf';

// Import this now, so it's cached for the duration of the app
// noinspection ES6UnusedImports
import config from './config.js'; // eslint-disable-line no-unused-vars

// Config PDF worker
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.mjs';

export const store = configureStore();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ErrorBoundary>
        <ColorModeScript storageKey="fmsp-cm" initialColorMode={theme.config.initialColorMode} />
        <Root store={store} history={history} />
    </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
