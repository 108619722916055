export const formFields = [
  {
    id: 'unit',
    name: 'unit',
    type: 'text',
    label: 'Unit'
  },
  {
    id: 'desc',
    name: 'description',
    type: 'textarea',
    label: 'Description',
  }
];

export const checkDuplicateUnits = (allUnits, newUnit) => {
  if (allUnits && allUnits.length) {
    for (let unit of allUnits) {
      if (unit.unit === newUnit) {
        return true;
      }
      if (!(unit.unit) && !(newUnit)) {
        return true;
      }
    }
  }
  return false;
};