import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  chakra,
  Text,
  HStack,
  Heading,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Box,
  Button,
  useTab,
  Flex,
  Divider,
  Select,
  Checkbox,
  useStyleConfig
} from '@chakra-ui/react';
import { uniq } from 'lodash';
import { DateRangePicker, NumberSlider } from '../../components/form/FormWrapper';
import { assignmentFieldValues } from '../../models/Assignment';
import {
  setDashboardFilterValues,
  setDashboardColumnsVisibility,
  resetFiltersAndColumns,
  getUIConfig
} from '../../features/user/userSlice';

// Custom tab component for styling current selected tab
const StyledTab = chakra('button', { themeKey: 'Tabs.Tab' });
// const StyledTab = <Button themeKey="Tabs.Tab" />;

const TabHeader = React.forwardRef((props, ref) => {
  const tabProps = useTab({ ...props, ref });
  // const styles = useStyles();
  const styles = useStyleConfig('Tabs')
  // const isSelected = !!tabProps['aria-selected'];
  return (
    <StyledTab __css={styles.tab} {...tabProps}>
      <Heading as="h5" fontWeight={600} size="sm">{props.children}</Heading>
    </StyledTab>
  );
});

export const FilterTabs = ({ allColumns, /*visibleColumns,*/ data, onResetFilters, /*rows*/  }) => {
  const dispatch = useDispatch();

  const resetFilters = () => {
    onResetFilters();
    dispatch(resetFiltersAndColumns());
  };

  return (
    <Box>
      <Tabs
        defaultIndex={0}
      >
        <TabList flexWrap="wrap" borderBottomWidth={1}>
          <HStack spacing={0} alignItems="baseline" px={'0.75rem'}>
            <TabHeader>
              Filters
            </TabHeader>
            {/* <Text color="initial" fontWeight={400} fontSize="xs">Currently viewing {rows.length} records.</Text> */}
          </HStack>
          <HStack spacing={0} alignItems="baseline" px={'0.75rem'}>
            <TabHeader>Columns</TabHeader>
            {/* <Text color="initial" fontWeight={400} fontSize="xs">{visibleColumns.length} columns selected.</Text> */}
          </HStack>
          <Button marginLeft={'auto'} size="xs" color="gray.800" variant="link" onClick={resetFilters}>Reset Filters</Button>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Filters data={data} />
          </TabPanel>
          <TabPanel>
            <ColumnFilters allColumns={allColumns} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box >
  );
};

export const ColumnFilters = ({ allColumns }) => {
  const [colGroups, setColGroups] = useState([]);

  useEffect(() => {
    const uniqColGroups = uniq(allColumns.filter(c => c.parent).map(c => c.parent));
    setColGroups(uniqColGroups);
  }, [allColumns]);


  return (
    <Box>
      <Flex justifyContent="space-between" marginTop="-1rem" flexWrap="wrap">
        {colGroups.map((headerGroup, i) => (
          <ColumnGroupCheckbox key={`filter-${i}`} allColumns={allColumns} headerGroup={headerGroup} />
        ))}
      </Flex>
    </Box>
  );
};

const ColumnGroupCheckbox = ({ headerGroup, allColumns }) => {
  // @todo to save my sanity. need to tie this to other property.
  const restrictedColumns = ['id', 'lastReviewedBy'];
  const columns = allColumns.filter(c => c.parent && c.parent.id === headerGroup.id).filter(c => restrictedColumns.indexOf(c.id) < 0);

  const [allChecked, setAllChecked] = useState();
  const [isIndeterminate, setIsIndeterminate] = useState();

  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const uiConfig = getUIConfig(user);

  useEffect(() => {
    const checkedItems = columns ? columns.map(c => uiConfig.dashboardColumnsVisibility[c.id]) : [];
    setAllChecked(checkedItems.every(Boolean));
    setIsIndeterminate(checkedItems.some(Boolean) && !checkedItems.every(Boolean));
  // }, [uiConfig.dashboardColumnsVisibility]);
  }, [uiConfig.dashboardColumnsVisibility, columns]);

  const toggleAll = (e) => {
    const newDashboardColumnsVisibility = {};
    columns.forEach(c => {
      newDashboardColumnsVisibility[c.id] = e.target.checked;
    });
    dispatch(setDashboardColumnsVisibility(newDashboardColumnsVisibility));
  };

  const toggleColumn = (columnId) => {
    const newValue = !uiConfig.dashboardColumnsVisibility[columnId];
    dispatch(setDashboardColumnsVisibility({ [columnId]: newValue }));
  };

  return (
    <Box key={headerGroup.id} marginTop="1rem">
      <Checkbox
        isIndeterminate={isIndeterminate}
        onChange={toggleAll}
        isChecked={allChecked}
        size="sm"
      >
        {headerGroup.Header}
      </Checkbox>
      <HStack>
        {columns && columns.map((column, i) => (
          <Checkbox key={`cb-${i}`} onChange={(e) => toggleColumn(column.id)} size="sm" isChecked={uiConfig.dashboardColumnsVisibility[column.id]}>
            <Text fontSize="sm">{column.Header}</Text>
          </Checkbox>
        ))}
      </HStack>
    </Box>
  );
};


export const Filters = ({ data }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const uiConfig = getUIConfig(user);

  const setFilterState = (filterId) => (value) => {
    if (uiConfig.dashboardFilterValues[filterId] !== value) {
      dispatch(setDashboardFilterValues({ [filterId]: value }));
    }
  };

  const createSelectFilter = ({id, label}) => {
    const options = getFilterOptionsById(id);

    return {
      id: id,
      label: label,
      type: 'select',
      fn: setFilterState(id),
      options: options,
      value: getFilterValueById(id, options)
    };
  };

  const getFilterValueById = (id, options) =>
    options.find(f => f.value === uiConfig.dashboardFilterValues[id]) ? uiConfig.dashboardFilterValues[id] : '';

  const getFilterOptionsById = (id) => {
    if (id === 'lastReviewedBy') {
      return uniq(data.filter(loc => loc.lastReviewedBy).map(loc => loc.lastReviewedBy)).sort().map(a => ({
        label: `${a}`,
        value: a
      }));
    } else if (id === 'reviewStatus') {
      return uniq(data.filter(loc => loc.reviewStatus).map(loc => loc.reviewStatus)).map(a => ({
        label: `${a}`,
        value: a
      }));
    } else if (id === 'assignedTo') {
      return uniq(data.filter(loc => loc.assignedTo).map(loc => loc.assignedTo)).sort().map(a => ({
        label: `${a}`,
        value: a
      }));
    } else if (id === 'assignmentStatus') {
      return Object.keys(assignmentFieldValues).map(key => ({
        label: assignmentFieldValues[key],
        value: key
      }));
    }

    return [];
  };

  useEffect(() => {
    const ids = ['lastReviewedBy', 'reviewStatus', 'assignedTo', 'assignmentStatus'];
    ids.forEach(id => {
      const options = getFilterOptionsById(id);
      const value = uiConfig.dashboardFilterValues[id];
      if (!value || (value && !options.find(f => f.value === value))) {
        setFilterState(id)(null);
      }
    });
  }, [data, uiConfig.dashboardFilterValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Flex justifyContent="space-between">
        <FilterGroup
          label="PrePlan"
          filters={[
            {
              id: 'completion',
              label: 'Completion Percentage',
              type: 'numberRange',
              fn: setFilterState('completion'),
              min: 0,
              max: 100,
              value: uiConfig.dashboardFilterValues.completion
            }
          ]}
        />
        <FilterGroup
          label="Review"
          filters={[
            {
              id: 'lastReviewedOn',
              label: 'Last Reviewed On',
              type: 'dateRange',
              fn: setFilterState('lastReviewedOn'),
              startDate: uiConfig.dashboardFilterValues.lastReviewedOn && uiConfig.dashboardFilterValues.lastReviewedOn[0],
              endDate: uiConfig.dashboardFilterValues.lastReviewedOn && uiConfig.dashboardFilterValues.lastReviewedOn[1]
            },
            createSelectFilter({
              id: 'lastReviewedBy',
              label: 'Last Reviewed By'
            }),
            createSelectFilter({
              id: 'reviewStatus',
              label: 'Review Status'
            }),
            {
              id: 'originalPrePlan',
              label: 'Created On',
              type: 'dateRange',
              fn: setFilterState('originalPrePlan'),
              startDate: uiConfig.dashboardFilterValues.originalPrePlan && uiConfig.dashboardFilterValues.originalPrePlan[0],
              endDate: uiConfig.dashboardFilterValues.originalPrePlan && uiConfig.dashboardFilterValues.originalPrePlan[1]
            }
          ]}
        />
        {/* <FilterGroup
          label="Creation"
          filters={[
            {
              id: 'originalPrePlan',
              label: 'Created Date',
              type: 'dateRange'
            },
            // {
            //   id: 'createdBy',
            //   label: 'Created By',
            //   type: 'select',
            //   fn: (val) => setFilter('createdBy', val ? val : null),
            //   options: uniq(data.filter(loc => loc.createdBy).map(loc => loc.createdBy)).map(a => ({
            //     label: `${a}`,
            //     value: a
            //   }))
            // },
          ]}
        /> */}
        <FilterGroup
          label="Assignment"
          filters={[
            createSelectFilter({
              id: 'assignedTo',
              label: 'Assigned To'
            }),
            createSelectFilter({
              id: 'assignmentStatus',
              label: 'Assignment Status'
            }),
          ]}
        />
      </Flex>
    </Box>
  );
};

const FilterGroup = (props) => {
  return (
    <Box>
      <Text fontSize="sm" textTransform="uppercase" fontWeight={600}>{props.label}</Text>
      <Divider mb={2} />
      <Flex>
        {props.filters.map((filter, i) => (
          <Stack marginRight={8} spacing={0} key={`filter-${i}`}>
            <Flex justifyContent="space-between">
              <Text fontSize="xs" textTransform="uppercase">{filter.label}</Text>
              {filter.type === 'dateRange' && filter.startDate && filter.endDate && <Button size="xs" color="gray.800" variant="link" onClick={() => filter.fn(null)}>Clear</Button>}
            </Flex>
            {filter.type === 'dateRange' && (
              <DateRangePicker
                startDate={filter.startDate}
                endDate={filter.endDate}
                onChange={filter.fn}
              />
            )}
            {filter.type === 'select' && (
              <Select
                size="xs"
                id={filter.id}
                onChange={(val/*, t*/) => {
                  filter.fn(val.target.value);
                }}
                placeholder={'--'}
                maxWidth="9rem"
                value={filter.value}
              >
                {filter.options && filter.options.map((option) => (
                  <option key={`${props.id}-${option.value}`} value={option.value}>{option.label}</option>
                ))}
              </Select>
            )}
            {filter.type === 'numberRange' && (
              <NumberSlider onChangeEnd={filter.fn} unit="%" min={filter.min} max={filter.max} value={filter.value} />
            )}
          </Stack>
        ))}
      </Flex>
    </Box>
  );
};
