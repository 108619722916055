import * as types from '../actions/types';

const tryParse = (item) => {
  var result = null;
  try {
    result = JSON.parse(item);
  } catch (e) {
    // console.error(e);
  }
  return result;
};

const defaultState = {
  isLoggedIn: false,
  customerId: null,
  jwt: sessionStorage.jwt || null,
  customerLink: tryParse(sessionStorage.customer),
  userLink: tryParse(sessionStorage.user),
  tokenType: sessionStorage.tokenType || null,
  customerIDLink: tryParse(sessionStorage.customerID)
};

export default function sessionReducer(state = defaultState, action) {
  switch(action.type) {
    case types.LOG_IN_SUCCESS:
      return Object.assign({}, state, {
        isLoggedIn: !!sessionStorage.jwt, errorMessage: '',
        customerId: action.authData && action.authData.customerId,
        jwt: action.authData && action.authData.jwt,
        customerLink: action.authData && action.authData.customerLink,
        userLink: action.authData && action.authData.userLink,
        tokenType: action.authData && action.authData.tokenType,
        customerIDLink: action.authData && action.authData.customerIDLink
      });
    case types.INVALID_CREDENTIAL:
      return Object.assign({}, state, { errorMessage: action.errMsg ? action.errMsg : 'Invalid credential' });
    case types.LOG_OUT:
      return Object.assign({}, state, { isLoggedIn: !!sessionStorage.jwt });

    default:
      return state;
  }
}
