import React from 'react';
import FormComponent from './FormComponent';


const formFields = [
  {
    label: 'Do Not Share',
    id: 'doNotShare',
    name: 'doNotShare',
    type: 'checkbox',
    description: 'Do Not Share'
  },
  {
    label: 'Business',
    id: 'locationName',
    name: 'locationName',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Address 1',
    id: 'address1',
    name: 'address1',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Address 2',
    id: 'address2',
    name: 'address2',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'City',
    id: 'city',
    name: 'city',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'State',
    id: 'state',
    name: 'state',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Zip',
    id: 'zip',
    name: 'zip',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Lot Number',
    id: 'lotNumber',
    name: 'lotNumber',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'District',
    id: 'customerZone',
    name: 'customerZone',
    type: 'text',
    placeholder: ''
  },
  {
    label: 'Floors Above',
    id: 'storey',
    name: 'storey',
    type: 'select',
    options: [
      {
        label: 'Select Number of Floors Above',
        value: ''
      },
      {
        label: '1',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9',
        value: '9'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },
      {
        label: '13',
        value: '13'
      },
      {
        label: '14',
        value: '14'
      },
      {
        label: '15',
        value: '15'
      },
      {
        label: '16',
        value: '16'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '19',
        value: '19'
      },
      {
        label: '20',
        value: '20'
      },
      {
        label: '21',
        value: '21'
      },
      {
        label: '22',
        value: '22'
      },
      {
        label: '23',
        value: '23'
      },
      {
        label: '24',
        value: '24'
      },
      {
        label: '25',
        value: '25'
      },
      {
        label: '26',
        value: '26'
      },
      {
        label: '27',
        value: '27'
      },
      {
        label: '28',
        value: '28'
      },
      {
        label: '29',
        value: '29'
      },
      {
        label: '30',
        value: '30'
      }
    ],
    required: false
  },
  {
    label: 'Floors Below',
    id: 'storeyBelow',
    name: 'storeyBelow',
    type: 'select',
    options: [
      {
        label: 'Select Number of Floors Below',
        value: ''
      },
      {
        label: '1',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9',
        value: '9'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },
      {
        label: '13',
        value: '13'
      },
      {
        label: '14',
        value: '14'
      },
      {
        label: '15',
        value: '15'
      },
      {
        label: '16',
        value: '16'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '19',
        value: '19'
      },
      {
        label: '20',
        value: '20'
      },
      {
        label: '21',
        value: '21'
      },
      {
        label: '22',
        value: '22'
      },
      {
        label: '23',
        value: '23'
      },
      {
        label: '24',
        value: '24'
      },
      {
        label: '25',
        value: '25'
      },
      {
        label: '26',
        value: '26'
      },
      {
        label: '27',
        value: '27'
      },
      {
        label: '28',
        value: '28'
      },
      {
        label: '29',
        value: '29'
      },
      {
        label: '30',
        value: '30'
      }
    ],
    required: false
  },
  {
    label: 'Roof Area',
    id: 'roofArea',
    name: 'roofArea',
    type: 'number',
    placeholder: ''
  },
  {
    label: 'Required Flow',
    id: 'requiredFlow',
    name: 'requiredFlow',
    type: 'number',
    placeholder: ''
  },
  {
    label: 'High Hazard',
    id: 'isHighHazard',
    name: 'isHighHazard',
    type: 'checkbox',
    description: 'Highlight as a high risk/hazardous location'
  },
  {
    label: 'High Hazard Description',
    id: 'highHazardDescription',
    name: 'highHazardDescription',
    type: 'text',
    description: 'Max 50 characters.',
    conditional: {
      name: 'isHighHazard',
      condition: (value) => value === 'true' || value === true
    }
  },
  {
    label: 'Risk Assessment Score',
    id: 'riskAssessmentScore',
    name: 'riskAssessmentScore',
    type: 'text',
    placeholder: '',
  },
];


export const LocationForm = (props) => {

  return (
    <FormComponent data={props.locationDataForm} formFields={formFields}/>
  );
};
export default LocationForm;
