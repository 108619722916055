import * as React from "react";

const cls1 = {
  isolation: "isolate"
};

const cls2 = {
  fill: "#2c62cb"
};

const cls3 = {
  fill: "#222234"
};

const SvgFlowMSPImageTrendLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 911.3 335.2"
    height={48}
    width={130.496}
    {...props}
  >
    <g>
      <g id="Layer_2">
        <g id="Layer_1-2">
          <g>
            <g>
              <path style={cls3} d="M692.7,68.8l-21,75.2h-.5l-22.2-75.2h-39.8l-21.5,75.2h-.7l-20.7-75.2h-42.3l41.2,118.2h39.8l23-73.7h.5l23.5,73.7h39.8l41.4-118.2h-40.6,0ZM482.4,139.2c-1.2,3.6-3,6.9-5.4,9.9-2.4,2.9-5.5,5.3-8.9,7-3.9,1.7-8,2.6-12.3,2.6s-8.4-.9-12.3-2.6c-3.4-1.7-6.5-4.1-8.9-7-2.4-2.9-4.2-6.3-5.4-9.9-2.4-7.3-2.4-15.1,0-22.4,1.1-3.5,2.9-6.8,5.3-9.7,2.4-2.8,5.4-5.2,8.8-6.8,3.9-1.7,8-2.6,12.3-2.6s8.4.9,12.3,2.6c3.4,1.7,6.4,4,8.9,6.8,2.4,2.8,4.3,6.1,5.5,9.7,1.3,3.6,1.9,7.4,1.9,11.2s-.6,7.6-1.8,11.2h0s0,0,0,0ZM502.5,82.1c-6.2-5.5-13.4-9.7-21.2-12.4-16.6-5.7-34.7-5.7-51.3,0-7.8,2.7-15,6.9-21.2,12.4-6.1,5.5-11,12.3-14.3,19.8-3.5,7.8-5.3,16.5-5.3,26.2-.1,9.1,1.7,18,5.3,26.3,3.3,7.6,8.2,14.4,14.3,20,6.1,5.6,13.3,9.9,21.2,12.8,16.6,6,34.8,6,51.3,0,7.8-2.9,15-7.2,21.2-12.8,6.2-5.6,11.1-12.4,14.4-20,3.6-8.3,5.4-17.3,5.3-26.3,0-9.7-1.8-18.4-5.3-26.2-3.3-7.5-8.2-14.3-14.4-19.8h0s0,0,0,0ZM332.8,187.8h40.3V7.3h-40.3v180.4ZM296.8,2.7c-10,0-18.3,1.5-24.9,4.4-6.2,2.6-11.7,6.7-15.9,11.9-4,5.1-6.8,11.1-8.3,17.4-1.6,6.8-2.4,13.8-2.4,20.8v11.4h-21.7v29.2h21.7v89.8h39.5v-89.8h26.3v-29.2h-26.3v-11.9c0-2.7.2-5.4.7-8.1.4-2.6,1.3-5,2.6-7.3,1.3-2.1,3.1-3.9,5.3-5.1,2.2-1.3,5.2-1.9,8.9-1.9s3.9.2,5.8.5c1.7.3,3.4.7,5.1,1.2l2.6-31c-3-.8-6-1.4-9-1.8-3.3-.4-6.7-.6-10-.6h0Z"/>
              <path style={cls2} d="M99,0c-26.2,0-51.4,10.3-70,28.7C10.4,47.1,0,72.1,0,98.1v.2l25.7,5.4c25.1,5.3,51.2,3.1,75.1-6.3,19.8-7.8,41.5-9.9,62.4-5.9l34.8,6.7h0c0-26.2-10.4-51.1-29-69.5C150.4,10.3,125.2,0,99,0Z"/>
              <g>
                <polygon style={cls3} points="793.9 186.4 794.2 148.4 794 148.4 779.9 186.4 770.7 186.4 757 148.4 756.8 148.4 757.1 186.4 744.7 186.4 744.7 132.7 763.4 132.7 775.7 167.2 776 167.2 787.9 132.7 806.8 132.7 806.8 186.4 793.9 186.4"/>
                <path style={cls3} d="M845.9,146.7c-1.2-1.5-2.7-2.6-4.4-3.4-1.6-.8-3.4-1.2-5.2-1.2-.9,0-1.8,0-2.6.2-.8.1-1.7.4-2.4.9-.7.4-1.3,1-1.8,1.7-.5.8-.8,1.7-.7,2.6,0,.8.2,1.6.6,2.3.4.6,1,1.2,1.7,1.6.8.5,1.7.9,2.6,1.2,1,.4,2.2.8,3.5,1.2,1.8.6,3.8,1.3,5.8,2,1.9.7,3.8,1.7,5.4,2.9,1.6,1.2,3,2.8,4.1,4.5,1.1,2.1,1.7,4.4,1.6,6.7,0,2.8-.5,5.5-1.7,8-1.1,2.2-2.7,4.1-4.7,5.6-2,1.5-4.3,2.6-6.7,3.3-2.5.7-5.1,1.1-7.8,1.1-3.8,0-7.7-.7-11.3-2-3.4-1.2-6.5-3.2-9-5.7l8.6-8.7c1.4,1.7,3.2,3.1,5.2,4.1,2,1.1,4.2,1.6,6.5,1.6,1,0,1.9-.1,2.8-.3.9-.2,1.7-.5,2.4-1,.7-.5,1.3-1.1,1.7-1.8.4-.9.6-1.8.6-2.7,0-.9-.2-1.8-.8-2.6-.6-.8-1.3-1.4-2.2-1.9-1.1-.7-2.3-1.2-3.5-1.6-1.4-.5-3-1-4.8-1.5-1.7-.6-3.4-1.2-5.1-2-1.6-.7-3.1-1.7-4.5-2.9-1.3-1.2-2.4-2.7-3.1-4.3-.8-1.9-1.2-4.1-1.2-6.2,0-2.7.5-5.3,1.8-7.7,1.2-2.1,2.8-3.9,4.8-5.2,2-1.4,4.3-2.4,6.7-3,2.5-.6,5-.9,7.6-.9,3.2,0,6.4.6,9.4,1.6,3.1,1.1,6,2.7,8.4,4.9l-8.3,8.7h0Z"/>
                <path style={cls3} d="M889.9,149.3c0-1.1-.2-2.2-.8-3.1-.5-.8-1.2-1.5-2.1-1.9-.9-.5-1.9-.8-3-.9-1.1-.1-2.3-.2-3.4-.2h-5.8v12.7h5.5c1.2,0,2.4-.1,3.5-.3,1.1-.2,2.1-.5,3-1.1.9-.5,1.6-1.2,2.1-2,.5-1,.8-2.1.8-3.1h0ZM903,149.2c0,2.8-.5,5.5-1.8,7.9-1.1,2.1-2.8,3.9-4.8,5.2-2.1,1.4-4.4,2.3-6.9,2.9-2.7.6-5.4.9-8.1.9h-6.5v20.3h-13.1v-53.8h19.9c2.8,0,5.6.3,8.3.9,2.4.5,4.7,1.5,6.7,2.8,1.9,1.3,3.5,3,4.5,5.1,1.2,2.4,1.7,5.1,1.7,7.8h0Z"/>
              </g>
              <path style={cls3} d="M100.8,97.5c-23.8,9.4-50,11.7-75.1,6.3L0,98.3c0,26,10.4,51,29,69.4,9.2,9.1,20.1,16.3,32.1,21.3s24.9,7.5,37.9,7.4c13,0,25.9-2.6,37.9-7.5s22.9-12.2,32.1-21.3,16.5-19.9,21.4-31.8c5-11.9,7.5-24.7,7.5-37.6l-34.8-6.7c-20.9-4-42.6-1.9-62.4,5.9h0Z"/>
            </g>
            <g>
              <g>
                <path id="circle-r" d="M904.8,258.2c1.1,0,2.2.3,3.2.8s1.8,1.4,2.4,2.4.9,2.1.9,3.2-.3,2.2-.9,3.2-1.4,1.8-2.4,2.4-2.1.9-3.2.9-2.2-.3-3.2-.9c-1-.6-1.8-1.4-2.4-2.4-.6-1-.9-2.1-.9-3.2s.3-2.2.9-3.2c.6-1,1.4-1.8,2.4-2.4,1-.5,2.1-.8,3.2-.8h0ZM904.8,259.5c-.9,0-1.7.2-2.6.7s-1.5,1.1-1.9,1.9c-.5.8-.7,1.7-.7,2.6s.2,1.8.7,2.6,1.1,1.5,1.9,1.9c.8.5,1.7.7,2.6.7s1.8-.2,2.6-.7,1.5-1.1,1.9-1.9c.5-.8.7-1.7.7-2.6s-.2-1.8-.7-2.6c-.5-.8-1.1-1.5-1.9-1.9-.8-.4-1.7-.7-2.6-.7h0ZM901.9,268.1v-6.9h1.4c1.3,0,2,0,2.2,0,.5,0,.8.1,1.1.3.3.1.5.4.6.7.2.3.3.6.3,1s-.2.9-.5,1.3c-.3.4-.8.6-1.4.6.2,0,.4.2.5.3s.3.3.5.5c0,0,.2.4.5.8l.8,1.4h-1.7l-.6-1.1c-.4-.8-.7-1.2-1-1.4s-.6-.3-1-.3h-.4v2.8h-1.4ZM903.3,264.2h.6c.8,0,1.3,0,1.5,0,.2,0,.3-.2.5-.3.1-.1.2-.3.2-.5s0-.4-.2-.5-.3-.3-.5-.3c-.2,0-.7-.1-1.5-.1h-.6v1.9h0Z"/>
                <path d="M865.9,265.2h5.8c8.7,0,13.2,3.6,13.2,12.3s-2.4,12.8-6.5,16.6c-4.9,4.5-10.3,4.9-14.8,4.9h-6.7l9.1-33.8ZM846.8,305.2h16.7c7.1,0,14.4-.5,21-6.6,5.5-5.1,8.8-13.4,8.8-21.3,0-11.8-7.5-18.5-19.6-18.5h-14.6l-12.4,46.4h0Z"/>
                <path d="M848.3,258.8l-12.6,46.6h-3.2l-20.3-36.4-9.5,36.2h-8.1l12.5-46.4h8.1l17,31.4,8.2-31.4h8,0Z"/>
                <path d="M788.1,298.8l-6.2,6.4h-27l12.5-46.4h27.4l-1.8,6.4h-18.9l-3.4,12.8h16.5l-1.7,6.3h-16.4l-4,14.6h23Z"/>
                <path d="M723.6,284.5l5.3-19.4h4c4.5,0,8.7,1.5,8.7,6.5s-2.6,7.9-10.1,7.9-1.4,0-2-.1l-5.8,5.2h0ZM738.7,284.1c1.6-.5,3.7-1.1,5.8-2.8,3.3-2.5,5.5-6.3,5.5-10.5,0-8.6-7.2-12-15-12h-12.9l-12.4,46.4h8.2l5.5-20.1c1,.1,1.8.2,3.4.2s3-.1,3.8-.2l7.9,20.1h8.7l-8.5-21.1h0Z"/>
                <path d="M685.5,305.2h-11l14-52.5h-18.8l2.2-8.3h47.5l-2.2,8.3h-17.7l-14,52.5h0Z"/>
                <path d="M658.7,305.2h-26.3v-46.4h27.1v6.6h-18.6v12.4h16v6.6h-16v14.2h21.6l-3.8,6.6h0Z"/>
                <path d="M622.2,302c-5.6,2.8-11.8,4.4-18.1,4.4-15.5,0-25.7-9.8-25.7-24.1s10.3-24.6,27-24.6,9.6.9,14.3,2.5v8c-2.3-1.3-7.1-4-13.7-4-10.7,0-18.6,7.4-18.6,18s6.8,18,17.6,18,7.6-1.4,9-2.1l.2-10.6h-11.9l3.9-6.4h16.4l-.4,21h0Z"/>
                <path d="M545,287.9l6.6-17.5c.2-.6.3-.9.7-2.4.4,1.5.5,1.7.7,2.5l6.2,17.4h-14.3,0ZM557,258.8h-8.4l-18.7,46.4h8.7l4.4-11.5h18.5l4.3,11.5h9.4l-18.1-46.4h0Z"/>
                <path d="M524.5,305.2h-8.6l-3.2-33.8-15.6,33.8h-.9l-15.5-34-3.2,34h-8.2l5.1-46.4h8.8l13.9,30.5,13.7-30.5h8.7l5.3,46.4h0Z"/>
                <path d="M458.5,305.2h-11.1v-59.6h11.1v59.6Z"/>
              </g>
              <g style={cls1}>
                <g style={cls1}>
                  <path d="M328.8,249.4h20.2c13.4,0,19.4,6,19.4,15.7s-3.4,11-9.6,12.2h0c6.7,1.4,11,6.8,11,13,0,9.6-7.5,15.9-20.9,15.9h-20.1v-56.8ZM349.1,274.3c8.1,0,12-3,12-9.3s-4-9.2-12-9.2h-13.4v18.5h13.4ZM350.5,299.7c8.1,0,12-3.4,12-9.5s-4-9.5-12-9.5h-14.9v18.9h14.9Z"/>
                </g>
                <g style={cls1}>
                  <path d="M400,313.4c-3.4,8.4-7.2,11.3-13.6,11.3s-7.1-1-9.5-3.6l2.4-5.9c2,2.8,4.6,3.4,7,3.4s5.7-1.3,7.8-7l2.2-5.6-18-42.9h7.8l10.8,28.1,2.7,7.7,3.1-8.5,10.1-27.2h7.4l-20.2,50.2Z"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgFlowMSPImageTrendLogo;
