import React, { useEffect, useState } from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { Checkbox, ConditionalField, DatePicker, Input, Select, TextArea } from './FormWrapper';
import { useFormContext, Controller } from 'react-hook-form';
import { isEqual } from 'lodash';
import { Select as SearchSelect } from 'chakra-react-select';

const FormComponent = ({
  formFields,
  data
}) => {
  const methods = useFormContext();
  const [init, setInit] = useState(null);
  useEffect(() => {
      if (!isEqual(init, data)) {
        formFields.forEach(field => {
          methods.setValue(field.id, data[field.id]);
        });
        setInit(data);
      }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps
  //}, [data, formFields, init, methods]);


  return (
    <Box>
      <Stack spacing="0.5rem">
        {formFields.map((f, index) => {
          const field = {
            ...f,
            labelProps: {
              fontSize: 'xs',
              textTransform: 'uppercase'
            },
            size: 'sm',
          };
          return (field.conditional ? (
              <ConditionalField key={`${field.id}-${index}-${field.type}`} condition={field.conditional.condition} conditionName={field.conditional.name}>
                <Field field={field} index={index} />
              </ConditionalField>
            ) : (
              <Field key={`${field.id}-${index}-${field.type}`} field={field} index={index} />)
          );
        })}
      </Stack>
    </Box>
  );

};


const Field = ({ field, index }) => {
    const methods = useFormContext();
    return (

        <Stack spacing="0.25rem">
            <Text
                key={`${field.id}-${index}-label`}
                fontSize="xs"
                textTransform="uppercase"
            >
                {field.label}
            </Text>
            {(field.type === 'static') && (
                <Input key={`${field.id}-${index}-inputstatic`} {...field} isReadOnly/>
            )}
            {(field.type === 'textarea') && (
                <TextArea
                    key={`${field.id}-${index}-input`}
                    {...field}
                />
            )}
            {['text', 'number', 'email', 'password'].indexOf(field.type) > -1 ? (
                <Input
                    key={`${field.id}-${index}-input`}
                    {...field}
                />
            ) : field.type === 'select' ? (
                <Select
                    key={`${field.id}-${index}-select`}
                    {...field}
                />
            ) : field.type === 'search-select' ? (
                <Controller control={methods.control} name={field.name} render={({ field: { onChange, /*onBlur, */value, ref } }) => {
                    return (
                        <SearchSelect
                            inputRef={ref}
                            key={`${field.id}-${index}-search-select`}
                            options={field.options}
                            value={field.options.find(c => c.value === value)}
                            onChange={val => onChange(val ? val.value : null)}
                        />
                    );
                }} />
            ) : field.type === 'checkbox' ? (
                <Checkbox
                    key={`${field.id}-${index}-checkbox`}
                    {...field}
                />
            ) : <React.Fragment/>
            }
            {field.type === 'date' && (
                    <DatePicker
                        key={`${field.id}-${index}-datepicker`}
                        {...field}
                    />

            )}
        </Stack>
    );
};
export default FormComponent;
