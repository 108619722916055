import * as z from 'zod';

export const Building = z.object({
  constructionType: z.optional(z.string()),
  fireAlarm: z.optional(z.string()), // @todo why not boolean?
  hoursOfOperation: z.optional(z.string()),
  lastReviewedBy: z.string().optional(),
  lastReviewedOn: z.string().optional(), // @todo should this be date?
  normalPopulation: z.optional(z.string()),
  notes: z.optional(z.string()),
  partnerNotes: z.optional(z.string()),
  commercialStructure: z.boolean().optional(),
  occupancyType: z.optional(z.string()),
  originalPrePlan: z.optional(z.string()),
  ownerContact: z.optional(z.string()),
  ownerPhone: z.optional(z.string()),
  roofConstruction: z.optional(z.string()),
  roofMaterial: z.optional(z.string()),
  roofType: z.optional(z.string()),
  sprinklered: z.optional(z.string()),
  standPipe: z.optional(z.string()),
});

export default Building;
