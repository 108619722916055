const Tools = {
  TextBubble: 'Text Bubble',
  Text: 'Text',
  Circle: 'Circle',
  Line: 'Line',
  Pencil: 'Pencil',
  Rectangle: 'Rectangle',
  Delete: 'Delete',
  Select: 'Select',
  Symbol: 'Symbol',
  // Pan: 'pan',
};

export default Tools;